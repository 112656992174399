import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { PageList, PageForm } from 'components/blocks/Page';


const PageListScreen = ({ history, site, page, actions }) => {
  return (
    <Page
      title="Страницы"
      info={page.filter.query ? `Найдено: ${page.count}`: ""}
      modelActions={actions.page}
      breadcrumbs={[
        { title: 'Сайт', path: '/site' },
        { title: 'Страницы', path: page.urls.list() },
      ]}
      actions={[
        {
          mode: 'button',
          name: 'add',
          type: 'primary',
          title: 'Добавить страницу',
        },
      ]}
    >
      <div className="page-main">
        <PageList />
      </div>

      <PageForm />
    </Page>
  );
};

const mapState = (state) => ({
  site: state.site,
  page: state.page,
});

const mapDispatch = (dispatch) => ({
  actions: {
    page: dispatch.page,
  },
});

export default connect(mapState, mapDispatch)(PageListScreen);
