import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';

const PriceTypeForm = (props) => {
  const { showEditForm } = props.priceType;

  const onClose = () => {
    props.actions.priceType.showEditForm({ show: false });
  };

  const onSubmit = (data) => {
    const newData = Object.assign(data, {
      site: props.site.activeId,
    });
    props.actions.priceType.update(newData);
  };

  return (
    <Form
      title="Редактирование свойства"
      model={props.priceType}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={{ sort: 100 }}
    >
      <MainFieldSet {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  site: state.site,
  priceType: state.priceType,
});

const mapDispatch = (dispatch) => ({
  actions: {
    priceType: dispatch.priceType,
  },
});

export default connect(mapState, mapDispatch)(PriceTypeForm);
