import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';


const RecommendedCategoryForm = (props) => {
  const { showEditForm } = props.recommendedCategory;

  const onClose = () => {
    props.actions.recommendedCategory.showEditForm({ show: false });
  };

  const onSubmit = (data) => {
    const newData = Object.assign(data, {
      groupId: props.groupId,
      preventLoadDetail: true,
    });
    props.actions.recommendedCategory.update(newData);
  };

  return (
    <Form
      width={800}
      title="Редактирование"
      model={props.recommendedCategory}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={{ sort: 100 }}
      convertEditObject={(editObject) => ({
        ...editObject,
        recommended_category: editObject.recommended_category.id,
      })}
    >
      <MainFieldSet {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  site: state.site,
  auth_token: state.auth.token,
  recommendedCategory: state.recommendedCategory,
});

const mapDispatch = (dispatch) => ({
  actions: {
    recommendedCategory: dispatch.recommendedCategory,
  },
});

export default connect(mapState, mapDispatch)(RecommendedCategoryForm);
