import React from 'react';

import { SeoTemplatePageDetail } from 'components/blocks/SeoTemplatePage';

const DetailScreen = (props) => {
  return (
    <div className="page-main">
      <SeoTemplatePageDetail />
    </div>
  );
};

export default DetailScreen;
