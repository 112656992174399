import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { groupDetailLoad } from 'store/models/group';
import { DetailLoadComponent } from 'components/common';

const GroupValue = (props) => {
  return (
    <DetailLoadComponent
      apiLoad={groupDetailLoad(props.site.activeDomain)}
      {...props}
      getDetailUrl={(id, item) => props.model.urls.detail(id)}
      render={({ id, item, linkPath }) => {
        const value = (item && item.name) || id;

        return (
          <Fragment>{props.asLink && linkPath ? <Link to={linkPath}>{value}</Link> : <span>{value}</span>}</Fragment>
        );
      }}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  authToken: state.auth.token,
  model: state.group,
  site: state.site,
});

export default connect(mapState)(GroupValue);
