import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { NavTopList, NavTopForm } from 'components/blocks/NavTop';


const NavTopListScreen = ({ history, site, navTop, actions }) => {
  return (
    <Page
      title="Навигация"
      info={navTop.filter.query ? `Найдено: ${navTop.count}`: ""}
      modelActions={actions.brand}
      breadcrumbs={[
        { title: 'Сайт', path: '/site' },
        { title: 'Навигация', path: navTop.urls.list() },
      ]}
      
      basePath="/site/nav"
      tabs={[
        { title: 'Главная навигация', key: 'detail' },
        { title: 'Верхняя навигация', key: 'top' },
        { title: 'Нижняя навигация', key: 'footer' },
      ]}
    >
      <div className="page-main">
        <NavTopList />
      </div>

      <NavTopForm />
    </Page>
  );
};

const mapState = (state) => ({
  site: state.site,
  navTop: state.navTop,
});

const mapDispatch = (dispatch) => ({
  actions: {
    navTop: dispatch.navTop,
  },
});

export default connect(mapState, mapDispatch)(NavTopListScreen);
