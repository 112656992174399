import React from 'react';
import { Input } from 'antd';

import { FormItem } from 'utils';
import { HtmlField } from 'components/common';


const MainFieldSet = (props) => {
  return (
    <div className="form-block">
      <FormItem {...props} name="name" label="Название" required={true}>
        <Input size="large" />
      </FormItem>

      <FormItem {...props} name="art_no" label="Артикул" required={true}>
        <Input size="large" />
      </FormItem>

      <FormItem {...props} name="text" label="Описание" required={false}>
        <HtmlField
          auth_token={props.auth_token}
          siteDomain={props.site.activeDomain}
          modelName="catalog.productgroup"
        />
      </FormItem>
    </div>
  );
};

export default MainFieldSet;
