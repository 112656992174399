import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Carousel, Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText, OnOff, Image } from 'components/common';
import CategoryList from '../CategoryList';


const CategoryDetail = ({ category, actions }) => {
  const itemUpdate = (data) => {
    actions.category.update(data);
  };

  const detail = category.detail || {};
  const parentObj = detail.parent_obj || {};
  const images = detail.images || [];

  const categoryAdd = () => {
    actions.category.showEditForm({ 
      show: true, 
      object: { parent: detail.id },
      preventLoadDetail: true
    });
  };

  const topActions = [
    <Button type="primary" icon={<PlusOutlined/>} onClick={categoryAdd}>
      Добавить подкатегорию
    </Button>
  ];

  return (
    <Row gutter={[16, 24]}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Родительская категория">{parentObj.name}</Descriptions.Item>
            <Descriptions.Item label="Название">{detail.name}</Descriptions.Item>
            <Descriptions.Item label="Путь">{detail.slug}</Descriptions.Item>
            <Descriptions.Item label="Сортировка">{detail.sort}</Descriptions.Item>

            <Descriptions.Item label="Активный">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_active"
                updateStatus={category.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>

            <Descriptions.Item label="Верхнее описание" span={24}>
              <div style={{overflowX: 'scroll', fontSize: '12px'}} dangerouslySetInnerHTML={{__html: detail.teaser}} />
            </Descriptions.Item>

            <Descriptions.Item label="Описание" span={24}>
              <div style={{overflowX: 'scroll', fontSize: '12px'}} dangerouslySetInnerHTML={{__html: detail.text}} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
      <Fieldset>
          <Carousel>
            {images.map(img => (
              <div key={`photo${img.sha1}`}>
                <Image src={img.url} height={300} />
              </div>
            ))}
          </Carousel>
        </Fieldset>

        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="EXT_ID" size="sm">{detail.ext_id}</Descriptions.Item>
            <Descriptions.Item label="Дата создания" size="sm">
              <DateTimeText date={detail.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения" size="sm">
              <DateTimeText date={detail.date_changed} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата синхронизации" size="sm">
              <DateTimeText date={detail.date_synced} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={24}>
        <Fieldset title="Подкатегории">
          {detail.id && <CategoryList activeFilter={{ parent: detail.id }} topActions={topActions}/>}
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  category: state.category,
});

const mapDispatch = (dispatch) => ({
  actions: {
    category: dispatch.category,
  },
});

export default connect(mapState, mapDispatch)(CategoryDetail);
