import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText, OnOff } from 'components/common';
import PropertyType from '../PropertyType';


const PropertyDetail = ({ property, actions }) => {
  const itemUpdate = (data) => {
    actions.property.update(data);
  };

  const detail = property.detail || {};

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Название">{detail.name}</Descriptions.Item>
            <Descriptions.Item label="Код">{detail.slug}</Descriptions.Item>
            <Descriptions.Item label="Тип">
              <PropertyType value={detail.type}/>
            </Descriptions.Item>
            <Descriptions.Item label="Сортировка">{detail.sort}</Descriptions.Item>

            <Descriptions.Item label="Активный">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_active"
                updateStatus={property.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="EXT_ID" size="sm">{detail.ext_id}</Descriptions.Item>
            <Descriptions.Item label="Дата создания" size="sm">
              <DateTimeText date={detail.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения" size="sm">
              <DateTimeText date={detail.date_changed} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата синхронизации" size="sm">
              <DateTimeText date={detail.date_synced} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  property: state.property,
});

const mapDispatch = (dispatch) => ({
  actions: {
    property: dispatch.property,
  },
});

export default connect(mapState, mapDispatch)(PropertyDetail);
