import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Upload, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import classNames from "classnames";
import update from 'immutability-helper';

import { arrayIsEqual, isDict } from 'utils';
import { endpoints } from 'store/models/file';
import './styles.css';


const type = 'DragableUploadList';

const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
  const ref = React.useRef();
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move' }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  );
};


const ImageUpload = ({ className, siteDomain, modelName, data, mode, title, auth_token, onChange, fileList, ...otherProps }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [initialFileList, setInitialFileList] = useState([]);
  const [files, setFiles] = useState([]);

  const memoFileList = useMemo(() => {
    let _tmpList = fileList ? JSON.parse(JSON.stringify(fileList)): [];
    if (!Array.isArray(_tmpList)) {
      if (isDict(_tmpList)) {
        _tmpList = [_tmpList];
      } else {
        _tmpList = [];
      }
    }
    return _tmpList;
  }, [fileList]);

  if (memoFileList && !arrayIsEqual(initialFileList, memoFileList)) {
    const _files = memoFileList.map(item => {
      item.uid = item.sha1;
      return item;
    });
    setFiles(_files);
    setInitialFileList(memoFileList);
  }

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = (file) => {
    setPreviewVisible(true);
    setPreviewImage(file.url || file.thumbUrl);
  };

  const getFileItem = (file) => {
    if (file.response) {
      file.uid = file.response.sha1;
      //file.id = file.response.id;
      file.path = file.response.path;
      file.url = file.response.url;
      //file.name = file.response.name;
      file.sha1 = file.response.sha1;
    }
    return file;
  }

  const handleChange = (info) => {
    let isUploading = false;
    let _tmpList = [];
    if (mode === 'single') {
      const item = getFileItem(info.file);
      if (item.status !== 'removed') {
        _tmpList = [item]
      }
      if (item.status === 'uploading') {
        isUploading = true;
      }
    } else {
      let length = info.fileList.length, seen = new Set();
      for (let i = 0; i < length; i++) {
        const item = getFileItem(info.fileList[i]);
        if (seen.has(item.uid)) continue;
        if (item.status === 'uploading') {
          isUploading = true;
        }
        seen.add(item.uid);
        _tmpList.push(item);
      }
    }

    setFiles(_tmpList);
    if (!isUploading) {
      const _files = _tmpList.map(item => ({
        uid: item.uid,
        sha1: item.sha1,
        path: item.path,
        url: item.url,
      }));
      //onChange && onChange(mode === 'single' && _files.length > 0 ? [_files[0]]: _files);
      onChange && onChange(_files);
    }
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = files[dragIndex];
      const _tmpList = update(files, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      setFiles(_tmpList);
      const _files = _tmpList.map(item => ({
        uid: item.uid,
        sha1: item.sha1,
        path: item.path,
        url: item.url,
      }));
      onChange && onChange(mode === 'single' && _files.length > 0 ? _files[0]: _files);
      //onChange && onChange(_files);
    },
    [files, onChange, mode],
  );

  const cx = classNames([
    "photo-upload",
    mode === 'single' && "photo-upload--full",
    className
  ]);

  return (
    <div className={cx}>
      <DndProvider backend={HTML5Backend}>
        <Upload
          className={mode === 'single' ? 'ant-upload-full' : ''}
          listType="picture-card"
          multiple={mode === 'signle' ? false: true}
          fileList={files}
          action={endpoints.uploadImage(siteDomain, modelName)}
          data={data}
          headers={{'Authorization': `JWT ${auth_token}`}}
          {...otherProps}
          onPreview={handlePreview}
          onChange={handleChange}

          itemRender={(originNode, file, currFileList) => (
            <DragableUploadListItem
              originNode={originNode}
              file={file}
              fileList={currFileList}
              moveRow={moveRow}
            />
          )}
        >
          {(mode !== 'single' || files.length === 0) &&
          <div className="photo-upload-btn">
            <PlusOutlined className="photo-upload-btn-icon"/>
            <div className="photo-upload-btn-text">{title}</div>
          </div>
          }
        </Upload>
      </DndProvider>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img style={{ width: '100%' }} src={previewImage} alt=""/>
      </Modal>
    </div>
  );
};

export default ImageUpload;
