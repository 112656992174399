import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText, PropValue } from 'components/common';
import { PageValue } from 'components/blocks/Page';
import PluginType from '../PluginType';
import PluginDataItemList from '../PluginDataItemList';
import PluginDataItemForm from '../PluginDataItemForm';


const PluginDetailSlider = ({ plugin, actions }) => {
  const pluginType = PluginType['slider'];

  const detail = plugin.detail || {};
  const pages = detail.pages || [];
  const settings = detail.settings || {};
  const data = detail.data || {};

  return (
    <Fragment>
      <Row gutter={[16, 24]}>
        <Col span={16}>
          <Fieldset>
            <Descriptions column={1} gutter={[16, 16]}>
              <Descriptions.Item label="Название" span={12}>{detail.name}</Descriptions.Item>

              <Descriptions.Item label="Тип" span={12}>{detail.type}</Descriptions.Item>

              <Descriptions.Item label="Страницы">
                {pages.map(id => <PageValue key={`page${id}`} value={id} asLink={true} isBack={true} />)}
              </Descriptions.Item>

              <Descriptions.Item label="Настройки">
                {pluginType.settingsFields.map(item => (
                  <PropValue key={`set${item.name}`} label={item.label} value={settings[item.name]}/>
                ))}
              </Descriptions.Item>
            </Descriptions>
          </Fieldset>
        </Col>

        <Col span={8}>
          <Fieldset>
            <Descriptions column={1} gutter={[16, 16]}>
              <Descriptions.Item label="Дата создания" size="sm">
                <DateTimeText date={detail.date_created} />
              </Descriptions.Item>
              <Descriptions.Item label="Дата изменения" size="sm">
                <DateTimeText date={detail.date_changed} />
              </Descriptions.Item>
            </Descriptions>
          </Fieldset>
        </Col>
      
        <Col span={24}>
          <Fieldset title="">
            <Descriptions column={1} gutter={[16, 16]}>
              {pluginType.dataFields.map(field => {
                switch (field.type) {
                  case 'list':
                    const items = (data[field.name] || []).map((item, index) => {
                      item.id = index;
                      item.index = index;
                      return item;
                    });
                    return (
                      <Descriptions.Item key={`item${field.name}`} label={field.label}>
                        <PluginDataItemList 
                          detail={detail} 
                          fieldName={field.name}
                          itemFields={field.list_item_fields} 
                          items={items}
                        />
                      </Descriptions.Item>
                    )
                  default:
                    return (
                      <Descriptions.Item key={`item${field.name}`} label={field.label}>
                        {data[field.name]}
                      </Descriptions.Item>
                    )
                }
              })}
            </Descriptions>
          </Fieldset>
        </Col>
      </Row>

      <PluginDataItemForm />
    </Fragment>
  );
};

const mapState = (state) => ({
  plugin: state.plugin,
});

const mapDispatch = (dispatch) => ({
  actions: {
    plugin: dispatch.plugin,
  },
});

export default connect(mapState, mapDispatch)(PluginDetailSlider);
