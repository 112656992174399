import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { NotificationTemplateForm } from 'components/blocks/NotificationTemplate';
import DetailScreen from './DetailScreen';


const NotificationTemplateDetailScreen = ({ history, notificationTemplate, actions, match }) => {
  useEffect(() => {
    actions.notificationTemplate.detailLoad({ id: match.params.notificationTemplateId });
  }, [match.params.notificationTemplateId, actions.notificationTemplate]);

  const id = match.params.notificationTemplateId;
  const detail = notificationTemplate.detail || {};

  return (
    <Page
      isLoading={notificationTemplate.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.email_subject_tpl}
      modelActions={actions.notificationTemplate}
      breadcrumbs={[
        { title: 'Уведомления', path: '/notification' },
        { title: 'Шаблоны уведомлений', path: notificationTemplate.urls.list() },
        { title: detail.email_subject_tpl, path: notificationTemplate.urls.detail(id) },
      ]}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={notificationTemplate.urls.detail(id)}
    >
      <Route exact path="/notification/:notificationTemplateId" component={DetailScreen} />

      <NotificationTemplateForm />
    </Page>
  );
};

const mapState = (state) => ({
  notificationTemplate: state.notificationTemplate,
});

const mapDispatch = (dispatch) => ({
  actions: {
    notificationTemplate: dispatch.notificationTemplate,
  },
});

export default connect(mapState, mapDispatch)(NotificationTemplateDetailScreen);
