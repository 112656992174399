import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText } from 'components/common';
import { PageValue } from 'components/blocks/Page';


const NavMainDetail = ({ navMain, actions }) => {
  const detail = navMain.detail || {};
  const page = detail.page || {};

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Тайтл">{detail.title}</Descriptions.Item>

            <Descriptions.Item label="Путь" span={24}>{detail.path}</Descriptions.Item>

            <Descriptions.Item label="Страница">
              <PageValue detail={page} asLink={true} isBack={true} />
            </Descriptions.Item>

            <Descriptions.Item label="Сортировка">{detail.sort}</Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Дата создания" size="sm">
              <DateTimeText date={detail.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения" size="sm">
              <DateTimeText date={detail.date_changed} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  navMain: state.navMain,
});

const mapDispatch = (dispatch) => ({
  actions: {
    navMain: dispatch.navMain,
  },
});

export default connect(mapState, mapDispatch)(NavMainDetail);
