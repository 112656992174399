import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText } from 'components/common';
import { OrderLineList } from 'components/blocks/Order';


const OrderDetail = ({ order, actions }) => {
  const detail = order.detail || {};

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Номер">{detail.number}</Descriptions.Item>

            <Descriptions.Item label="Статус">{detail.status}</Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Клиент">
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Имя" span={24}>{detail.user_name}</Descriptions.Item>

            <Descriptions.Item label="Телефон">{detail.user_phone}</Descriptions.Item>

            <Descriptions.Item label="E-mail">{detail.user_email}</Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Товары">
          <OrderLineList items={detail.lines || []}/>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Сумма">
              {detail.total}
            </Descriptions.Item>

            <Descriptions.Item label="Дата создания">
              <DateTimeText date={detail.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения">
              <DateTimeText date={detail.date_changed} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Доставка">
          <Descriptions column={1} gutter={[16, 16]}>
            {detail.shipping_method === 'pickup' && 
              <Fragment>
                <Descriptions.Item label="Способ доставки" span={24}>самовывоз</Descriptions.Item>

                <Descriptions.Item label="Магазин" span={24}>
                  {detail.shipping_warehouse ? detail.shipping_warehouse.name: ''}
                </Descriptions.Item>
              </Fragment>}
            
            {detail.shipping_method === 'courier' && 
              <Fragment>
                <Descriptions.Item label="Способ доставки" span={24}>курьером</Descriptions.Item>

                <Descriptions.Item label="Адрес" span={24}>
                  {detail.shipping_address ? detail.shipping_address.address: ''}
                </Descriptions.Item>
              </Fragment>}
          </Descriptions>
        </Fieldset>
        
        <Fieldset title="Оплата">
          <Descriptions column={1} gutter={[16, 16]}>
            {detail.payment_method === 'offline' && 
              <Fragment>
                <Descriptions.Item label="Способ оплаты" span={24}>при получении</Descriptions.Item>
              </Fragment>}
            
            {detail.payment_method === 'online' && 
              <Fragment>
                <Descriptions.Item label="Способ оплаты" span={24}>онлайн</Descriptions.Item>
              </Fragment>}
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  order: state.order,
});

const mapDispatch = (dispatch) => ({
  actions: {
    order: dispatch.order,
  },
});

export default connect(mapState, mapDispatch)(OrderDetail);
