import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText, OnOff } from 'components/common';

const UserDetail = ({ user, actions }) => {
  const itemUpdate = (data) => {
    actions.user.update(data);
  };

  const detail = user.detail || {};

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="E-mail">{detail.email}</Descriptions.Item>
            <Descriptions.Item label="Активный">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_active"
                updateStatus={user.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>

            <Descriptions.Item label="Дата создания">
              <DateTimeText date={detail.date_joined} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  user: state.user,
});

const mapDispatch = (dispatch) => ({
  actions: {
    user: dispatch.user,
  },
});

export default connect(mapState, mapDispatch)(UserDetail);
