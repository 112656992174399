import React from 'react';
import { Input } from 'antd';

import { FormItem } from 'utils';
import { ArrayField } from 'components/common';


const PropertiesFieldSet = (props) => {
  const { propertyConfig, priceType, ...otherProps } = props;

  const fields = propertyConfig.reduce((result, item) => {
    if (!item.is_common) {
      result.push({ 
        name: item.property_ext_id,
        label: item.property_name,
        defaultValue: "",
        component: Input, 
        componentStyle: {minWidth: '150px'},
      });
    }
    return result;
  }, []);

  return (
    <div className="form-block">
      <FormItem {...otherProps} name="properties" label="" required={false} valuePropName="items">
        <ArrayField fields={fields} mode="vertical"/>
      </FormItem>
    </div>
  );
};

export default PropertiesFieldSet;
