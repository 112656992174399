import { push } from "connected-react-router";
import { CATALOG_API_URL } from 'config';
import { api } from 'utils';
import * as base from '../base';

const endpoints = {
  list: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/plugin/`,
  detail: (siteDomain) => (id) => `${CATALOG_API_URL}/api/v1/${siteDomain}/plugin/${id}/`,
};

export const pluginListLoad = (siteDomain) => (token, payload) => api.ListLoad(endpoints.list(siteDomain), token, payload);
export const pluginDetailLoad = (siteDomain) => (token, { id }) => api.DetailLoad(endpoints.detail(siteDomain), token, { id });
export const pluginUpdate = (siteDomain) => (token, data) => api.Update(endpoints.list(siteDomain), endpoints.detail(siteDomain), token, data);
export const pluginDelete = (siteDomain) => (token, data) => api.Delete(endpoints.detail(siteDomain), token, data);

export const plugin = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    showDataItemForm: false,
    editDetail: null,
    editItemFieldType: 'list',
    editItemFieldName: '',
    editItemFields: [],

    title: 'Плагины',
    urls: {
      list: () => `/site/plugin`,
      detail: (id) => `/site/plugin/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,

    showDataItemForm(state, payload) {
      return {
        ...state,
        showDataItemForm: payload.show,
        editObject: payload.item,
        editDetail: payload.detail,
        editItemFieldType: payload.itemFieldType || 'list',
        editItemFieldName: payload.itemFieldName,
        editItemFields: payload.itemFields || [],
        editPreventLoadDetail: payload.preventLoadDetail,
      };
    },
  },
  effects: (dispatch) => ({
    async listLoad(payload, rootState) {
      const apiRequest = pluginListLoad(rootState.site.activeDomain);
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'plugin', apiRequest);
    },

    async detailLoad(payload, rootState) {
      const apiRequest = pluginDetailLoad(rootState.site.activeDomain);
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'plugin', apiRequest);
    },

    async update(payload, rootState) {
      const apiRequest = pluginUpdate(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'plugin', apiRequest, async (data) => {
        dispatch['plugin'].showEditForm({ show: false });
        dispatch['plugin'].showDataItemForm({ show: false });
        if (!payload.preventLoadDetail) {
          dispatch(push(rootState['plugin'].urls.detail(data.id)));
        } else {
          const filter = rootState['plugin'].filter;
          await dispatch['plugin'].listLoad(filter);
        }
      });
    },

    async delete(payload, rootState) {
      const apiRequest = pluginDelete(rootState.site.activeDomain);
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'plugin', apiRequest);
    },
  }),
};
