import React from 'react';

import { PluginDetail } from 'components/blocks/Plugin';

const DetailScreen = (props) => {
  return (
    <div className="page-main">
      <PluginDetail />
    </div>
  );
};

export default DetailScreen;
