import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { CategoryList, CategoryForm } from 'components/blocks/Category';

const CategoryListScreen = ({ history, category, actions }) => {
  return (
    <Page
      title="Категории"
      info={category.filter.query ? `Найдено: ${category.count}`: ""}
      modelActions={actions.category}
      breadcrumbs={[{ title: 'Категории', path: category.urls.list() }]}
      actions={[
        {
          mode: 'button',
          name: 'add',
          type: 'primary',
          title: 'Добавить категорию',
        },
      ]}
    >
      <div className="page-main">
        <CategoryList activeFilter={null}/>
      </div>

      <CategoryForm />
    </Page>
  );
};

const mapState = (state) => ({
  category: state.category,
});

const mapDispatch = (dispatch) => ({
  actions: {
    category: dispatch.category,
  },
});

export default connect(mapState, mapDispatch)(CategoryListScreen);
