import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { SiteForm } from 'components/blocks/Site';
import DetailScreen from './DetailScreen';


const SiteDetailScreen = ({ history, site, actions, match }) => {
  useEffect(() => {
    actions.site.detailLoad({ id: site.activeId });
  }, [actions.site, site.activeId]);

  const detail = site.detail || {};
  console.log(site);

  return (
    <Page
      isLoading={site.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.site}
      breadcrumbs={[
        { title: 'Сайт', path: '/site' },
      ]}
      actions={[
        { mode: 'button', name: 'edit' },
      ]}
    >
      <Route exact path="/site" component={DetailScreen} />

      <SiteForm />
    </Page>
  );
};

const mapState = (state) => ({
  site: state.site,
});

const mapDispatch = (dispatch) => ({
  actions: {
    site: dispatch.site,
  },
});

export default connect(mapState, mapDispatch)(SiteDetailScreen);
