import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Tree, Spin } from 'antd';
import { CaretDownOutlined, BranchesOutlined } from '@ant-design/icons';

import './styles.css';

const CategoryTree = ({ router, match, actions, category, group, onCollapse }) => {
  let { categoryId } = match.params;
  const groupDetail = group.detail || {};
  const groupCategories = groupDetail.categories || [];

  if (!categoryId) {
    categoryId = category.activeId ? category.activeId.toString() : null;
    if ((!categoryId || categoryId === 'empty') && groupCategories[0]) {
      categoryId = groupCategories[0].toString();
    }
  }

  useEffect(() => {
    actions.category.listLoad({});
  }, [actions.category]);

  useEffect(() => {
    if (categoryId !== 'group' && categoryId !== 'product') {
      actions.category.select(categoryId);
    }
  }, [actions.category, categoryId]);

  const renderItem = (item) => {
    return (
      <span className="tree-node-main">
        <span className="tree-node-main-title">{item.name}</span>
        <span className="tree-node-main-count">{item.product_group_count_all}</span>
      </span>
    );
  };

  const navigate = (checkedKeys) => {
    if (checkedKeys.length > 0) {
      actions.category.select(checkedKeys[0]);
      actions.router.push(`/catalog/cat/${checkedKeys[0]}`);
    }
  };

  const treeData = category.list.reduce(
    (res, id) => {
      let item = category.byId[id];
      res.push(item);
      return res;
    },
    [
      {
        key: 'all',
        id: 'all',
        name: 'Все товары',
        product_group_count_all: category.info && category.info.product_group_count,
      },
      {
        key: 'empty',
        id: 'empty',
        name: 'Без категории',
        product_group_count_all: category.info && category.info.empty_category_product_group_count,
      },
    ],
  );

  return (
    <Spin spinning={category.loadListStatus.isLoading}>
      <div className="tree">
        <div className="tree-trigger" onClick={onCollapse} />
        <div className="tree-title">
          <BranchesOutlined />
          <span>Категории</span>
        </div>
        {category.loadListStatus.isLoaded &&
          <Tree
            selectedKeys={[categoryId]}
            //expandedKeys={[categoryId]}
            defaultExpandedKeys={[categoryId]}
            defaultExpandParent={true}
            autoExpandParent={true}
            switcherIcon={<CaretDownOutlined />}
            onSelect={navigate}
            treeData={treeData}
            titleRender={renderItem}
          />
        }
      </div>
    </Spin>
  );
};

const mapState = (state) => ({
  router: state.router,
  category: state.category,
  group: state.group,
});

const mapDispatch = (dispatch) => ({
  actions: {
    router: {
      push: (payload) => dispatch(push(payload)),
    },
    category: dispatch.category,
  },
});

export default connect(mapState, mapDispatch)(CategoryTree);
