import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { BrandForm } from 'components/blocks/Brand';
import DetailScreen from './DetailScreen';


const BrandDetailScreen = ({ history, brand, actions, match }) => {
  useEffect(() => {
    actions.brand.detailLoad({ id: match.params.brandId });
  }, [match.params.brandId, actions.brand]);

  const id = match.params.brandId;
  const detail = brand.detail || {};

  return (
    <Page
      isLoading={brand.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.brand}
      breadcrumbs={[
        { title: 'Бренды', path: brand.urls.list() },
        { title: detail.name, path: brand.urls.detail(id) },
      ]}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={brand.urls.detail(id)}
      tabs={[
        { title: 'Основное', key: 'detail' },
        { title: 'Страница', key: 'page' },
      ]}
    >
      <Route exact path="/catalog/brand/:brandId" component={DetailScreen} />

      <BrandForm />
    </Page>
  );
};

const mapState = (state) => ({
  brand: state.brand,
});

const mapDispatch = (dispatch) => ({
  actions: {
    brand: dispatch.brand,
  },
});

export default connect(mapState, mapDispatch)(BrandDetailScreen);
