import React, { Fragment } from 'react';
import { Route } from 'react-router';
import { Layout } from 'antd';

import { Top } from 'components/layout';
import NotificationTemplateListScreen from './NotificationTemplateListScreen';
import NotificationTemplateDetailScreen from './NotificationTemplateDetailScreen';
import NotificationListScreen from './NotificationListScreen';
import NotificationDetailScreen from './NotificationDetailScreen';


const NotificationScreen = ({ match }) => {
  return (
    <Fragment>
      <Top
        title="Уведомления"
        basePath="/notification"
        tabs={[
          { title: 'Шаблоны уведомлений', key: 'detail' },
          { title: 'Уведомления', key: 'list' },
        ]}
      />

      <Layout className="layout-inner">
        <Layout.Content>
          <Route exact path="/notification/list" component={NotificationListScreen} />
          <Route exact path="/notification/list/:notificationId" component={NotificationDetailScreen} />

          <Route exact path="/notification" component={NotificationTemplateListScreen} />
          <Route exact path="/notification/:notificationTemplateId" component={NotificationTemplateDetailScreen} />
        </Layout.Content>
      </Layout>
    </Fragment>
  );
};

export default NotificationScreen;
