import React, { useEffect, useState } from 'react';
import { Input, Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import { getUrlSearch } from 'utils';
import TableFilters from '../TableFilters';

const TableTop = ({
  data,
  onLoadData,
  filterFields,
  topActions,
  rowActions,
  selected,
  showSearch,
  showFilter,
  onFilterToggle,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setSearchQuery(data.filter.params.search || '');
  }, [filterFields, data.filter.query, data.filter.params, data.filter.params.search]);

  const onSearch = (value) => {
    let params = Object.assign({}, data.filter.params);
    params.search = value;
    const query = '?' + getUrlSearch(params);
    onLoadData && onLoadData({ query, activeFilter: data.filter.activeFilter });
  };

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
    onSearch(e.target.value);
  };

  const actions = topActions ? topActions.slice() : [];

  return (
    <div className="table-top">
      <div className="table-top-main">
        <div className="table-top-main-start">
          {showSearch && (
            <Input.Search
              className="table-top-search"
              placeholder="Поиск"
              value={searchQuery}
              allowClear={true}
              onSearch={onSearch}
              onChange={onSearchChange}
            />
          )}

          {showFilter && (
            <Button onClick={onFilterToggle}>
              <FilterOutlined />
            </Button>
          )}
        </div>

        {actions.length > 0 && (
          <div className="table-top-main-end">
            {actions.map((item, index) => (
              <div className="action" key={`action${index}`}>
                {item}
              </div>
            ))}
          </div>
        )}
      </div>

      {filterFields && <TableFilters data={data} onLoadData={onLoadData} filterFields={filterFields} />}

      {selected && selected.length > 0 && rowActions && rowActions.length > 0 && (
        <div className="table-row-actions">
          {rowActions.map((item, index) => (
            <div className="table-row-action" key={`row-action${index}`}>
              <Button type={item.type} onClick={() => item.action(selected)}>
                {item.title}
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableTop;
