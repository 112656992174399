import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { ProductForm } from 'components/blocks/Product';
import DetailScreen from './DetailScreen';


const ProductDetailScreen = ({ history, category, group, product, actions, match }) => {
  useEffect(() => {
    actions.product.detailLoad({ id: match.params.productId });
  }, [match.params.productId, actions.product]);

  const id = match.params.productId;
  const detail = product.detail || {};
  const detailGroup = detail.group || {};
  const propertyConfig = detailGroup.property_config || [];

  const breadcrumbs = useMemo(() => {
    let result = [{ title: 'Каталог', path: '/catalog' }];
    const categories = detail.categories || [];
    const categoryAncestors = detail.category_ancestors || {};
    const groupDetail = detail.group || {};

    const ancestors = categoryAncestors[categories[0]] || [];
    result = result.concat(ancestors.map((item) => ({ title: item.name, path: category.urls.groupList(item.id) })));
    result.push({
      title: groupDetail.name, path: group.urls.detail(groupDetail.id)
    });
    return result
  }, [detail.categories, detail.category_ancestors, detail.group, category.urls, group.urls]);

  return (
    <Page
      isLoading={product.loadDetailStatus.isLoading || product.updateStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.product}
      breadcrumbs={breadcrumbs}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={product.urls.detail(id)}
    >
      <Route exact path="/catalog/product/:productId" component={DetailScreen} />

      <ProductForm propertyConfig={propertyConfig}/>
    </Page>
  );
};

const mapState = (state) => ({
  category: state.category,
  group: state.group,
  product: state.product,
});

const mapDispatch = (dispatch) => ({
  actions: {
    product: dispatch.product,
  },
});

export default connect(mapState, mapDispatch)(ProductDetailScreen);
