import React from 'react';
import { Dropdown, Menu } from 'antd';
import { PlusOutlined } from "@ant-design/icons";


const SEO_TPL_PARTS = {
    product_detail: [
        {
            value: '[name]',
            title: 'имя',
            example: 'Товар',
        },
        {
            value: '[slug]',
            title: 'путь',
            example: 'tovar-slug',
        },
        {
            value: '[art_no]',
            title: 'артикул',
            example: 'арт.D123',
        },
        {
            value: '[retail_price]',
            title: 'розничная цена',
            example: '1000',
        },
        {
            value: '[text]',
            title: 'описание',
            example: 'описание',
        },
    ],

    category_detail: [
        {
            value: '[name]',
            title: 'имя',
            example: 'Категория',
        },
        {
            value: '[slug]',
            title: 'путь',
            example: 'category-slug',
        },
    ],

    brand_detail: [
        {
            value: '[name]',
            title: 'имя',
            example: 'Бренд',
        },
        {
            value: '[slug]',
            title: 'путь',
            example: 'brand-slug',
        },
    ],
};

const replaceAll = function (target, search, replacement) {
    return target.split(search).join(replacement);
};

export function renderTpl(tpl, type) {
    let res = tpl;
    const parts = SEO_TPL_PARTS[type] || [];
    parts.forEach(part => {
        res = replaceAll(res, part.value, part.example);
        if (part.variants) {
            part.variants.forEach(subPart => {
                res = replaceAll(res, subPart.value, subPart.example);
            });
        }
    });
    return res;
}

const SeoTemplatePartsDropdown = ({ type, onChange }) => {
    const handleClick = (item) => {
        onChange(item);
    };

    const renderItems = () => {
        const parts = SEO_TPL_PARTS[type] || [];
        return (
            <Menu>
                {parts.map((item, idx) => {
                    if (item.variants) {
                        return (
                            <Menu.SubMenu key={`item-p${idx}`} title={item.title}>
                                <Menu.Item key={item.value} onClick={() => handleClick(item)}>
                                    {item.title}
                                </Menu.Item>
                                {item.variants.map((opt, idx2) => (
                                    <Menu.Item key={opt.value} onClick={() => handleClick(opt)}>
                                        {opt.title}
                                    </Menu.Item>
                                ))}
                            </Menu.SubMenu>
                        )
                    } else {
                        return (
                            <Menu.Item key={item.value} onClick={() => handleClick(item)}>{item.title}</Menu.Item>
                        )
                    }
                })}
            </Menu>
        )
    }

    return (
        <Dropdown 
            overlay={renderItems} 
            trigger={['click']} 
            placement="topLeft"
        >
            <PlusOutlined/>
        </Dropdown>
    );
}

export default SeoTemplatePartsDropdown;
