import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { tableColumn } from 'utils';
import { TableList, ActionButton } from 'components/common';
import { PageValue } from 'components/blocks/Page';
import PluginValue from '../PluginValue';


const PluginList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, plugin }) => {
  const defaultColumnList = ['name', 'type', 'pages', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Название', 'name', {
      render: (val, item) => <PluginValue detail={item} asLink={true} isBack={true} />,
      sorter: true,
    }),
    type: tableColumn('Тип', 'type', {
      sorter: true,
    }),
    pages: tableColumn('Страницы', 'pages', {
      render: (val, item) => val.map((id) => <PageValue key={`page${id}`} value={id} asLink={true} isBack={true} />),
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.plugin} actions={[{ name: 'edit' }, { name: 'delete' }]} />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = plugin.filter.activeFilter;
      }
      actions.plugin.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [plugin, actions, setSelected],
  );

  const batchDelete = (selected) => {
    actions.plugin.batchDelete({ selected });
  };

  const navAdd = () => {
    actions.plugin.showEditForm({ 
      show: true, 
      preventLoadDetail: true
    });
  };

  return (
    <TableList
      location={router.location}
      model={plugin}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={[
        <Button type="primary" icon={<PlusOutlined/>} onClick={navAdd}>
          Добавить
        </Button>
      ]}
      showSearch={true}
      selected={selected}
      rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}
      rowActions={[
        {
          title: 'Удалить',
          icon: 'delete',
          action: (selected) => batchDelete(selected),
        },
      ]}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  plugin: state.plugin,
});

const mapDispatch = (dispatch) => ({
  actions: {
    plugin: dispatch.plugin,
  },
});

export default connect(mapState, mapDispatch)(PluginList);
