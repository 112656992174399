import React, { Fragment, useState, useEffect } from 'react';
import { Route } from 'react-router';
import { Layout } from 'antd';

import { Top } from 'components/layout';
import { CategoryTree } from 'components/blocks/Category';
import GroupListScreen from './GroupListScreen';
import GroupDetailScreen from './GroupDetailScreen';
import ProductDetailScreen from './ProductDetailScreen';
import PropertyListScreen from './PropertyListScreen';
import PropertyDetailScreen from './PropertyDetailScreen';
import WarehouseListScreen from './WarehouseListScreen';
import WarehouseDetailScreen from './WarehouseDetailScreen';
import PriceTypeListScreen from './PriceTypeListScreen';
import PriceTypeDetailScreen from './PriceTypeDetailScreen';
import BrandListScreen from './BrandListScreen';
import BrandDetailScreen from './BrandDetailScreen';
import BrandPageDetailScreen from './BrandPageDetailScreen';
import CategoryListScreen from './CategoryListScreen';
import CategoryDetailScreen from './CategoryDetailScreen';
import CategoryPageDetailScreen from './CategoryPageDetailScreen';


const CatalogScreen = ({ match }) => {
  const { url } = match;
  const isGroup = url.startsWith('/catalog/group');
  const isProducts = url === '/catalog' || url.startsWith('/catalog/cat/') || isGroup;

  const [collapsed, setCollapsed] = useState(isGroup);

  useEffect(() => {
    setCollapsed(isGroup);
  }, [isGroup, setCollapsed]);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Fragment>
      <Top
        title="Каталог"
        basePath="/catalog"
        tabs={[
          { title: 'Товары', key: 'detail', match: ['cat/', 'group'] },
          { title: 'Категории', key: 'category' },
          { title: 'Свойства товаров', key: 'property' },
          { title: 'Типы цен', key: 'priceType' },
          { title: 'Склады', key: 'warehouse' },
          { title: 'Бренды', key: 'brand' },
        ]}
      />

      <Layout className="layout-inner">
        {isProducts && (
          <Layout.Sider
            breakpoint="lg"
            className="page-aside"
            width={270}
            collapsible
            trigger={null}
            collapsed={collapsed}
            collapsedWidth={0}
          >
            <CategoryTree match={match} onCollapse={onCollapse} />
          </Layout.Sider>
        )}

        <Layout.Content>
          <Route exact path="/catalog" component={GroupListScreen} />
          <Route exact path="/catalog/cat/:categoryId" component={GroupListScreen} />
          
          <Route exact path="/catalog/category" component={CategoryListScreen} />
          <Route exact path="/catalog/category/:categoryId" component={CategoryDetailScreen} />
          <Route exact path="/catalog/category/:categoryId/page" component={CategoryPageDetailScreen} />
          
          <Route exact path="/catalog/group/:groupId" component={GroupDetailScreen} />
          <Route exact path="/catalog/group/:groupId/products" component={GroupDetailScreen} />
          <Route exact path="/catalog/group/:groupId/recommended" component={GroupDetailScreen} />
          <Route exact path="/catalog/group/:groupId/seo" component={GroupDetailScreen} />

          <Route exact path="/catalog/product/:productId" component={ProductDetailScreen} />

          <Route exact path="/catalog/property" component={PropertyListScreen} />
          <Route exact path="/catalog/property/:propertyId" component={PropertyDetailScreen} />
          <Route exact path="/catalog/priceType" component={PriceTypeListScreen} />
          <Route exact path="/catalog/priceType/:priceTypeId" component={PriceTypeDetailScreen} />
          <Route exact path="/catalog/warehouse" component={WarehouseListScreen} />
          <Route exact path="/catalog/warehouse/:warehouseId" component={WarehouseDetailScreen} />
          
          <Route exact path="/catalog/brand" component={BrandListScreen} />
          <Route exact path="/catalog/brand/:brandId" component={BrandDetailScreen} />
          <Route exact path="/catalog/brand/:brandId/page" component={BrandPageDetailScreen} />
        </Layout.Content>
      </Layout>
    </Fragment>
  );
};

export default CatalogScreen;
