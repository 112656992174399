import React from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';

import { FormItem } from 'utils';


const PropertySelect = (props) => {
  const { onChange, disabled, property, dispatch, defaultValue, ...otherProps } = props;
  return (
    <FormItem {...otherProps}>
      <Select
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="Выберите свойство"
        showSearch={true}
        allowClear={true}
        disabled={disabled}
        filterOption={(input, option) => {
          const children = option.props.children;
          return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {!otherProps.required && <Select.Option value={null}>---</Select.Option>}
        {property.list.map(id => {
          const item = property.byId[id];
          return (
            <Select.Option key={`property${item.id}`} value={item.ext_id}>
              {item.name}
            </Select.Option>
          )
        })}
      </Select>
    </FormItem>
  );
};

const mapState = (state) => ({
  property: state.property,
});

export default connect(mapState)(PropertySelect);
