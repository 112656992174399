import React, { useCallback, useState } from 'react';
import { Switch } from 'antd';
import { UngroupOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import { tableColumn } from 'utils';
import { TableList, ActionButton, DateTimeText, Image, PropValue } from 'components/common';
import ProductValue from '../ProductValue';


const ProductList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, product, groupId }) => {
  const defaultColumnList = [
    'image', 'name', 'properties', 'prices', 'quantity', 'date_changed', 'is_active', 'actions'
  ];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Название', 'name', {
      render: (val, item) => (
        <div>
          <ProductValue detail={item} asLink={true} isBack={true} />
          <div>Артикул: {item.art_no}</div>
          <div>Код: {item.code || '-'}</div>
        </div>
      ),
      sorter: true,
    }),
    image: tableColumn('Картинка', 'image', {
      render: (val, item) => {
        const img = item.images[0];
        return <Image src={img && img.url} width={120} height={100} />;
      },
      width: 120,
      sorter: false,
    }),
    quantity: tableColumn('Кол-во', 'quantity', {
      width: 80,
      sorter: true,
    }),
    prices: tableColumn('Цены', 'prices', {
      render: (val, detail) => {
        const prices = detail.prices.map(item => (
          item.is_active && <PropValue key={`price${item.ext_id}`} label={item.name} value={item.price}/>
        ));
        return (
          <div>
            <PropValue label="Старая цена" value={detail.old_price || '-'}/>
            <PropValue label="Цена сайта" value={detail.site_price || '-'}/>
            {prices}
          </div>
        );
      },
      width: 150,
      sorter: true,
    }),
    properties: tableColumn('Свойства', 'properties', {
      render: (val, detail) => {
        const props = detail.properties.map(item => (
          item.is_active && <PropValue key={`prop${item.ext_id}`} label={item.name} value={item.value}/>
        ));
        return <div>{props}</div>;
      },
      width: 120,
      sorter: false,
    }),
    date_created: tableColumn('Дата создания', 'date_created', {
      render: (val, item) => <DateTimeText date={val} />,
      sorter: true,
      width: 150,
    }),
    date_changed: tableColumn('Дата изменения', 'date_changed', {
      render: (val, item) => <DateTimeText date={val} />,
      sorter: true,
      width: 120,
    }),
    is_active: tableColumn('Активен', 'is_active', {
      sorter: true,
      width: 100,
      render: (val, item) => (
        <div>
          <Switch
            style={{ marginTop: '5px', minWidth: '62px' }}
            checkedChildren="вкл"
            unCheckedChildren="выкл"
            checked={item.is_active}
            loading={product.updateStatus.isLoading && itemIdForUpdate === item.id}
            onClick={() => onProductOnOff(item)}
          />
        </div>
      ),
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.product} actions={[{ name: 'edit' }, { name: 'delete' }]} />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = product.filter.activeFilter;
      }
      actions.product.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [product, actions, setSelected],
  );

  const onProductOnOff = (item) => {
    const data = {
      id: item.id,
      is_active: !item.is_active,
      preventLoadDetail: true,
    };
    setItemIdForUpdate(item.id);
    actions.product.update(data);
  };

  const showBatchProductForm = (selected) => {
    actions.product.showBatchForm({ show: true, selected, object: { selected } });
  };

  const productUncombine = (selected) => {
    actions.group.batchUncombine({ group_id: groupId, selected });
  };

  const batchDelete = (selected) => {
    actions.product.batchDelete({ selected });
  };

  return (
    <TableList
      location={router.location}
      model={product}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      selected={selected}
      //childrenColumnName="offers"
      rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}
      rowActions={[
        {
          title: 'Редактировать свойства',
          icon: 'edit',
          type: 'primary',
          action: (selected) => showBatchProductForm(selected),
        },
        {
          title: 'Разъединить',
          icon: <UngroupOutlined />,
          type: 'primary',
          action: (selected) => productUncombine(selected),
        },
        {
          title: 'Удалить',
          icon: 'delete',
          action: (selected) => batchDelete(selected),
        },
      ]}
      filterFields={{
        list: ['is_active'],
        byName: {
          is_active: {
            label: 'Активно',
            type: 'bool',
          },
        },
      }}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  product: state.product,
});

const mapDispatch = (dispatch) => ({
  actions: {
    product: dispatch.product,
    group: dispatch.group,
  },
});

export default connect(mapState, mapDispatch)(ProductList);
