import React from 'react';
import { Input, InputNumber, Checkbox, Row, Col } from 'antd';

import { FormItem } from 'utils';
import { ImageUpload } from 'components/common';
import { CategorySelect } from 'components/blocks/Category';
import { GroupSelect } from 'components/blocks/Group';
import { BrandSelectExtId } from 'components/blocks/Brand';


const MainFieldSet = ({ plugin, auth_token, site, ...otherProps }) => {
  const { editDetail, editItemFields } = plugin;

  return (
    <Row gutter={32}>
      <Col span={24}>
        <div className="form-block">
          {editItemFields.map(item => {
            switch (item.type) {
              case 'category':
                return (
                  <CategorySelect 
                    {...otherProps} 
                    key={`field${item.name}`} 
                    name={item.name} 
                    label={item.label} 
                    required={item.required}
                  />
                );

              case 'group':
                return (
                  <GroupSelect 
                    {...otherProps} 
                    key={`field${item.name}`} 
                    name={item.name} 
                    label={item.label} 
                    required={item.required}
                  />
                );
              
              case 'brand':
                return (
                  <BrandSelectExtId 
                    {...otherProps} 
                    key={`field${item.name}`} 
                    name={item.name} 
                    label={item.label} 
                    required={item.required}
                  />
                );

              case 'image':
                return (
                  <FormItem 
                    {...otherProps} 
                    key={`field${item.name}`} 
                    name={item.name} 
                    label={item.label} 
                    required={item.required}
                    valuePropName="fileList"
                  >
                    <ImageUpload 
                      title={`Загрузить ${item.label}`}
                      mode='single'
                      auth_token={auth_token}
                      siteDomain={site.activeDomain}
                      modelName="plugin.plugin"
                      data={{ext_id: editDetail.id}}
                    />
                  </FormItem>
                )
              case 'int':
                return (
                  <FormItem 
                    {...otherProps} 
                    key={`field${item.name}`} 
                    name={item.name} 
                    label={item.label} 
                    required={item.required}
                  >
                    <InputNumber size="large" />
                  </FormItem>
                )
              case 'bool':
                return (
                  <FormItem 
                    {...otherProps} 
                    key={`field${item.name}`} 
                    name={item.name} 
                    label={item.label} 
                    required={item.required}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </FormItem>
                )
              default:
                return (
                  <FormItem 
                    {...otherProps} 
                    key={`field${item.name}`} 
                    name={item.name} 
                    label={item.label} 
                    required={item.required}
                  >
                    <Input size="large" />
                  </FormItem>
                )
            }
          })}
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
