import React from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from 'antd';

import { Navigation } from 'components/layout';
import { Logo } from 'components/common';
import { UserPanel } from 'components/blocks/User';
import DashboardScreen from 'screens/DashboardScreen';
import CatalogScreen from 'screens/Catalog';
import SiteScreen from 'screens/Site';
import OrderScreen from 'screens/Order';
import NotificationScreen from 'screens/Notification'


const MainScreen = () => {
  return (
    <Layout className="app">
      <Layout.Sider collapsed={true} breakpoint="lg" collapsedWidth="80" className="app-sider">
        <Logo />
        <Navigation />
        <UserPanel />
      </Layout.Sider>
      <Layout className="app-main">
        <Switch>
          <Route exact path="/" component={DashboardScreen} />

          <Route exact path="/catalog" component={CatalogScreen} />
          <Route path="/catalog/cat/:categoryId" component={CatalogScreen} />
          <Route path="/catalog/category/" component={CatalogScreen} />
          <Route path="/catalog/category/:categoryId" component={CatalogScreen} />
          <Route path="/catalog/group/:groupId" component={CatalogScreen} />
          <Route path="/catalog/product/:productId" component={CatalogScreen} />
          <Route path="/catalog/property/" component={CatalogScreen} />
          <Route path="/catalog/property/:propertyId" component={CatalogScreen} />
          <Route path="/catalog/priceType/" component={CatalogScreen} />
          <Route path="/catalog/priceType/:priceTypeId" component={CatalogScreen} />
          <Route path="/catalog/warehouse/" component={CatalogScreen} />
          <Route path="/catalog/warehouse/:warehouseId" component={CatalogScreen} />
          <Route path="/catalog/brand/" component={CatalogScreen} />
          <Route path="/catalog/brand/:brandId" component={CatalogScreen} />

          <Route path="/site" component={SiteScreen} />
          <Route path="/site/nav" component={SiteScreen} />
          <Route path="/site/nav/:navMainId" component={SiteScreen} />
          <Route path="/site/nav/top/:navTopId" component={SiteScreen} />
          <Route path="/site/nav/footer/:navFooterId" component={SiteScreen} />
          <Route path="/site/plugin" component={SiteScreen} />

          <Route path="/notification" component={NotificationScreen} />
          <Route path="/notification/:notificationTemplateId" component={NotificationScreen} />
          <Route path="/notification/list" component={NotificationScreen} />
          <Route path="/notification/list/:notificationId" component={NotificationScreen} />

          <Route path="/order" component={OrderScreen} />
          <Route path="/order/:orderId" component={OrderScreen} />
        </Switch>
      </Layout>
    </Layout>
  );
};

export default MainScreen;
