import './styles.css'

import { CategoryValue } from 'components/blocks/Category'
import { ProductValue } from 'components/blocks/Product'
import { BrandValue } from 'components/blocks/Brand'


const PageObjectValue = ({ page_type, object_id }) => {
  if (!object_id) return null;

  if (page_type === 'category_detail') {
    return <CategoryValue detailId={object_id} asLink={true} isBack={true} />
  }

  if (page_type === 'product_detail') {
    return <ProductValue detailId={object_id} asLink={true} isBack={true} />
  }

  if (page_type === 'brand_detail') {
    return <BrandValue detailId={object_id} asLink={true} isBack={true} />
  }

  return (
    <div>{object_id}</div>
  );
};

export default PageObjectValue
