import React from 'react';
import classNames from 'classnames';
import styles from './loader.module.css';

const Loader = ({ active, fixed, color }) => {
  if (!active) return null;

  const cx = classNames([
    styles.main,
    fixed && styles.mainFixed,
    color && styles[`color_${color}`] && styles[`color_${color}`],
  ]);

  return (
    <div className={cx}>
      <div className={styles.spinner}>
        <div className={styles.bounce1} />
        <div className={styles.bounce2} />
        <div className={styles.bounce3} />
      </div>
    </div>
  );
};

export default Loader;
