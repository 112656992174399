import { CATALOG_API_URL } from 'config';
import { api, getHeaders } from 'utils';
import * as base from '../base';

const endpoints = {
  list: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/product/`,
  detail: (siteDomain) => (id) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/product/${id}/`,
  sectionAdd: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/product/section_add/`,
  batchDelete: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/product/delete/`,
  batchUpdate: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/product/update/`,
};

export const productListLoad = (siteDomain) => (token, payload) => api.ListLoad(endpoints.list(siteDomain), token, payload);
export const productDetailLoad = (siteDomain) => (token, { id }) => api.DetailLoad(endpoints.detail(siteDomain), token, { id });
export const productUpdate = (siteDomain) => (token, data) => api.Update(endpoints.list(siteDomain), endpoints.detail(siteDomain), token, data);
export const productDelete = (siteDomain) => (token, data) => api.Delete(endpoints.detail(siteDomain), token, data);
export const productSectionAdd = (siteDomain) => (token, data) =>
  api.Load('post', endpoints.sectionAdd(siteDomain), data, {
    headers: getHeaders(token),
  });
export const productBatchDelete = (siteDomain) => (token, data) =>
  api.Load('post', endpoints.batchDelete(siteDomain), data, {
    headers: getHeaders(token),
  });
export const productBatchUpdate = (siteDomain) => (token, data) =>
  api.Load('post', endpoints.batchUpdate(siteDomain), data, {
    headers: getHeaders(token),
  });

export const product = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    showSectionForm: false,
    showBrandForm: false,
    showBatchForm: false,
    selected: null,

    title: 'Товары',
    urls: {
      list: () => `/catalog/product`,
      detail: (id) => `/catalog/product/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,

    showSectionForm(state, payload) {
      return {
        ...state,
        showSectionForm: payload.show,
        editObject: payload.object,
        editPreventLoadDetail: payload.preventLoadDetail,
        selected: payload.selected,
      };
    },
    showBrandForm(state, payload) {
      return {
        ...state,
        showBrandForm: payload.show,
        editObject: payload.object,
        editPreventLoadDetail: payload.preventLoadDetail,
        selected: payload.selected,
      };
    },
    showBatchForm(state, payload) {
      return {
        ...state,
        showBatchForm: payload.show,
        editObject: payload.object,
        editPreventLoadDetail: payload.preventLoadDetail,
        selected: payload.selected,
      };
    },
  },
  effects: (dispatch) => ({
    async listLoad(payload, rootState) {
      const apiRequest = productListLoad(rootState.site.activeDomain);
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'product', apiRequest);
    },

    async detailLoad(payload, rootState) {
      const apiRequest = productDetailLoad(rootState.site.activeDomain);
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'product', apiRequest);
    },

    async update(payload, rootState) {
      const apiRequest = productUpdate(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'product', apiRequest);
    },

    async delete(payload, rootState) {
      const apiRequest = productDelete(rootState.site.activeDomain);
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'product', apiRequest);
    },

    async sectionAdd(payload, rootState) {
      const apiRequest = productSectionAdd(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'product', apiRequest, async (data) => {
        dispatch['product'].showSectionForm({ show: false });
        const filter = rootState['product'].filter;
        await dispatch['product'].listLoad(filter);
      });
    },

    async batchUpdate(payload, rootState) {
      const apiRequest = productBatchUpdate(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'product', apiRequest, async (data) => {
        dispatch['product'].showBrandForm({ show: false });
        dispatch['product'].showBatchForm({ show: false });
        const filter = rootState['product'].filter;
        await dispatch['product'].listLoad(filter);
      });
    },

    async batchDelete(payload, rootState) {
      const apiRequest = productBatchDelete(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'product', apiRequest);
    },
  }),
};
