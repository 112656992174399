import { push } from "connected-react-router";

import { CATALOG_API_URL } from 'config';
import { api } from 'utils';
import * as base from '../base';

const endpoints = {
  list: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/page/`,
  detail: (siteDomain) => (id) => `${CATALOG_API_URL}/api/v1/${siteDomain}/page/${id}/`,
};

export const pageListLoad = (siteDomain) => (token, payload) => api.ListLoad(endpoints.list(siteDomain), token, payload);
export const pageDetailLoad = (siteDomain) => (token, { id }) => api.DetailLoad(endpoints.detail(siteDomain), token, { id });
export const pageUpdate = (siteDomain) => (token, data) => api.Update(endpoints.list(siteDomain), endpoints.detail(siteDomain), token, data);
export const pageDelete = (siteDomain) => (token, data) => api.Delete(endpoints.detail(siteDomain), token, data);

export const page = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Страницы',
    urls: {
      list: () => `/site/page`,
      detail: (id) => `/site/page/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: (dispatch) => ({
    async listLoad(payload, rootState) {
      const apiRequest = pageListLoad(rootState.site.activeDomain);
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'page', apiRequest);
    },

    async detailLoad(payload, rootState) {
      const apiRequest = pageDetailLoad(rootState.site.activeDomain);
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'page', apiRequest);
    },

    async update(payload, rootState) {
      const apiRequest = pageUpdate(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'page', apiRequest, async (data) => {
        dispatch['page'].showEditForm({ show: false });
        if (payload.objectDetailLoad) {
          let objectModel = null;
          switch (data?.page_type) {
            case 'category_detail':
              objectModel = 'category';
              break;
            case 'product_detail':
              objectModel = 'product';
              break;
            case 'brand_detail':
              objectModel = 'brand';
              break;
            default:
              objectModel = null;
          }
          if (objectModel) {
            await dispatch[objectModel].detailLoad({ id: data?.object_id });
          }
        } else if (!payload.preventLoadDetail) {
          dispatch(push(rootState['page'].urls.detail(data.id)));
        } else {
          const filter = rootState['page'].filter;
          await dispatch['page'].listLoad(filter);
        }
      });
    },

    async delete(payload, rootState) {
      const apiRequest = pageDelete(rootState.site.activeDomain);
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'page', apiRequest);
    },
  }),
};
