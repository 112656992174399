import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { SeoTemplatePageList, SeoTemplatePageForm } from 'components/blocks/SeoTemplatePage';


const SeoTemplatePageListScreen = ({ history, site, seoTemplatePage, actions }) => {
  return (
    <Page
      title="Шаблоны страниц"
      info={seoTemplatePage.filter.query ? `Найдено: ${seoTemplatePage.count}`: ""}
      modelActions={actions.seoTemplatePage}
      breadcrumbs={[
        { title: 'Сайт', path: '/site' },
        { title: 'Шаблоны страниц', path: seoTemplatePage.urls.list() },
      ]}
      actions={[
        {
          mode: 'button',
          name: 'add',
          type: 'primary',
          title: 'Добавить шаблон',
        },
      ]}
    >
      <div className="page-main">
        <SeoTemplatePageList />
      </div>

      <SeoTemplatePageForm />
    </Page>
  );
};

const mapState = (state) => ({
  site: state.site,
  seoTemplatePage: state.seoTemplatePage,
});

const mapDispatch = (dispatch) => ({
  actions: {
    seoTemplatePage: dispatch.seoTemplatePage,
  },
});

export default connect(mapState, mapDispatch)(SeoTemplatePageListScreen);
