import React from 'react';
import { Checkbox, Row, Col } from 'antd';

import { FormItem } from 'utils';


const SyncSettingsFieldSet = (props) => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <div className="form-block">
          <FormItem {...props} name="is_import_properties" required={false} valuePropName="checked">
            <Checkbox>импортировать свойства</Checkbox>
          </FormItem>

          <FormItem {...props} name="is_import_categories" label="" required={false} valuePropName="checked">
            <Checkbox>импортировать категории</Checkbox>
          </FormItem>

          <FormItem {...props} name="is_import_product_images" label="" required={false} valuePropName="checked">
            <Checkbox>импортировать картинки товаров</Checkbox>
          </FormItem>

          <FormItem {...props} name="is_import_product_name" label="" required={false} valuePropName="checked">
            <Checkbox>импортировать названия товаров</Checkbox>
          </FormItem>

          <FormItem {...props} name="is_import_product_art_no" label="" required={false} valuePropName="checked">
            <Checkbox>импортировать артикул товаров</Checkbox>
          </FormItem>

          <FormItem {...props} name="is_import_product_text" label="" required={false} valuePropName="checked">
            <Checkbox>импортировать описания товаров</Checkbox>
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default SyncSettingsFieldSet;
