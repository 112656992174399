import React from 'react';
import { connect } from 'react-redux';

import PluginType from '../PluginType';


const PluginDetail = ({ plugin, actions }) => {
  const detail = plugin.detail || {};
  const pluginType = PluginType[detail.type];

  if (pluginType) {
    return <pluginType.detailComponent/>
  }

  return null;
};

const mapState = (state) => ({
  plugin: state.plugin,
});

const mapDispatch = (dispatch) => ({
  actions: {
    plugin: dispatch.plugin,
  },
});

export default connect(mapState, mapDispatch)(PluginDetail);
