import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';

const GroupCombineForm = (props) => {
  const { group } = props;
  const { showCombineForm, selected } = group;

  const onClose = () => {
    props.actions.group.showCombineForm({ show: false });
  };

  const onSubmit = (data) => {
    const newData = Object.assign({ selected }, data);
    props.actions.group.batchCombine(newData);
  };

  let name = '';
  let art_no = '';
  let text = '';
  if (selected && selected.length) {
    const item = group.byId[selected[0]];
    name = item.name;
    art_no = item.art_no;
    text = item.text;
  }

  return (
    <Form
      name="groupCombineForm"
      title="Объединение товаров"
      model={props.group}
      visible={showCombineForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({
        ...editObject,
        name,
        art_no,
        text,
      })}
    >
      <MainFieldSet {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  auth_token: state.auth.token,
  site: state.site,
  group: state.group,
});

const mapDispatch = (dispatch) => ({
  actions: {
    group: dispatch.group,
  },
});

export default connect(mapState, mapDispatch)(GroupCombineForm);
