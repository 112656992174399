import { CATALOG_API_URL } from 'config';
import { api } from 'utils';
import * as base from '../base';

const endpoints = {
  list: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/notification/staff_subscriber/`,
  detail: (siteDomain) => (id) => `${CATALOG_API_URL}/api/v1/${siteDomain}/notification/staff_subscriber/${id}/`,
};

export const notificationStaffSubscriberListLoad = (siteDomain) => (token, payload) => api.ListLoad(endpoints.list(siteDomain), token, payload);
export const notificationStaffSubscriberDetailLoad = (siteDomain) => (token, { id }) => api.DetailLoad(endpoints.detail(siteDomain), token, { id });
export const notificationStaffSubscriberUpdate = (siteDomain) => (token, data) => api.Update(endpoints.list(siteDomain), endpoints.detail(siteDomain), token, data);
export const notificationStaffSubscriberDelete = (siteDomain) => (token, data) => api.Delete(endpoints.detail(siteDomain), token, data);

export const notificationStaffSubscriber = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Подписчики',
    urls: {
      list: () => `/notification_staff_subscriber`,
      detail: (id) => `/notification_staff_subscriber/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: (dispatch) => ({
    async listLoad(payload, rootState) {
      const apiRequest = notificationStaffSubscriberListLoad(rootState.site.activeDomain);
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'notificationStaffSubscriber', apiRequest);
    },

    async detailLoad(payload, rootState) {
      const apiRequest = notificationStaffSubscriberDetailLoad(rootState.site.activeDomain);
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'notificationStaffSubscriber', apiRequest);
    },

    async update(payload, rootState) {
      const apiRequest = notificationStaffSubscriberUpdate(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'notificationStaffSubscriber', apiRequest);
    },

    async delete(payload, rootState) {
      const apiRequest = notificationStaffSubscriberDelete(rootState.site.activeDomain);
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'notificationStaffSubscriber', apiRequest);
    },
  }),
};
