import React from 'react';

import { NavMainDetail } from 'components/blocks/NavMain';

const DetailScreen = (props) => {
  return (
    <div className="page-main">
      <NavMainDetail />
    </div>
  );
};

export default DetailScreen;
