import React from 'react';
import { Input, InputNumber, Row, Col } from 'antd';

import { FormItem } from 'utils';
import { PageSelect } from 'components/blocks/Page';


const MainFieldSet = (props) => {
  const { plugin, ...otherProps } = props;
  return (
    <Row gutter={32}>
      <Col span={24}>
        <div className="form-block">
          <FormItem {...otherProps} name="title" label="Тайтл" required={true}>
            <Input size="large" />
          </FormItem>

          <FormItem {...otherProps} name="path" label="Путь" required={true}>
            <Input size="large" />
          </FormItem>

          <PageSelect {...otherProps} name="page" label="Страница" required={false} multiple={false}/>

          <FormItem {...otherProps} name="sort" label="Сортировка" required={false}>
            <InputNumber size="large" />
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
