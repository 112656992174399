import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';
import PropertiesFieldSet from './PropertiesFieldSet';
import WarehousesFieldSet from './WarehousesFieldSet';
import PricesFieldSet from './PricesFieldSet';


const FormContent = (props) => {
  return (
    <Tabs className="tabsForm" type="card">
      <Tabs.TabPane tab="Основное" key="detail" forceRender={true}>
        <MainFieldSet {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Свойства" key="properties" forceRender={true}>
        <PropertiesFieldSet {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Остатки" key="warehouses" forceRender={true}>
        <WarehousesFieldSet {...props}/>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Цены" key="prices" forceRender={true}>
        <PricesFieldSet {...props}/>
      </Tabs.TabPane>
    </Tabs>
  )
}


const ProductForm = (props) => {
  const { propertyConfig, warehouse, priceType, product } = props;
  const { showEditForm, editObject } = product;

  const onClose = () => {
    props.actions.product.showEditForm({ show: false });
  };

  const onSubmit = (data) => {
    let newData = Object.assign(data, {
      site: props.site.activeId,
    });

    const properties = data.properties || [];
    newData._properties = properties.reduce((result, item) => {
      const ext_id = Object.keys(item)[0];
      const value = item[ext_id];
      if (value) {
        result[ext_id] = {
          property_ext_id: ext_id,
          value: value,
        };
      }
      return result;
    }, {});

    const warehouses = data.warehouses || [];
    newData._warehouses = warehouses.reduce((result, item) => {
      const ext_id = Object.keys(item)[0];
      const value = item[ext_id];
      if (value) {
        result[ext_id] = {
          warehouse_ext_id: ext_id,
          quantity: value,
        };
      }
      return result;
    }, {});

    const prices = data.prices || [];
    newData._prices = prices.reduce((result, item) => {
      const ext_id = Object.keys(item)[0];
      const value = item[ext_id];
      if (value) {
        result[ext_id] = {
          price_type_ext_id: ext_id,
          price: value,
        };
      }
      return result;
    }, {});

    props.actions.product.update(newData);
  };

  let properties = {};
  let warehouses = {};
  let prices = {};

  if (editObject && editObject.id) {
    const item = editObject;
    const itemProperties = (item.properties || []).reduce((result, item) => {
      result[item.ext_id] = item.value;
      return result;
    }, {});
    const itemWarehouses = (item.warehouses || []).reduce((result, item) => {
      result[item.ext_id] = item.quantity;
      return result;
    }, {});
    const itemPrices = (item.prices || []).reduce((result, item) => {
      result[item.ext_id] = item.price;
      return result;
    }, {});

    properties = propertyConfig.map(item => {
      return {
        [item.property_ext_id]: itemProperties[item.property_ext_id] || '',
      };
    });

    warehouses = warehouse.list.reduce((res, id) => {
      const item = warehouse.byId[id];
      if (item.is_active) {
        res.push({[item.ext_id]: itemWarehouses[item.ext_id] || ''})
      }
      return res;
    }, []);

    prices = priceType.list.reduce((res, id) => {
      const item = priceType.byId[id];
      if (item.is_active) {
        res.push({[item.ext_id]: itemPrices[item.ext_id] || ''})
      }
      return res;
    }, []);
  }

  return (
    <Form
      width={800}
      title="Редактирование товара"
      model={props.product}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({
        ...editObject,
        properties: properties,
        warehouses: warehouses,
        prices: prices,
      })}
    >
      <FormContent {...props}/>
    </Form>
  );
};

const mapState = (state) => ({
  site: state.site,
  auth_token: state.auth.token,
  product: state.product,
  warehouse: state.warehouse,
  priceType: state.priceType,
});

const mapDispatch = (dispatch) => ({
  actions: {
    product: dispatch.product,
  },
});

export default connect(mapState, mapDispatch)(ProductForm);
