const ENV = {
  local: {
    gAuthUrl: 'http://127.0.0.1:8010',
    gCatalogUrl: 'http://127.0.0.1:8000',
    gSyncUrl: 'http://127.0.0.1:8020',
  },
  production: {
    gAuthUrl: 'https://gauth.lampstudio.ru',
    gCatalogUrl: 'https://gcatalog-api.lampstudio.ru',
    gSyncUrl: 'https://gsync-api.lampstudio.ru',
  },
};

const urls = ENV['production'];

export const AUTH_API_URL = urls.gAuthUrl;
export const CATALOG_API_URL = urls.gCatalogUrl;
export const SYNC_API_URL = urls.gSyncUrl;
