import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { PriceTypeForm } from 'components/blocks/PriceType';
import DetailScreen from './DetailScreen';

const PriceTypeDetailScreen = ({ history, priceType, actions, match }) => {
  useEffect(() => {
    actions.priceType.detailLoad({ id: match.params.priceTypeId });
  }, [match.params.priceTypeId, actions.priceType]);

  const id = match.params.priceTypeId;
  const detail = priceType.detail || {};

  return (
    <Page
      isLoading={priceType.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.priceType}
      breadcrumbs={[
        { title: 'Типы цен', path: priceType.urls.list() },
        { title: detail.name, path: priceType.urls.detail(id) },
      ]}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={priceType.urls.detail(id)}
    >
      <Route exact path="/catalog/priceType/:priceTypeId" component={DetailScreen} />

      <PriceTypeForm />
    </Page>
  );
};

const mapState = (state) => ({
  priceType: state.priceType,
});

const mapDispatch = (dispatch) => ({
  actions: {
    priceType: dispatch.priceType,
  },
});

export default connect(mapState, mapDispatch)(PriceTypeDetailScreen);
