import React, { Fragment } from 'react';
import { Route } from 'react-router';
import { Layout } from 'antd';

import { Top } from 'components/layout';
import OrderListScreen from './OrderListScreen';
import OrderDetailScreen from './OrderDetailScreen';


const OrderScreen = ({ match }) => {
  return (
    <Fragment>
      <Top
        title="Заказы"
        basePath="/order"
        tabs={[
          { title: 'Заказы', key: 'detail' },
          /*{ title: 'Оплаты', key: 'payment' },*/
        ]}
      />

      <Layout className="layout-inner">
        <Layout.Content>
          <Route exact path="/order" component={OrderListScreen} />
          <Route exact path="/order/:orderId" component={OrderDetailScreen} />
        </Layout.Content>
      </Layout>
    </Fragment>
  );
};

export default OrderScreen;
