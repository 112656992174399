import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { CategoryForm } from 'components/blocks/Category';
import { GroupList, GroupForm, GroupBrandForm, GroupCategoryForm, GroupCombineForm } from 'components/blocks/Group';


const GroupListScreen = ({ match, category, actions }) => {
  const { categoryId } = match.params;
  const categoryDetail = category.detail || {};

  useEffect(() => {
    if (categoryId !== undefined && categoryId !== 'all' && categoryId !== 'empty') {
      actions.category.detailLoad({ id: categoryId });
    }
  }, [categoryId, actions.category]);

  const breadcrumbs = useMemo(() => {
    let result = [{ title: 'Каталог', path: category.urls.list() }];
    const ancestors = categoryDetail.ancestors || [];
    return result.concat(ancestors.map((item) => ({ title: item.name, path: category.urls.groupList(item.id) })));
  }, [categoryDetail.ancestors, category.urls]);

  const title = categoryDetail.title || { all: 'Все товары', 'empty': 'Товары без категории' }[categoryId] || 'Все товары';

  let activeFilter = null;
  if (categoryId !== 'all') {
    activeFilter = { categories: categoryId };
  }

  return (
    <Page
      title={title}
      isLoading={category.loadDetailStatus.isLoading}
      modelActions={actions.category}
      detail={categoryDetail}
      breadcrumbs={breadcrumbs}
      actions={[
        {
          mode: 'button',
          name: 'addGroup',
          type: 'primary',
          title: 'Добавить товар',
        },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'edit' }],
        },
      ]}
      actionByName={{
        addGroup: (modelActions, item) => ({
          title: 'Добавить товар',
          action: () => actions.group.showEditForm({
            show: true,
            object: { categories: categoryDetail.id },
          }),
        }),
      }}
    >
      <div className="page-main">
        <GroupList activeFilter={activeFilter} />
      </div>

      <CategoryForm />
      <GroupForm />
      <GroupBrandForm />
      <GroupCategoryForm />
      <GroupCombineForm />
    </Page>
  );
};

const mapState = (state) => ({
  category: state.category,
});

const mapDispatch = (dispatch) => ({
  actions: {
    category: dispatch.category,
    group: dispatch.group,
    product: dispatch.product,
  },
});

export default connect(mapState, mapDispatch)(GroupListScreen);
