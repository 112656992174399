import React from 'react';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';

import PageHeader from '../PageHeader';
import './styles.css';
import './page_transitions.css';

const Page = (props) => {
  const { children, isLoading, ...headerProps } = props;
  return (
    <Spin spinning={isLoading || false}>
      <div className="page">
        <PageHeader {...headerProps} />

        <div className="page-content">
          <div className="page-content-inner">{children}</div>
        </div>
      </div>
    </Spin>
  );
};

export default withRouter(Page);
