import React from 'react';

import styles from './prop.module.css';

const PropValue = ({ label, value }) => {
  return (
    <div className={styles.prop}>
      <div className={styles.propLabel}>{label}:</div>
      <div className={styles.propValue}>{value}</div>
    </div>
  );
};

export default PropValue;
