import React from 'react';
import { connect } from 'react-redux';

import { Fieldset } from 'components/layout';
import { ProductList } from 'components/blocks/Product';


const ProductsScreen = (props) => {
  const detail = props.group.detail || {};

  return (
    <div className="pageMainWithTabs">
      <Fieldset title="Товары">
        {detail.id && <ProductList activeFilter={{ group: detail.id }} groupId={detail.id} />}
      </Fieldset>
    </div>
  );
};

const mapState = (state) => ({
  group: state.group,
});

export default connect(mapState)(ProductsScreen);
