import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Menu, Dropdown, Avatar } from 'antd';
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';

import { getFullNameOrEmail } from 'utils/helpers';
import './styles.css';

const UserPanel = ({ currentUser, actions, user }) => {
  const handleMenuClick = ({ key }) => {
    if (key === 'logout') {
      actions.auth.logout();
    } else {
      actions.router.push(`/${key}`);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="setting">
        <SettingOutlined />
        <span>Настройки</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined />
        <span>Выход</span>
      </Menu.Item>
    </Menu>
  );

  const name = getFullNameOrEmail(currentUser);

  return (
    <Dropdown overlay={menu} overlayClassName="user-pnael-dropdown">
      <div className="user-panel">
        <Avatar size="small" className="user-panel-avatar" src={currentUser.photo} alt="avatar">
          {name.charAt(0)}
        </Avatar>
        <span className="user-panel-name">
          <span>{name}</span>
        </span>
      </div>
    </Dropdown>
  );
};

const mapState = (state) => ({
  currentUser: state.user.current,
});

const mapDispatch = (dispatch) => ({
  actions: {
    router: {
      push: (payload) => dispatch(push(payload)),
    },
    auth: dispatch.auth,
  },
});

export default connect(mapState, mapDispatch)(UserPanel);
