import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined, GlobalOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import './styles.css';

const SiteChooserPanel = ({ site, actions }) => {
  const select = (id) => {
    actions.site.select(id);
  };

  const renderMenu = () => {
    return (
      <Menu className="menuInner">
        {site.list.map(id => {
          const item = site.byId[id];
          const cx = classNames("menuItem",
            {"menuItemActive": id === site.activeId}
          );
          return (
            <Menu.Item key={id} onClick={() => select(id)} className={cx}>
              <div className="menuLogo">
                <GlobalOutlined />
              </div>

              <div className="menuContent">
                <div className="menuTitle">{item.domain}</div>
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return (
    <Dropdown
      overlay={() => renderMenu()}
      overlayClassName="site-chooser-menu"
      placement="bottomCenter"
      trigger={['click']}
    >
      <div className="site-chooser">
        <div className="site-chooser-logo">
          <GlobalOutlined />
        </div>
        <div className="site-chooser-main">
          <div className="site-chooser-title">{site.activeDomain}</div>
        </div>
        <div className="site-chooser-icon">
          <CaretDownOutlined />
        </div>
      </div>
    </Dropdown>
  );
};

const mapState = (state) => ({
  site: state.site,
});

const mapDispatch = (dispatch) => ({
  actions: {
    site: dispatch.site,
  },
});

export default connect(mapState, mapDispatch)(SiteChooserPanel);
