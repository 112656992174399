import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { WarehouseList, WarehouseForm } from 'components/blocks/Warehouse';

const WarehouseListScreen = ({ history, warehouse, actions }) => {
  return (
    <Page
      title="Склады"
      info={warehouse.filter.query ? `Найдено: ${warehouse.count}`: ""}
      modelActions={actions.warehouse}
      breadcrumbs={[{ title: 'Склады', path: warehouse.urls.list() }]}
      actions={[
        {
          mode: 'button',
          name: 'add',
          type: 'primary',
          title: 'Добавить склад',
        },
      ]}
    >
      <div className="page-main">
        <WarehouseList />
      </div>

      <WarehouseForm />
    </Page>
  );
};

const mapState = (state) => ({
  warehouse: state.warehouse,
});

const mapDispatch = (dispatch) => ({
  actions: {
    warehouse: dispatch.warehouse,
  },
});

export default connect(mapState, mapDispatch)(WarehouseListScreen);
