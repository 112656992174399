import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { tableColumn } from 'utils';
import { TableList, ActionButton } from 'components/common';
import { PageValue } from 'components/blocks/Page';
import NavFooterValue from '../NavFooterValue';


const NavFooterList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, navFooter }) => {
  const defaultColumnList = ['title', 'path', 'page', 'sort', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    title: tableColumn('Тайтл', 'title', {
      render: (val, item) => <NavFooterValue detail={item} asLink={true} isBack={true} />,
      sorter: true,
    }),
    path: tableColumn('Путь', 'path', {
      sorter: true,
    }),
    page: tableColumn('Страница', 'page', {
      render: (val, item) => <PageValue detail={val} asLink={true} isBack={true} />,
      sorter: true,
    }),
    sort: tableColumn('Сортировка', 'sort', {
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.navFooter} actions={[{ name: 'edit' }, { name: 'delete' }]} />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = navFooter.filter.activeFilter;
      }
      actions.navFooter.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [navFooter, actions, setSelected],
  );

  const batchDelete = (selected) => {
    actions.navFooter.batchDelete({ selected });
  };

  const navAdd = () => {
    actions.navFooter.showEditForm({ 
      show: true, 
      preventLoadDetail: true
    });
  };

  return (
    <TableList
      location={router.location}
      model={navFooter}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={[
        <Button type="primary" icon={<PlusOutlined/>} onClick={navAdd}>
          Добавить
        </Button>
      ]}
      showSearch={true}
      selected={selected}
      rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}
      rowActions={[
        {
          title: 'Удалить',
          icon: 'delete',
          action: (selected) => batchDelete(selected),
        },
      ]}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  navFooter: state.navFooter,
});

const mapDispatch = (dispatch) => ({
  actions: {
    navFooter: dispatch.navFooter,
  },
});

export default connect(mapState, mapDispatch)(NavFooterList);
