import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { categoryFlatListLoad } from 'store/models/category';
import { tableColumn } from 'utils';
import { TableList, ActionButton } from 'components/common';
import { CategoryValue } from 'components/blocks/Category';


const PluginDataCategoryList = ({ detail, fieldName, items, extIds, actions, site, plugin, authToken }) => {
  //const [loading, setLoading] = useState(false);
  const [innerItems, setInnerItems] = useState([]);

  const apiLoad = categoryFlatListLoad(site.activeDomain);
  const ext_ids = extIds.join(',');

  useEffect(() => {
    let _isSubscribed = true;
    console.log(ext_ids);

    //setLoading(true);
    apiLoad(authToken, { activeFilter: {ext_id: ext_ids} }).then(({ error, data }) => {
      if (_isSubscribed) {
        //setLoading(false);
        if (!error) {
          console.log('data', data);
          const byExtId = data.results.reduce((res, item) => {
            res[item.ext_id] = item;
            return res;
          }, {})
          
          setInnerItems(items.reduce((res, item) => {
            if (byExtId[item.ext_id]) {
              byExtId[item.ext_id].index = item.index;
              res.push(byExtId[item.ext_id])
            }
            return res;
          }, []));
        }
      }
    });

    return () => (_isSubscribed = false);
  // eslint-disable-next-line
  }, [ext_ids]);

  const columnList = ['name', 'actions'];

  const addItem = () => {
    actions.plugin.showDataItemForm({ 
      show: true, 
      preventLoadDetail: true,
      detail, 
      itemFieldType: 'category_list',
      itemFieldName: fieldName,
      itemFields: [
        {
          'name': 'ext_id',
          'label': 'Категория',
          'type': 'category',
          'default': null,
          'required': true,
        },
      ]
    });
  };

  const delItem = (item) => {
    console.log('delItem', item);
    const _tmpItems = innerItems.map(item => item.ext_id);
    _tmpItems.splice(item.index, 1);
    const data = Object.assign(detail.data, {
      [fieldName]: _tmpItems,
    });

    const postData = {
      id: detail.id,
      site: site.activeId,
      data:  data,
      preventLoadDetail: true,
    };

    console.log('postData', postData);
    actions.plugin.update(postData);
  };

  const columnByName = {
    name: tableColumn('Название', 'name', {
      render: (val, item) => <CategoryValue detail={item} asLink={true} isBack={true} />,
      sorter: true,
    }),

    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton 
          detail={item} 
          modelActions={actions.plugin} 
          actions={[{ name: 'edit' }, { name: 'delete' }]}
          actionByName={{
            edit: (modelActions, item) => ({
              title: 'Редактировать',
              action: () => actions.plugin.showDataItemForm({ 
                show: true, 
                detail, 
                item, 
                itemFieldType: 'category_list',
                itemFieldName: fieldName, 
                itemFields: [
                  {
                    'name': 'ext_id',
                    'label': 'Категория',
                    'type': 'category',
                    'default': null,
                    'required': true,
                  },
                ]
              }),
            }),
            delete: (modelActions, item) => ({
              title: 'Удалить',
              action: () => delItem(item),
            }),
          }}
        />
      ),
    }),
  };

  return (
    <TableList
      dataSource={innerItems}
      model={plugin}
      columnList={columnList}
      columnByName={columnByName}
      topActions={[
        <Button type="primary" icon={<PlusOutlined/>} onClick={addItem}>Добавить</Button>
      ]}
      showSearch={false}
      hideNav={true}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  authToken: state.auth.token,
  site: state.site,
  plugin: state.plugin,
});

const mapDispatch = (dispatch) => ({
  actions: {
    plugin: dispatch.plugin,
  },
});

export default connect(mapState, mapDispatch)(PluginDataCategoryList);
