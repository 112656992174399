import React, { Fragment, useState, useMemo } from 'react';
import { Button, Input, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { arrayIsEqual } from 'utils';
import styles from './arrayField.module.css';


const Field = ({ row, field, index, handleChange }) => {
  const InputComponent = field.component || Input;
  const componentStyle = field.componentStyle || {};
  const componentProps = field.componentProps || {};
  const defaultValue = row[field.name];
  let valueProps = {};
  if (defaultValue === true) {
    valueProps['checked'] = defaultValue;
  } else {
    valueProps['defaultValue'] = defaultValue;
  }
  return (
    <InputComponent
      defaultValue={defaultValue}
      placeholder={field.label}
      onChange={e => handleChange(index, field.name, e)}
      style={componentStyle}
      {...componentProps}
      {...valueProps}
    />
  )
};


const HorizontalArrayField = ({ fields, rows, handleChange, allowAdd, allowRemove, add, remove }) => {
  const header = (rows.length > 0) && (
    <Space style={{display: 'flex', marginBottom: '8px'}} align="baseline" size="middle">
      {fields.map(field => (
        <div key={`fieldhead${field.name}`} style={field.componentStyle || {}}>
          {field.label}
        </div>
      ))}
    </Space>
  );

  return (
    <Fragment>
      {header}

      {rows.map((row, index) => (
        <Space key={`row${index}`} style={{display: 'flex', marginBottom: '8px'}} align="baseline" size="middle">
          {fields.map(field => 
            <Field key={`field${field.name}`} row={row} field={field} index={index} handleChange={handleChange}/>)}

          {allowRemove && (
            <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(index)} />
          )}
        </Space>
      ))}

      {allowAdd && (
        <div>
          <Button type="dashed" onClick={add} style={{ width: '60%' }}>
            <PlusOutlined type="plus" /> Добавить
          </Button>
        </div>
      )}
    </Fragment>
  )
}


const VerticalArrayField = ({ fields, rows, handleChange }) => {
  const _rows = rows.reduce((result, item) => {
    result = Object.assign(result, item);
    return result;
  }, {});

  return (
    <Fragment>
      {fields.map((field, index) => (
        <Space key={`row${index}`} style={{display: 'flex', marginBottom: '8px'}} align="baseline" size="middle">
          <div style={{width: '200px'}}>
            {field.label}
          </div>
          {(field.name in _rows) && (
            <Field 
              key={`field${field.name}`} 
              row={{[field.name]:_rows[field.name]}} 
              field={field} 
              index={index} 
              handleChange={handleChange}
            />
          )}
        </Space>
      ))}
    </Fragment>
  )
}


const ArrayField = ({ className, mode, fields, items, allowAdd, allowRemove, onChange }) => {
  const [initialItems, setInitialItems] = useState([]);
  const [rows, setRows] = useState([]);
  
  const defaultRow = useMemo(() => {
    return fields.reduce((result, field) => {
      result[field.name] = field.defaultValue;
      return result;
    }, {});
  }, [fields]);

  if (!arrayIsEqual(initialItems, items || [])) {
    setRows(items || []);
    setInitialItems(items || []);
  }

  const remove = (index) => {
    const tmpRows = JSON.parse(JSON.stringify(rows));
    const newRows = tmpRows.filter((row, idx) => idx !== index);
    setRows(newRows);
    onChange && onChange(newRows);
  };

  const add = () => {
    let newRows = JSON.parse(JSON.stringify(rows));
    newRows.push(Object.assign({}, defaultRow));
    setRows(newRows);
    onChange && onChange(newRows);
  };

  const handleChange = (rowIndex, fieldName, e) => {
    let newRows = JSON.parse(JSON.stringify(rows));

    let value = e;
    if (value && value.target) {
      value = value.target.value || value.target.checked;
    }
    newRows[rowIndex][fieldName] = value;
    setRows(newRows);
    onChange && onChange(newRows);
  };

  const cx = classNames([styles.main, className]);

  return (
    <div className={cx}>
      {mode === "vertical" ? 
        <VerticalArrayField 
          fields={fields} rows={rows} handleChange={handleChange}
        /> : 
        <HorizontalArrayField 
          fields={fields} rows={rows} handleChange={handleChange}
          allowAdd={allowAdd} 
          allowRemove={allowRemove}
          add={add}
          remove={remove}
        />
      }
    </div>
  );
}

export default ArrayField;
