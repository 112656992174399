import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';

const GroupForm = (props) => {
  const { showEditForm } = props.group;

  const onClose = () => {
    props.actions.group.showEditForm({ show: false });
  };

  const onSubmit = (data) => {
    let categories = data.categories ? data.categories: null;
    if (categories && !Array.isArray(data.categories)) {
      categories = [categories];
    }

    const newData = Object.assign(data, {
      site: props.site.activeId,
      categories: categories,
    });
    console.log('newData', newData);
    props.actions.group.update(newData);
  };

  return (
    <Form
      width={800}
      name="groupForm"
      title="Редактирование группы товаров"
      model={props.group}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <MainFieldSet {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  auth_token: state.auth.token,
  site: state.site,
  group: state.group,
});

const mapDispatch = (dispatch) => ({
  actions: {
    group: dispatch.group,
  },
});

export default connect(mapState, mapDispatch)(GroupForm);
