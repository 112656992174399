import { push, replace } from "connected-react-router";
import * as base from "./index";


export async function asyncRequest(dispatch, payload, rootState, apiRequest, actions, callback = null) {
  const token = rootState.auth.token;

  actions.request(payload);

  if (payload && payload.locationChange) {
    const searchSimple = rootState.router.location.search.replace('?', '');
    const querySimple = payload.query.replace('?', '');
    if (searchSimple !== querySimple) {
      console.log('searchSimple', searchSimple);
      console.log(querySimple, querySimple);
      dispatch(replace({ search: payload.query === '?' ? '': payload.query }));
    }
  }

  try {
    const { error, data, statusCode } = await apiRequest(token, payload);
    let respData = data || {};

    if (error) {
      actions.error({ respData, statusCode });
    } else {
      respData.preventLoadDetail = payload ? payload.preventLoadDetail: false;
      actions.complete(respData);
      if (callback) {
        await callback(respData);
      }
    }
  } catch (error) {
    console.log('Request Error', error);
    actions.error()
  }
}


export async function asyncListLoadRequest(dispatch, payload, rootState, model, apiRequest, callback = null) {
  return base.asyncRequest(dispatch, payload, rootState, apiRequest, {
    request: dispatch[model].listLoadRequest,
    complete: dispatch[model].listLoadComplete,
    error: dispatch[model].listLoadError,
  }, callback);
}


export async function asyncDetailLoadRequest(dispatch, payload, rootState, model, apiRequest, callback = null) {
  return base.asyncRequest(dispatch, payload, rootState, apiRequest, {
    request: dispatch[model].detailLoadRequest,
    complete: dispatch[model].detailLoadComplete,
    error: dispatch[model].detailLoadError,
  }, callback);
}


export async function asyncUpdateRequest(dispatch, payload, rootState, model, apiRequest, callback = null) {
  return base.asyncRequest(dispatch, payload, rootState, apiRequest, {
    request: dispatch[model].updateRequest,
    complete: dispatch[model].updateComplete,
    error: dispatch[model].updateError,
  }, async (data) => {
    if (callback) {
      await callback(data);
    } else {
      dispatch[model].showEditForm({ show: false });
      if (!payload.preventLoadDetail) {
        dispatch(push(rootState[model].urls.detail(data.id)));
      } else {
        const filter = rootState[model].filter;
        await dispatch[model].listLoad(filter);
      }
    }
  });
}

export async function asyncDeleteRequest(dispatch, payload, rootState, model, apiRequest, callback = null) {
  return base.asyncRequest(dispatch, payload, rootState, apiRequest, {
    request: dispatch[model].deleteRequest,
    complete: dispatch[model].deleteComplete,
    error: dispatch[model].deleteError,
  }, async (data) => {
    if (callback) {
      await callback(data);
    } else {
      const path = rootState.router.location.pathname;
      const detailPath = rootState[model].urls.detail(payload.id);
      if (path === detailPath) {
        dispatch(push(rootState[model].urls.list()));
      }
      const filter = rootState[model].filter;
      await dispatch[model].listLoad(filter);
    }
  });
}
