import React from 'react';
import { Input, Row, Col } from 'antd';

import { FormItem } from 'utils';
import { ImageUpload, HtmlField } from 'components/common';


const MainFieldSet = (props) => {
  const { propertyConfig, priceType, ...otherProps } = props;
  const editObject = props.product.editObject || {};

  return (
    <Row gutter={32}>
      <Col span={16}>
        <div className="form-block">
          <FormItem {...otherProps} name="name" label="Название" required={true}>
            <Input size="large" />
          </FormItem>
          <FormItem {...otherProps} name="code" label="Код" required={false}>
            <Input size="large" />
          </FormItem>
          <FormItem {...otherProps} name="art_no" label="Артикул" required={false}>
            <Input size="large" />
          </FormItem>
          <FormItem {...otherProps} name="text" label="Описание" required={false}>
            <HtmlField
              auth_token={props.auth_token}
              siteDomain={props.site.activeDomain}
              modelName="catalog.product"
            />
          </FormItem>
        </div>
      </Col>
    
      <Col span={8}>
        <div className="form-block">
          <FormItem {...otherProps} name="images" required={false} valuePropName="fileList">
            <ImageUpload 
              title="Загрузить фото"
              auth_token={props.auth_token}
              siteDomain={props.site.activeDomain}
              modelName="catalog.product"
              data={{ext_id: editObject.ext_id}}
            />
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
