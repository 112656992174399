import React from 'react';
import { Input, InputNumber, Checkbox, Row, Col } from 'antd';

import { FormItem } from 'utils';
import { ImageUpload } from 'components/common';
import CategorySelect from '../../CategorySelect';


const MainFieldSet = (props) => {
  const editObject = props.category.editObject || {};

  return (
    <Row gutter={32}>
      <Col span={16}>
        <div className="form-block">
          <CategorySelect name="parent" label="Родительская категория" required={false} multiple={false}/>

          <FormItem name="name" label="Название" required={true}>
            <Input size="large" />
          </FormItem>

          <FormItem name="slug" label="Путь" required={true}>
            <Input size="large" />
          </FormItem>

          <FormItem name="sort" label="Сортировка" required={true}>
            <InputNumber size="large" />
          </FormItem>

          <FormItem name="is_active" label="Активно" required={false} valuePropName="checked">
            <Checkbox />
          </FormItem>
        </div>
      </Col>

      <Col span={8}>
        <div className="form-block">
          <FormItem name="images" required={false} valuePropName="fileList">
            <ImageUpload 
              title="Загрузить фото"
              auth_token={props.auth_token}
              siteDomain={props.site.activeDomain}
              modelName="catalog.category"
              data={{ext_id: editObject.ext_id}}
            />
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
