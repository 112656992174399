import React from 'react';
import { Input } from 'antd';

import { FormItem } from 'utils';

const MainFieldSet = (props) => {
  return (
    <div className="form-block">
      <FormItem {...props} name="name" label="Название" required={true}>
        <Input size="large" />
      </FormItem>
    </div>
  );
};

export default MainFieldSet;
