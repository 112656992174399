import './styles.css'


const TYPE_LIST = [
  ['page', 'страница'],
  ['contacts', 'контакты'],

  ['category_list', 'список категорий'],
  ['category_detail', 'категория'],

  ['product_list', 'список товаров (подборка)'],
  ['product_detail', 'товар'],

  ['brand_list', 'список брендов'],
  ['brand_detail', 'бренд'],
]

const EDITABLE_TYPE_LIST = [
  ['page', 'страница'],
  ['contacts', 'контакты'],
  ['category_list', 'список категорий'],
  ['product_list', 'список товаров (подборка)'],
  ['brand_list', 'список брендов'],
]

const TYPE = TYPE_LIST.reduce((res, item) => {
  res[item[0]] = item[1];
  return res;
}, {});

const getValue = (value) => {
  return TYPE[value] || value;
}


const PageType = ({ detail }) => {
  const page_type = detail?.page_type;

  return (
    <div className={`pageType ${page_type}`}>{getValue(page_type)}</div>
  );
};

PageType.isForObject = (page_type, object_id) => {
  if (object_id) {
    return ['category_detail', 'product_detail', 'brand_detail'].includes(page_type);
  }
  return false;
}
PageType.getValue = getValue;
PageType.EDITABLE_TYPE_LIST = EDITABLE_TYPE_LIST;
PageType.TYPE_LIST = TYPE_LIST;
PageType.TYPE = TYPE;


export default PageType
