import React from 'react';
import { Input } from 'antd';

import { FormItem } from 'utils';

const MainFieldSet = (props) => {
  return (
    <div className="form-block">
      <FormItem {...props} name="email" label="E-mail" required={true}>
        <Input size="large" />
      </FormItem>

      {!props.user.editObject && (
        <FormItem {...props} name="password" label="Пароль" required={true}>
          <Input.Password />
        </FormItem>
      )}
    </div>
  );
};

export default MainFieldSet;
