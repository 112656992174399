import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { tableColumn } from 'utils';
import { TableList, ActionButton, DateTimeText } from 'components/common';
import { PageType } from 'components/blocks/Page';
import SeoTemplatePageValue from '../SeoTemplatePageValue';


const SeoTemplatePageList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, seoTemplatePage }) => {
  const defaultColumnList = ['tpl_title', 'tpl_path', 'tpl_h1', 'page_type', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }

  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    tpl_title: tableColumn('Тайтл', 'tpl_title', {
      render: (val, item) => <SeoTemplatePageValue detail={item} asLink={true} isBack={true} />,
      sorter: true,
    }),
    tpl_path: tableColumn('Путь', 'tpl_path', {
      sorter: true,
    }),
    tpl_h1: tableColumn('H1', 'tpl_h1', {
      sorter: true,
    }),
    page_type: tableColumn('Тип', 'page_type', {
      render: (val, item) => <PageType detail={item} />,
      sorter: true,
    }),
    date_changed: tableColumn('Дата изменения', 'date_changed', {
      render: (val, item) => <DateTimeText date={val} />,
      width: 150,
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.seoTemplatePage} actions={[{ name: 'edit' }, { name: 'delete' }]} />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = seoTemplatePage.filter.activeFilter;
      }
      actions.seoTemplatePage.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [seoTemplatePage, actions, setSelected],
  );

  const batchDelete = (selected) => {
    actions.seoTemplatePage.batchDelete({ selected });
  };

  return (
    <TableList
      location={router.location}
      model={seoTemplatePage}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      selected={selected}
      rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}
      rowActions={[
        {
          title: 'Удалить',
          icon: 'delete',
          action: (selected) => batchDelete(selected),
        },
      ]}
      /*filterFields={{
        list: ['page_type'],
        byName: {
          page_type: {
            label: 'Тип',
            type: 'select',
            options: PageType.TYPE_LIST.map(item => ({ value: item[0], label: item[1] })),
            valueComponent: ({ value }) => PageType.getValue(value),
          },
        },
      }}*/
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  seoTemplatePage: state.seoTemplatePage,
});

const mapDispatch = (dispatch) => ({
  actions: {
    seoTemplatePage: dispatch.seoTemplatePage,
  },
});

export default connect(mapState, mapDispatch)(SeoTemplatePageList);
