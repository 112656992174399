import React from 'react';
import { Input, Row, Col, Select, Checkbox } from 'antd';

import { FormItem } from 'utils';


const MainFieldSet = (props) => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <div className="form-block">
          <FormItem {...props} name="event_type" label="Тип события" required={true}>
            <Select
              style={{ width: '100%' }}
              placeholder="Выберите"
            >
              <Select.Option value="order_created">Заказ создан</Select.Option>
            </Select>
          </FormItem>

          <FormItem {...props} name="from_email" label="E-mail отправителя" required={true}>
            <Input size="large" />
          </FormItem>

          <FormItem {...props} name="email_subject_tpl" label="Тема письма" required={true}>
            <Input size="large" />
          </FormItem>

          <FormItem {...props} name="email_body_tpl" label="Сообщение" required={false}>
            <Input.TextArea rows={10} />
          </FormItem>

          <FormItem name="for_manager" label="Для менеджеров" required={false} valuePropName="checked">
            <Checkbox />
          </FormItem>

          <FormItem name="is_active" label="Активно" required={false} valuePropName="checked">
            <Checkbox />
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
