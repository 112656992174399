import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { PluginList } from 'components/blocks/Plugin';


const PluginListScreen = ({ history, site, plugin, actions }) => {
  return (
    <Page
      title="Плагины"
      info={plugin.filter.query ? `Найдено: ${plugin.count}`: ""}
      modelActions={actions.brand}
      breadcrumbs={[
        { title: 'Сайт', path: '/site' },
        { title: 'Плагины', path: plugin.urls.list() },
      ]}
    >
      <div className="page-main">
        <PluginList />
      </div>
    </Page>
  );
};

const mapState = (state) => ({
  site: state.site,
  plugin: state.plugin,
});

const mapDispatch = (dispatch) => ({
  actions: {
    plugin: dispatch.plugin,
  },
});

export default connect(mapState, mapDispatch)(PluginListScreen);
