import React from 'react';
import { Checkbox } from 'antd';

import { FormItem } from 'utils';
import { CategorySelect } from 'components/blocks/Category';


const MainFieldSet = (props) => {
  return (
    <div className="form-block">
      <CategorySelect {...props} name="categories" label="Категории" required={false} />

      <FormItem {...props} name="is_replace" label="Заменить категорию" required={false} valuePropName="checked">
        <Checkbox />
      </FormItem>
    </div>
  );
};

export default MainFieldSet;
