import React  from 'react';
import classNames from "classnames";

import './styles.css';


const PROPERTY_TYPE = {
  'str': 'Строка',
  'int': 'Число',
  'list': 'Справочник',
  'file': 'Файл',
};

const PROPERTY_TYPE_LIST = ['str', 'int', 'list', 'file'];

const getPropertyTypeName = (value) => PROPERTY_TYPE[value] || value;

const PropertyType = ({ value, className }) => {
  const cx = classNames([
    "propertyType",
    `propertyType--${value}`,
    className,
  ]);

  return (
    <div className={cx}>{PROPERTY_TYPE[value] || value}</div>
  );
};

PropertyType.PROPERTY_TYPE_LIST = PROPERTY_TYPE_LIST;
PropertyType.getPropertyTypeName = getPropertyTypeName;

export default PropertyType;
