import React from 'react';
import { connect } from 'react-redux';

import { tableColumn } from 'utils';
import { TableList, ActionButton, PropValue } from 'components/common';
import { ProductValue } from 'components/blocks/Product';


const OrderLineList = ({ items, actions, order }) => {
  const columnList = ['product', 'quantity', 'line_price'];

  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    product: tableColumn('Товар', 'product', {
      render: (val, item) => (
        <div>
          {item.product ? <ProductValue detail={item.product} asLink={true} isBack={true} />: '-'}

          <PropValue label="Название" value={item.product_name}/>
          <PropValue label="Артикул" value={item.product_art_no}/>
          <PropValue label="Цена" value={item.product_price}/>
        </div>
      ),
      sorter: true,
    }),
    quantity: tableColumn('Кол-во', 'quantity', {
      sorter: true,
      width: 100,
    }),
    line_price: tableColumn('Стоимость', 'line_price', {
      sorter: true,
      width: 100,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.order} actions={[{ name: 'edit' }, { name: 'delete' }]} />
      ),
    }),
  };

  return (
    <TableList
      dataSource={items}
      model={order}
      columnList={columnList}
      columnByName={columnByName}
      /*topActions={[
        <Button type="primary" icon={<PlusOutlined/>} onClick={addItem}>Добавить</Button>
      ]}*/
      showSearch={false}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  order: state.order,
});

const mapDispatch = (dispatch) => ({
  actions: {
    order: dispatch.order,
  },
});

export default connect(mapState, mapDispatch)(OrderLineList);
