import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { PriceTypeList, PriceTypeForm } from 'components/blocks/PriceType';

const PriceTypeListScreen = ({ history, priceType, actions }) => {
  return (
    <Page
      title="Типы цен"
      info={priceType.filter.query ? `Найдено: ${priceType.count}`: ""}
      modelActions={actions.priceType}
      breadcrumbs={[{ title: 'Типы цен', path: priceType.urls.list() }]}
      actions={[
        {
          mode: 'button',
          name: 'add',
          type: 'primary',
          title: 'Добавить тип цены',
        },
      ]}
    >
      <div className="page-main">
        <PriceTypeList />
      </div>

      <PriceTypeForm />
    </Page>
  );
};

const mapState = (state) => ({
  priceType: state.priceType,
});

const mapDispatch = (dispatch) => ({
  actions: {
    priceType: dispatch.priceType,
  },
});

export default connect(mapState, mapDispatch)(PriceTypeListScreen);
