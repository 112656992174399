import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';

const UserForm = (props) => {
  const { showEditForm } = props.user;

  const onClose = () => {
    props.actions.user.showEditForm({ show: false });
  };

  const onSubmit = (data) => {
    props.actions.user.update(data);
  };

  return (
    <Form
      title="Редактирование пользователя"
      model={props.user}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <MainFieldSet {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  user: state.user,
});

const mapDispatch = (dispatch) => ({
  actions: {
    user: dispatch.user,
  },
});

export default connect(mapState, mapDispatch)(UserForm);
