import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Menu } from 'antd';
import { DashboardOutlined, ClusterOutlined, UserOutlined, SyncOutlined, DatabaseOutlined, MailOutlined } from '@ant-design/icons';

import './styles.css';

class Navigation extends Component {
  navigate = ({ item, key, keyPath }) => {
    if (key === 'dashboard') {
      this.props.actions.router.push(`/`);
    } else {
      this.props.actions.router.push(`/${key}`);
    }
  };

  getCurrentRootNav() {
    const { pathname } = this.props.router.location;
    return pathname.split('/')[1] || 'dashboard';
  }

  render() {
    const currentRootNav = this.getCurrentRootNav();

    return (
      <Menu
        className="navigation"
        //mode="inline"
        //inlineIndent={0}
        defaultOpenKeys={[currentRootNav]}
        defaultSelectedKeys={[currentRootNav]}
        selectedKeys={[currentRootNav]}
        onClick={this.navigate}
      >
        <Menu.Item key="dashboard">
          <DashboardOutlined />
          <span>Рабочий стол</span>
        </Menu.Item>

        <Menu.Item key="catalog">
          <DatabaseOutlined />
          <span>Каталог</span>
        </Menu.Item>

        <Menu.Item key="sync">
          <SyncOutlined />
          <span>Синхрониз.</span>
        </Menu.Item>

        <Menu.Item key="order">
          <DatabaseOutlined />
          <span>Заказы</span>
        </Menu.Item>

        <Menu.ItemGroup title="Настройки">
          <Menu.Item key="site">
            <ClusterOutlined />
            <span>Сайты</span>
          </Menu.Item>

          <Menu.Item key="notification">
            <MailOutlined />
            <span>Уведомления</span>
          </Menu.Item>

          <Menu.Item key="user">
            <UserOutlined />
            <span>Пользователи</span>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu >
    );
  }
}

const mapState = (state) => ({
  router: state.router,
});

const mapDispatch = (dispatch) => ({
  actions: {
    router: {
      push: (payload) => dispatch(push(payload)),
    },
  },
});

export default connect(mapState, mapDispatch)(Navigation);
