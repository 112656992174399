import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';


const GroupBrandForm = (props) => {
  const { showBrandForm } = props.group;

  const onClose = () => {
    props.actions.group.showBrandForm({ show: false });
  };

  const onSubmit = (data) => {
    const { selected } = props.group;
    const brand = data.brand || null;
    const newData = Object.assign({ selected }, data, { brand });
    props.actions.group.brandAdd(newData);
  };

  return (
    <Form
      title="Добавление товаров в бренд"
      model={props.group}
      visible={showBrandForm}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <MainFieldSet {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  group: state.group,
});

const mapDispatch = (dispatch) => ({
  actions: {
    group: dispatch.group,
  },
});

export default connect(mapState, mapDispatch)(GroupBrandForm);
