import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { OrderList, OrderForm } from 'components/blocks/Order';


const PluginListScreen = ({ history, site, plugin, actions }) => {
  return (
    <Page
      title="Заказы"
      info={plugin.filter.query ? `Найдено: ${plugin.count}`: ""}
      modelActions={actions.brand}
      breadcrumbs={[
        { title: 'Заказы', path: '/order' },
      ]}
    >
      <div className="page-main">
        <OrderList />
      </div>

      <OrderForm/>
    </Page>
  );
};

const mapState = (state) => ({
  site: state.site,
  plugin: state.plugin,
});

const mapDispatch = (dispatch) => ({
  actions: {
    plugin: dispatch.plugin,
  },
});

export default connect(mapState, mapDispatch)(PluginListScreen);
