import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { tableColumn } from 'utils';
import { TableList, ActionButton, DateTimeText } from 'components/common';
import NotificationValue from '../NotificationValue';


const NotificationList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, notification }) => {
  const defaultColumnList = ['name', 'event_type', 'status', 'date_created', 'date_sent', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }

  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Получатель', 'name', {
      render: (val, item) => <NotificationValue detail={item} asLink={true} isBack={true} />,
      sorter: true,
    }),
    event_type: tableColumn('Тип события', 'event_type', {
      sorter: true,
      width: 150,
    }),
    status: tableColumn('Статус', 'status', {
      sorter: true,
      width: 150,
    }),
    date_created: tableColumn('Дата создания', 'date_created', {
      render: (val, item) => <DateTimeText date={val} />,
      width: 150,
      sorter: true,
    }),
    date_sent: tableColumn('Дата отправки', 'date_sent', {
      render: (val, item) => <DateTimeText date={val} />,
      width: 150,
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.notification} actions={[{ name: 'edit' }/*, { name: 'delete' }*/]} />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = notification.filter.activeFilter;
      }
      actions.notification.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [notification, actions, setSelected],
  );

  return (
    <TableList
      location={router.location}
      model={notification}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      selected={selected}
      /*rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}*/
      /*filterFields={{
        list: ['is_active'],
        byName: {
          is_active: {
            label: 'Активно',
            type: 'bool',
          },
        },
      }}*/
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  notification: state.notification,
});

const mapDispatch = (dispatch) => ({
  actions: {
    notification: dispatch.notification,
  },
});

export default connect(mapState, mapDispatch)(NotificationList);
