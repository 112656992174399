import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { OrderForm } from 'components/blocks/Order';
import DetailScreen from './DetailScreen';


const OrderDetailScreen = ({ history, order, actions, match }) => {
  useEffect(() => {
    actions.order.detailLoad({ id: match.params.orderId });
  }, [match.params.orderId, actions.order]);

  const id = match.params.orderId;
  const detail = order.detail || {};

  return (
    <Page
      isLoading={order.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.order}
      breadcrumbs={[
        { title: 'Заказы', path: '/order' },
        { title: detail.number, path: order.urls.detail(id) },
      ]}
      actions={[
        { mode: 'button', name: 'edit' },
        /*{
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },*/
      ]}
      basePath={order.urls.detail(id)}
    >
      <Route exact path="/order/:orderId" component={DetailScreen} />

      <OrderForm/>
    </Page>
  );
};

const mapState = (state) => ({
  order: state.order,
});

const mapDispatch = (dispatch) => ({
  actions: {
    order: dispatch.order,
  },
});

export default connect(mapState, mapDispatch)(OrderDetailScreen);
