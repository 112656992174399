import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import DetailScreen from './DetailScreen';


const NotificationDetailScreen = ({ history, notification, actions, match }) => {
  useEffect(() => {
    actions.notification.detailLoad({ id: match.params.notificationId });
  }, [match.params.notificationId, actions.notification]);

  const id = match.params.notificationId;
  const detail = notification.detail || {};

  return (
    <Page
      isLoading={notification.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.to_email}
      modelActions={actions.notification}
      breadcrumbs={[
        { title: 'Шаблоны уведомлений', path: '/notification' },
        { title: 'Уведомления', path: notification.urls.list() },
        { title: detail.to_email, path: notification.urls.detail(id) },
      ]}
      basePath={notification.urls.detail(id)}
    >
      <Route exact path="/notification/list/:notificationId" component={DetailScreen} />
    </Page>
  );
};

const mapState = (state) => ({
  notification: state.notification,
});

const mapDispatch = (dispatch) => ({
  actions: {
    notification: dispatch.notification,
  },
});

export default connect(mapState, mapDispatch)(NotificationDetailScreen);
