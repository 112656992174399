import React, { useState, useEffect } from 'react';
import { Input, Row, Col } from 'antd';
import getSlug from 'speakingurl';

import { FormItem } from 'utils';
import { PageTypeSelect } from 'components/blocks/Page'
import SeoTemplatePartsDropdown, { renderTpl } from '../../SeoTemplatePartsDropdown';


const MainFieldSet = (props) => {
  // eslint-disable-next-line
  const { form, seoTemplatePage, initialValues, ...otherProps } = props;

  const editObject = seoTemplatePage?.editObject || { page_type: 'product_detail' };
  //const page_type = editObject?.page_type || 'product_detail';

  const [pageType, setPageType] = useState(editObject.page_type);
  const [extra, setExtra] = useState({});

  const onTypeChange = (value) => {
    setPageType(value);
  }

  const updateExtra = (fieldName, value) => {
    const newExtra = Object.assign({}, extra);
    newExtra[fieldName] = renderTpl(value, pageType);
    if (fieldName === 'tpl_path') {
      newExtra[fieldName] = getSlug(newExtra[fieldName]);
    }
    setExtra(newExtra);
  };

  const partAdd = (fieldName, part) => {
    const oldValue = form.getFieldValue(fieldName) || '';
    const newValue = oldValue + part.value;
    form.setFieldsValue({
      [fieldName]: newValue,
    });
    updateExtra(fieldName, newValue);
  };

  useEffect(() => {
    const getHelp = (fieldName, value) => {
      if (!value) return '';
      let help = renderTpl(value, pageType);
      if (fieldName === 'tpl_path') {
        help = getSlug(help);
      }
      return help;
    };

    const fieldValues = editObject?.id ? editObject: initialValues;

    const newExtra = {
      tpl_path: getHelp('tpl_path', fieldValues.tpl_path),
      tpl_title: getHelp('tpl_title', fieldValues.tpl_title),
      tpl_description: getHelp('tpl_description', fieldValues.tpl_description),
      tpl_h1: getHelp('tpl_h1', fieldValues.tpl_h1),
      tpl_h2: getHelp('tpl_h2', fieldValues.tpl_h2),
      //tpl_text: getHelp('tpl_text', fieldValues.tpl_text),
    }

    setExtra(newExtra);
    // eslint-disable-next-line
  }, [pageType, editObject?.id]);

  return (
    <Row gutter={32}>
      <Col span={24}>
        <div className="form-block">
          <FormItem {...otherProps} name="page_type" label="Тип страницы" required={true}>
            <PageTypeSelect isForObject={true} onChange={onTypeChange} />
          </FormItem>

          <FormItem {...otherProps} name="tpl_path" label="Шаблон пути" required={true} extra={extra.tpl_path}>
            <Input
              size="large"
              onChange={(e) => updateExtra('tpl_path', e.target.value)}
              addonAfter={
                <SeoTemplatePartsDropdown
                  type={pageType}
                  onChange={(part) => partAdd('tpl_path', part)}
                />
              }
            />
          </FormItem>

          <FormItem {...otherProps} name="tpl_title" label="Шаблон тайтла" required={true} extra={extra.tpl_title}>
            <Input
              size="large"
              onChange={(e) => updateExtra('tpl_title', e.target.value)}
              addonAfter={
                <SeoTemplatePartsDropdown
                  type={pageType}
                  onChange={(part) => partAdd('tpl_title', part)}
                />
              }
            />
          </FormItem>

          <FormItem
            {...otherProps}
            name="tpl_description"
            label={
              <span>
                <span>Шаблон дескрипшена</span>
                <span>&nbsp;&nbsp;</span>
                <SeoTemplatePartsDropdown
                  type={pageType}
                  onChange={(part) => partAdd('tpl_description', part)}
                />
              </span>
            }
            required={true}
            extra={extra.tpl_description}
          >
            <Input.TextArea
              rows={5}
              onChange={(e) => updateExtra('tpl_description', e.target.value)}
            />
          </FormItem>

          <FormItem {...otherProps} name="tpl_h1" label="Шаблон H1" required={true} extra={extra.tpl_h1}>
            <Input
              size="large"
              onChange={(e) => updateExtra('tpl_h1', e.target.value)}
              addonAfter={
                <SeoTemplatePartsDropdown
                  type={pageType}
                  onChange={(part) => partAdd('tpl_h1', part)}
                />
              }
            />
          </FormItem>

          <FormItem {...otherProps} name="tpl_h2" label="Шаблон H2" required={false} extra={extra.tpl_h2}>
            <Input
              size="large"
              onChange={(e) => updateExtra('tpl_h2', e.target.value)}
              addonAfter={
                <SeoTemplatePartsDropdown
                  type={pageType}
                  onChange={(part) => partAdd('tpl_h2', part)}
                />
              }
            />
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
