import React from 'react';
import { Row, Col } from 'antd';

import { FormItem } from 'utils';
import { HtmlField } from 'components/common';


const TextFieldSet = (props) => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <div className="form-block">
          <FormItem {...props} name="text" label="Текст" required={false}>
            <HtmlField
              auth_token={props.auth_token}
              siteDomain={props.site.activeDomain}
              modelName="page.page"
            />
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default TextFieldSet;
