import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';

const GroupCategoryForm = (props) => {
  const { showCategoryForm } = props.group;

  const onClose = () => {
    props.actions.group.showCategoryForm({ show: false });
  };

  const onSubmit = (data) => {
    const { selected } = props.group;
    const newData = Object.assign({ selected }, data);
    props.actions.group.categoriesAdd(newData);
  };

  return (
    <Form
      title="Добавление товаров в категории"
      model={props.group}
      visible={showCategoryForm}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <MainFieldSet {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  group: state.group,
});

const mapDispatch = (dispatch) => ({
  actions: {
    group: dispatch.group,
  },
});

export default connect(mapState, mapDispatch)(GroupCategoryForm);
