import React from 'react';

import { NotificationTemplateDetail } from 'components/blocks/NotificationTemplate';

const DetailScreen = (props) => {
  return (
    <div className="page-main">
      <NotificationTemplateDetail />
    </div>
  );
};

export default DetailScreen;
