import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { PageForm } from 'components/blocks/Page';
import DetailScreen from './DetailScreen';


const PageDetailScreen = ({ history, page, actions, match }) => {
  useEffect(() => {
    actions.page.detailLoad({ id: match.params.pageId });
  }, [match.params.pageId, actions.page]);

  const id = match.params.pageId;
  const detail = page.detail || {};

  return (
    <Page
      isLoading={page.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.h1}
      modelActions={actions.page}
      breadcrumbs={[
        { title: 'Сайт', path: '/site' },
        { title: 'Страницы', path: page.urls.list() },
        { title: detail.title, path: page.urls.detail(id) },
      ]}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={page.urls.detail(id)}
    >
      <Route exact path="/site/page/:pageId" component={DetailScreen} />

      <PageForm />
    </Page>
  );
};

const mapState = (state) => ({
  page: state.page,
});

const mapDispatch = (dispatch) => ({
  actions: {
    page: dispatch.page,
  },
});

export default connect(mapState, mapDispatch)(PageDetailScreen);
