import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';
import ScriptsFieldSet from './ScriptsFieldSet';
import SyncSettingsFieldSet from './SyncSettingsFieldSet';


const FormContent = (props) => {
  return (
    <Tabs className="tabsForm" type="card">
      <Tabs.TabPane tab="Основное" key="detail" forceRender={true}>
        <MainFieldSet {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Скрипты" key="scripts" forceRender={true}>
        <ScriptsFieldSet {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Настройки синхронизации" key="sync_settings" forceRender={true}>
        <SyncSettingsFieldSet {...props} />
      </Tabs.TabPane>
    </Tabs>
  )
}


const SiteForm = (props) => {
  const { showEditForm } = props.site;

  const onClose = () => {
    props.actions.site.showEditForm({ show: false });
  };

  const onSubmit = (data) => {
    console.log(data)
    const newData = Object.assign(data, {
      favicon: (data.favicon && data.favicon.length > 0) ? data.favicon[0]: {}
    });
    props.actions.site.update(newData);
  };

  return (
    <Form
      width={800}
      title="Редактирование сайта"
      model={props.site}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={{ sort: 100 }}
      convertEditObject={(editObject) => ({
        ...editObject,
        favicon: editObject.favicon && editObject.favicon.sha1 ? [editObject.favicon]: [],
      })}
    >
      <FormContent {...props}/>
    </Form>
  );
};

const mapState = (state) => ({
  site: state.site,
  auth_token: state.auth.token,
});

const mapDispatch = (dispatch) => ({
  actions: {
    site: dispatch.site,
  },
});

export default connect(mapState, mapDispatch)(SiteForm);
