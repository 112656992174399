import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';
import TextFieldSet from './TextFieldSet';
import PropertyFieldSet from './PropertyFieldSet';


const FormContent = (props) => {
  return (
    <Tabs className="tabsForm" type="card">
      <Tabs.TabPane tab="Основное" key="detail" forceRender={true}>
        <MainFieldSet {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Описание" key="text" forceRender={true}>
        <TextFieldSet {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Свойства" key="properties" forceRender={true}>
        <PropertyFieldSet {...props} />
      </Tabs.TabPane>
    </Tabs>
  )
}


const CategoryForm = (props) => {
  const { showEditForm } = props.category;

  const onClose = () => {
    props.actions.category.showEditForm({ show: false });
  };

  const onSubmit = (data) => {
    const newData = Object.assign(data, {
      site: props.site.activeId,
      parent: data.parent || null,
    });
    props.actions.category.update(newData);
  };

  return (
    <Form
      width={1000}
      title="Редактирование категории"
      model={props.category}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={{ sort: 100 }}
    >
      <FormContent {...props}/>
    </Form>
  );
};

const mapState = (state) => ({
  site: state.site,
  auth_token: state.auth.token,
  category: state.category,
});

const mapDispatch = (dispatch) => ({
  actions: {
    category: dispatch.category,
  },
});

export default connect(mapState, mapDispatch)(CategoryForm);
