import React, { useCallback, useState } from 'react';
import { Switch, Radio } from 'antd';
import { GroupOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import { tableColumn, getUrlSearch, getUrlParams } from 'utils';
import { TableList, ActionButton, DateTimeText, Image } from 'components/common';
import GroupValue from '../GroupValue';

const GroupList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, group }) => {
  const defaultColumnList = ['image', 'name', 'min_retail_price', 'quantity', 'date_created', 'date_changed', 'is_active', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Название', 'name', {
      render: (val, item) => (
        <div>
          <GroupValue detail={item} asLink={true} isBack={true} />
          <div>Артикул: {item.art_no}</div>
          <div>Кол-во видов товаров: {item.product_count}</div>
        </div>
      ),
      sorter: true,
    }),
    art_no: tableColumn('Артикул', 'art_no', {
      width: 150,
      sorter: true,
    }),
    image: tableColumn('Картинка', 'image', {
      render: (val, item) => {
        const img = item.images[0];
        return <Image src={img && img.url} width={120} height={100} />;
      },
      width: 120,
      sorter: true,
    }),
    min_retail_price: tableColumn('Цена', 'min_retail_price', {
      width: 80,
      sorter: true,
    }),
    quantity: tableColumn('Кол-во', 'quantity', {
      width: 80,
      sorter: true,
    }),
    date_created: tableColumn('Дата создания', 'date_created', {
      render: (val, item) => <DateTimeText date={val} />,
      sorter: true,
      width: 150,
    }),
    date_changed: tableColumn('Дата изменения', 'date_changed', {
      render: (val, item) => <DateTimeText date={val} />,
      sorter: true,
      width: 150,
    }),
    is_active: tableColumn('Активен', 'is_active', {
      sorter: true,
      width: 150,
      render: (val, item) => (
        <div>
          <Switch
            style={{ marginTop: '5px', minWidth: '62px' }}
            checkedChildren="вкл"
            unCheckedChildren="выкл"
            checked={item.is_active}
            loading={group.updateStatus.isLoading && itemIdForUpdate === item.id}
            onClick={() => onGroupOnOff(item)}
          />
        </div>
      ),
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.group} actions={[{ name: 'edit' }, { name: 'delete' }]} />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = group.filter.activeFilter;
      }
      actions.group.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [group, actions, setSelected],
  );

  const onGroupOnOff = (item) => {
    const data = {
      id: item.id,
      is_active: !item.is_active,
      preventLoadDetail: true,
    };
    setItemIdForUpdate(item.id);
    actions.group.update(data);
  };

  const showCategoryForm = (selected) => {
    actions.group.showCategoryForm({ show: true, selected });
  };

  const showGroupBrandForm = (selected) => {
    actions.group.showBrandForm({ show: true, selected, object: { selected } });
  };

  const groupCombine = (selected) => {
    actions.group.showCombineForm({ show: true, selected, object: { selected } });
  };

  const batchDelete = (selected) => {
    actions.group.batchDelete({ selected });
  };

  const batchPublish = (selected) => {
    const items = selected.map(id => ({ id: id, fields: { is_active: true } }))
    actions.group.batchUpdate({ selected: items });
  };

  const batchUnpublish = (selected) => {
    const items = selected.map(id => ({ id: id, fields: { is_active: false } }))
    actions.group.batchUpdate({ selected: items });
  };


  const [viewMode, setViewMode] = useState('current');


  const onViewChange = (e) => {
    setViewMode(e.target.value);

    let params = getUrlParams(router.location.search);
    params.view_mode = e.target.value

    const query = `?${getUrlSearch(params)}`;
    onLoad({ query, activeFilter })
  }

  return (
    <TableList
      location={router.location}
      model={group}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={[
        <Radio.Group buttonStyle="solid" value={viewMode} onChange={onViewChange}>
          <Radio.Button value="current">Из текущей категории</Radio.Button>
          <Radio.Button value="all">Со всех подкатегорий</Radio.Button>
        </Radio.Group>
      ]}
      showSearch={true}
      selected={selected}
      //childrenColumnName="offers"
      rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}
      rowActions={[
        {
          title: 'Добавить в категории',
          icon: 'plus',
          type: 'primary',
          action: (selected) => showCategoryForm(selected),
        },
        {
          title: 'Изменить бренд',
          //icon: '',
          type: 'primary',
          action: (selected) => showGroupBrandForm(selected),
        },
        {
          title: 'Объединить товары',
          type: 'primary',
          icon: <GroupOutlined />,
          action: (selected) => groupCombine(selected),
        },
        {
          title: 'Сделать активными',
          type: 'primary',
          action: (selected) => batchPublish(selected),
        },
        {
          title: 'Сделать неактивными',
          type: 'primary',
          action: (selected) => batchUnpublish(selected),
        },
        {
          title: 'Удалить',
          icon: 'delete',
          action: (selected) => batchDelete(selected),
        },
      ]}
      filterFields={{
        list: ['is_active', 'can_buy', 'is_one_product', 'is_empty_images', 'is_empty_brand', 'is_empty_properties'],
        byName: {
          is_active: {
            label: 'Активно',
            type: 'bool',
          },
          can_buy: {
            label: 'В продаже',
            type: 'bool',
          },
          is_one_product: {
            label: 'Один товар',
            type: 'bool',
          },
          is_empty_images: {
            label: 'Нет картинок',
            type: 'bool',
          },
          is_empty_brand: {
            label: 'Нет бренда',
            type: 'bool',
          },
          is_empty_properties: {
            label: 'Пустые свойства',
            type: 'bool',
          },
        },
      }}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  group: state.group,
});

const mapDispatch = (dispatch) => ({
  actions: {
    group: dispatch.group,
  },
});

export default connect(mapState, mapDispatch)(GroupList);
