import React from 'react';
import { InputNumber } from 'antd';

import { FormItem } from 'utils';
import { ArrayField } from 'components/common';


const WarehousesFieldSet = (props) => {
  const { propertyConfig, priceType, warehouse, ...otherProps } = props;

  const fields = warehouse.list.reduce((result, ext_id) => {
    const item = warehouse.byId[ext_id];
    if (item.is_active) {
      result.push({ 
        name: item.ext_id,
        label: item.name,
        defaultValue: "",
        component: InputNumber, 
        componentStyle: {minWidth: '200px'},
      });
    }
    return result;
  }, []);

  return (
    <div className="form-block">
      <FormItem {...otherProps} name="warehouses" label="" required={false} valuePropName="items">
        <ArrayField fields={fields} mode="vertical"/>
      </FormItem>
    </div>
  );
};

export default WarehousesFieldSet;
