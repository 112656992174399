import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';

import { Breadcrumbs } from 'components/common';
import './styles.css';

const PageHeaderNav = ({ backUrl, backSearch, breadcrumbs }) => {
  if (backUrl) {
    return (
      <div className="page-header-nav">
        <Link
          className="back-btn"
          to={{ pathname: backUrl, search: backSearch, state: { slideOut: true } }}
          replace={true}
        >
          <ArrowLeftOutlined />
          <span className="text">назад</span>
        </Link>
      </div>
    );
  }

  if (breadcrumbs && breadcrumbs.length) {
    return (
      <div className="page-header-nav">
        <Breadcrumbs items={[{path: '/', icon: <HomeOutlined />}, ...breadcrumbs]}/>
      </div>
    );
  }

  return null;
};

export default PageHeaderNav;
