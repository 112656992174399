import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';


const PluginDataItemForm = (props) => {
  const { showDataItemForm, editDetail, editObject, editItemFieldName, editItemFieldType } = props.plugin;

  const onClose = () => {
    props.actions.plugin.showDataItemForm({ show: false });
  };

  const onSubmit = (data) => {
    const detailData = editDetail.data || {};
    const itemIndex = editObject ? editObject.index: -1;
    let items = detailData[editItemFieldName] ? JSON.parse(JSON.stringify(detailData[editItemFieldName])): [];
    switch (editItemFieldType) {
      case 'product_list':
        data = data.ext_id;
        break;
      case 'brand_list':
        data = data.ext_id;
        break;
      case 'category_list':
        data = data.ext_id;
        break;
      default:
        items = items.map(item => {
          if ('id' in item) {
            delete item['id']
          }
          if ('index' in item) {
            delete item['index']
          }
          return item;
        });
    }

    if (data.image && Array.isArray(data.image)) {
      data.image = data.image[0];
    }
    
    if (items[itemIndex]) {
      items[itemIndex] = data;
    } else {
      items.push(data);
    }
    // sorting by sort field
    items.sort(function(a, b) {
      return a.sort - b.sort;
    });
    console.log(editItemFieldType, itemIndex, items);

    const newData = Object.assign(detailData, {
      [editItemFieldName]: items,
    });
    console.log('newData', newData);
    const postData = {
      id: editDetail.id,
      site: props.site.activeId,
      data: newData,
    };
    console.log('postData', postData);
    props.actions.plugin.update(postData);
  };

  return (
    <Form
      width={800}
      title="Редактирование элемента"
      model={props.plugin}
      visible={showDataItemForm}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={{ sort: 100 }}
      convertEditObject={(editObject) => ({
        ...editObject,
      })}
    >
      <MainFieldSet {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  site: state.site,
  auth_token: state.auth.token,
  plugin: state.plugin,
});

const mapDispatch = (dispatch) => ({
  actions: {
    plugin: dispatch.plugin,
  },
});

export default connect(mapState, mapDispatch)(PluginDataItemForm);
