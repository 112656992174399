import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { NavMainForm } from 'components/blocks/NavMain';
import DetailScreen from './DetailScreen';


const NavMainDetailScreen = ({ history, navMain, actions, match }) => {
  useEffect(() => {
    actions.navMain.detailLoad({ id: match.params.navMainId });
  }, [match.params.navMainId, actions.navMain]);

  const id = match.params.navMainId;
  const detail = navMain.detail || {};

  return (
    <Page
      isLoading={navMain.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.navMain}
      breadcrumbs={[
        { title: 'Сайт', path: '/site' },
        { title: 'Навигация', path: navMain.urls.list() },
        { title: detail.title, path: navMain.urls.detail(id) },
      ]}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={navMain.urls.detail(id)}
    >
      <Route exact path="/site/nav/:navMainId" component={DetailScreen} />

      <NavMainForm />
    </Page>
  );
};

const mapState = (state) => ({
  navMain: state.navMain,
});

const mapDispatch = (dispatch) => ({
  actions: {
    navMain: dispatch.navMain,
  },
});

export default connect(mapState, mapDispatch)(NavMainDetailScreen);
