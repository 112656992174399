import PluginDetailSlider from '../PluginDetailSlider';
import PluginDetailProductDay from '../PluginDetailProductDay';
import PluginDetailProductNew from '../PluginDetailProductNew';
import PluginDetailProductPopular from '../PluginDetailProductPopular';
import PluginDetailBrandPopular from '../PluginDetailBrandPopular';
import PluginDetailCategoryPopular from '../PluginDetailCategoryPopular';


const PluginType = {
  'slider': {
    detailComponent: PluginDetailSlider,
    settingsFields: [
      {
        'name': 'timeout',
        'label': 'Таймаут',
        'type': 'int',
        'default': 5000,
        'required': true,
      },
    ],
    dataFields: [
      {
        'name': 'slides',
        'label': 'Слайды',
        'type': 'list',
        'default': [],
        'required': true,
        'list_item_fields': [
          {
            'name': 'image',
            'label': 'Картинка',
            'type': 'image',
            'default': null,
            'required': true,
          }, 
          {
            'name': 'url',
            'label': 'Ссылка',
            'type': 'str',
            'default': null,
            'required': false,
          }, 
          {
            'name': 'sort',
            'label': 'Сортировка',
            'type': 'int',
            'default': 100,
            'required': true,
          }, 
          {
            'name': 'is_active',
            'label': 'Активно',
            'type': 'bool',
            'default': true,
            'required': false,
          }
        ],
      }
    ],
  },

  'product_day': {
    detailComponent: PluginDetailProductDay,
    settingsFields: [
      {
        'name': 'timeout',
        'label': 'Таймаут',
        'type': 'int',
        'default': 5000,
        'required': true,
      },
    ],
    dataFields: [
      {
        'name': 'products',
        'label': 'Товары',
        'type': 'product_list',
        'default': [],
        'required': true,
      }
    ],
  },

  'product_new': {
    detailComponent: PluginDetailProductNew,
    settingsFields: [],
    dataFields: [
      {
        'name': 'products',
        'label': 'Товары',
        'type': 'product_list',
        'default': [],
        'required': true,
      }
    ],
  },

  'product_popular': {
    detailComponent: PluginDetailProductPopular,
    settingsFields: [],
    dataFields: [
      {
        'name': 'products',
        'label': 'Товары',
        'type': 'product_list',
        'default': [],
        'required': true,
      }
    ],
  },

  'brand_popular': {
    detailComponent: PluginDetailBrandPopular,
    settingsFields: [],
    dataFields: [
      {
        'name': 'brands',
        'label': 'Бренды',
        'type': 'brand_list',
        'default': [],
        'required': true,
      }
    ],
  },

  'category_popular': {
    detailComponent: PluginDetailCategoryPopular,
    settingsFields: [],
    dataFields: [
      {
        'name': 'categories',
        'label': 'Категории',
        'type': 'category_list',
        'default': [],
        'required': true,
      }
    ],
  },
};


export default PluginType;
