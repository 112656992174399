import React from 'react';
import { Checkbox } from 'antd';

import { FormItem } from 'utils';
import { ArrayField } from 'components/common';
import { PropertySelect } from 'components/blocks/Property';


const PropertyFieldSet = (props) => {
  return (
    <div className="form-block">
      <FormItem name="property_config" label="" required={false} valuePropName="items">
        <ArrayField
          fields={[
            { 
              name: 'property_ext_id', 
              label: 'Свойство', 
              defaultValue: "",
              component: PropertySelect, 
              componentStyle: {minWidth: '200px'},
              componentProps: {
                
              } 
            },
            { 
              name: 'is_common', 
              label: 'Общее', 
              defaultValue: false,
              component: Checkbox,
              componentStyle: {minWidth: '50px'},
              componentProps: {
                
              } 
            },
          ]}
          allowAdd={true}
          allowRemove={true}
        />
      </FormItem>
    </div>
  );
};

export default PropertyFieldSet;
