import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { BrandList, BrandForm } from 'components/blocks/Brand';

const BrandListScreen = ({ history, brand, actions }) => {
  return (
    <Page
      title="Бренды"
      info={brand.filter.query ? `Найдено: ${brand.count}`: ""}
      modelActions={actions.brand}
      breadcrumbs={[{ title: 'Бренды', path: brand.urls.list() }]}
      actions={[
        {
          mode: 'button',
          name: 'add',
          type: 'primary',
          title: 'Добавить бренд',
        },
      ]}
    >
      <div className="page-main">
        <BrandList />
      </div>

      <BrandForm />
    </Page>
  );
};

const mapState = (state) => ({
  brand: state.brand,
});

const mapDispatch = (dispatch) => ({
  actions: {
    brand: dispatch.brand,
  },
});

export default connect(mapState, mapDispatch)(BrandListScreen);
