import React from 'react';
import { connect } from 'react-redux';

import { PageDetail } from 'components/blocks/Page';


const CategoryPageScreen = (props) => {
  const categoryDetail = props.category?.detail;

  return (
    <div className="page-main">
      <PageDetail pageDetail={categoryDetail?.page || {}} />
    </div>
  );
};

const mapState = (state) => ({
  category: state.category,
  page: state.page,
});

const mapDispatch = (dispatch) => ({
  actions: {
    page: dispatch.page,
  },
});

export default connect(mapState, mapDispatch)(CategoryPageScreen);
