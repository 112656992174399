import React from 'react';
import { Input, Row, Col, Checkbox } from 'antd';

import { FormItem } from 'utils';
import { NotificationTemplateSelect } from 'components/blocks/NotificationTemplate'


const MainFieldSet = (props) => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <div className="form-block">
          <NotificationTemplateSelect {...props} name="templateId" label="Шаблон" required={false} multiple={false} disabled={!!props.templateId} />

          <FormItem {...props} name="email" label="E-mail получателя" required={true}>
            <Input size="large" />
          </FormItem>

          <FormItem name="is_active" label="Активно" required={false} valuePropName="checked">
            <Checkbox />
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
