import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

import { endpoints } from 'store/models/file';
import UploadAdapter from './uploadAdapter';


const HtmlField = ({ className, value, onChange, auth_token, siteDomain, modelName, ...otherProps }) => {
  const handleChange = (event, editor) => {
    const data = editor.getData();
    onChange && onChange(data);
  };

  return (
    <CKEditor
      editor={ DecoupledEditor }
      //plugins={[ImageResizeEditing, ImageResizeHandles]}
      data={value}
      onChange={handleChange}
      onReady={ editor => {
        if (editor) {
          editor.ui.getEditableElement().parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
          );

          editor.plugins.get('FileRepository').createUploadAdapter = function( loader ) {
            return new UploadAdapter(loader, auth_token, siteDomain, modelName);
          };
        }
      }}
      config={{
        //plugins: [ Paragraph, Bold, Italic, Essentials ],
        toolbar: {
          items: [ 
            'heading', 'bold', 'italic', '|', 
            'bulletedList', 'numberedList', 'blockQuote', '|',
            'insertTable', '|', 'link', '|', 'imageUpload', '|',
            'undo', 'redo'
          ],
          shouldNotGroupWhenFull: true,
        },
        ckfinder: {
          // Upload the images to the server using the CKFinder QuickUpload command.
          uploadUrl: endpoints.uploadImage(siteDomain, 'html'),
        }
      }}
      {...otherProps}
    />
  );
};

export default HtmlField;
