import { push } from 'connected-react-router';

import { CATALOG_API_URL } from 'config';
import { api, getHeaders } from 'utils';
import * as base from '../base';

const endpoints = {
  list: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/group/`,
  detail: (siteDomain) => (id) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/group/${id}/`,

  brandAdd: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/group/brand/add/`,
  categoriesAdd: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/group/categories/add/`,
  batchUpdate: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/group/update/`,
  batchDelete: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/group/delete/`,
  batchCombine: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/group/combine/`,
  batchUncombine: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/group/uncombine/`,
};

export const groupListLoad = (siteDomain) => (token, payload) => api.ListLoad(endpoints.list(siteDomain), token, payload);
export const groupDetailLoad = (siteDomain) => (token, { id }) => api.DetailLoad(endpoints.detail(siteDomain), token, { id });
export const groupUpdate = (siteDomain) => (token, data) => api.Update(endpoints.list(siteDomain), endpoints.detail(siteDomain), token, data);
export const groupDelete = (siteDomain) => (token, data) => api.Delete(endpoints.detail(siteDomain), token, data);
export const groupBatchUpdate = (siteDomain) => (token, data) =>
  api.Load('post', endpoints.batchUpdate(siteDomain), data, {
    headers: getHeaders(token),
  });

export const groupBrandAdd = (siteDomain) => (token, data) =>
  api.Load('post', endpoints.brandAdd(siteDomain), data, {
    headers: getHeaders(token),
  });
export const groupCategoriesAdd = (siteDomain) => (token, data) =>
  api.Load('post', endpoints.categoriesAdd(siteDomain), data, {
    headers: getHeaders(token),
  });
export const groupBatchDelete = (siteDomain) => (token, data) =>
  api.Load('post', endpoints.batchDelete(siteDomain), data, {
    headers: getHeaders(token),
  });
export const groupBatchCombine = (siteDomain) => (token, data) =>
  api.Load('post', endpoints.batchCombine(siteDomain), data, {
    headers: getHeaders(token),
  });
export const groupBatchUncombine = (siteDomain) => (token, data) =>
  api.Load('post', endpoints.batchUncombine(siteDomain), data, {
    headers: getHeaders(token),
  });

export const group = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    showCategoryForm: false,
    showBrandForm: false,
    showCombineForm: false,
    selected: null,

    title: 'Товары',
    urls: {
      list: () => `/catalog/group`,
      detail: (id) => `/catalog/group/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,

    showCategoryForm(state, payload) {
      return {
        ...state,
        showCategoryForm: payload.show,
        editObject: payload.object,
        editPreventLoadDetail: payload.preventLoadDetail,
        selected: payload.selected,
      };
    },
    showBrandForm(state, payload) {
      return {
        ...state,
        showBrandForm: payload.show,
        editObject: payload.object,
        editPreventLoadDetail: payload.preventLoadDetail,
        selected: payload.selected,
      };
    },
    showCombineForm(state, payload) {
      return {
        ...state,
        showCombineForm: payload.show,
        editObject: payload.object,
        editPreventLoadDetail: payload.preventLoadDetail,
        selected: payload.selected,
      };
    },
  },
  effects: (dispatch) => ({
    async listLoad(payload, rootState) {
      const apiRequest = groupListLoad(rootState.site.activeDomain);
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'group', apiRequest);
    },

    async detailLoad(payload, rootState) {
      const apiRequest = groupDetailLoad(rootState.site.activeDomain);
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'group', apiRequest);
    },

    async update(payload, rootState) {
      const apiRequest = groupUpdate(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'group', apiRequest, async (data) => {
        dispatch['group'].showEditForm({ show: false });
        if (!payload.preventLoadDetail) {
          const pathname = rootState.router.location.pathname || '';
          if (pathname.startsWith('/catalog/group/') && payload.id) {
            await dispatch['group'].detailLoad({ id: payload.id });
          } else {
            const state = {
              backUrl: pathname,
              backSearch: rootState.router.location.search,
            };
            dispatch(push(rootState['group'].urls.detail(data.id), state));
          }

          const filter = rootState['product'].filter;
          await dispatch['product'].listLoad(filter);
        } else {
          const filter = rootState['group'].filter;
          await dispatch['group'].listLoad(filter);
        }
      });
    },

    async delete(payload, rootState) {
      const apiRequest = groupDelete(rootState.site.activeDomain);
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'group', apiRequest);
    },

    async brandAdd(payload, rootState) {
      const apiRequest = groupBrandAdd(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'group', apiRequest, async (data) => {
        dispatch['group'].showBrandForm({ show: false });
        const filter = rootState['group'].filter;
        await dispatch['group'].listLoad(filter);
      });
    },

    async categoriesAdd(payload, rootState) {
      const apiRequest = groupCategoriesAdd(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'group', apiRequest, async (data) => {
        dispatch['group'].showCategoryForm({ show: false });
        const filter = rootState['group'].filter;
        await dispatch['group'].listLoad(filter);
      });
    },

    async batchUpdate(payload, rootState) {
      const apiRequest = groupBatchUpdate(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'group', apiRequest, async (data) => {
        const filter = rootState['group'].filter;
        await dispatch['group'].listLoad(filter);
      });
    },

    async batchDelete(payload, rootState) {
      const apiRequest = groupBatchDelete(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'group', apiRequest, async (data) => {
        const filter = rootState['group'].filter;
        await dispatch['group'].listLoad(filter);
      });
    },

    async batchCombine(payload, rootState) {
      const apiRequest = groupBatchCombine(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'group', apiRequest, async (data) => {
        dispatch['group'].showCombineForm({ show: false });
        if (data.id) {
          const state = {
            backUrl: rootState.router.location.pathname,
            backSearch: rootState.router.location.search,
          };
          dispatch(push(rootState['group'].urls.detail(data.id), state));
        } else {
          const filter = rootState['group'].filter;
          await dispatch['group'].listLoad(filter);
        }
      });
    },

    async batchUncombine(payload, rootState) {
      const apiRequest = groupBatchUncombine(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'group', apiRequest, async (data) => {
        if (data.id) {
          //dispatch(push(rootState['group'].urls.detail(data.id)));
          await dispatch['group'].detailLoad({ id: data.id });
          const productFilter = rootState['product'].filter;
          await dispatch['product'].listLoad(productFilter);
        } else {
          const filter = rootState['group'].filter;
          await dispatch['group'].listLoad(filter);
        }
      });
    },
  }),
};
