import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

import './styles.css';

function getActiveTab(tabs, path) {
  if (path === '') {
    return 'detail';
  }
  let i = 0,
    n = tabs.length;
  while (i < n) {
    let match = tabs[i].match || [];
    match.push(tabs[i].key);
    let j = 0,
      k = match.length;
    while (j < k) {
      if (path.startsWith(match[j])) {
        return tabs[i].key;
      }
      j++;
    }
    i++;
  }
  return '';
}

const TopTabs = ({ basePath, tabs, actions, router, onTabChange }) => {
  const onChange = (key) => {
    if (onTabChange) {
      onTabChange(key);
    } else {
      const url = key === 'detail' ? basePath : `${basePath}/${key}`;
      actions.router.push(url);
    }
  };

  const path = router.location.pathname.replace(basePath, '').substr(1);
  const activeTab = getActiveTab(tabs, path);

  return (
    <Tabs className="top-tabs" activeKey={activeTab} onChange={onChange} type="card">
      {tabs.map((item) => (
        <Tabs.TabPane
          tab={
            <div>
              {item.title} {item.count !== undefined && <span>{item.count}</span>}
            </div>
          }
          key={item.key}
        />
      ))}
    </Tabs>
  );
};

const mapState = (state) => ({
  router: state.router,
});

const mapDispatch = (dispatch) => ({
  actions: {
    router: {
      push: (payload) => dispatch(push(payload)),
    },
  },
});

export default connect(mapState, mapDispatch)(TopTabs);
