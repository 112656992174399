import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { NotificationList } from 'components/blocks/Notification';


const NotificationListScreen = ({ history, site, notification, actions }) => {
  return (
    <Page
      title="Уведомления"
      info={notification.filter.query ? `Найдено: ${notification.count}`: ""}
      modelActions={actions.brand}
      breadcrumbs={[
        { title: 'Шаблоны уведомлений', path: '/notification' },
        { title: 'Уведомления', path: notification.urls.list() },
      ]}
    >
      <div className="page-main">
        <NotificationList />
      </div>
    </Page>
  );
};

const mapState = (state) => ({
  site: state.site,
  notification: state.notification,
});

const mapDispatch = (dispatch) => ({
  actions: {
    notification: dispatch.notification,
  },
});

export default connect(mapState, mapDispatch)(NotificationListScreen);
