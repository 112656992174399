import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import PageHeaderActions from './PageHeaderActions';
import PageHeaderNav from './PageHeaderNav';
import PageHeaderTabs from './PageHeaderTabs';
import './styles.css';

function PageHeader({
  router,
  mode,
  title,
  info,
  basePath,
  tabs,
  onTabChange,
  breadcrumbs,
  modelActions,
  actions,
  actionByName,
  detail,
}) {
  const cx = classNames(['page-header', mode && `page-header--${mode}`]);
  const state = router.location.state;
  const backUrl = state && state.backUrl;
  const backSearch = state && state.backSearch;

  return (
    <header className={cx}>
      <PageHeaderNav backUrl={backUrl} backSearch={backSearch} breadcrumbs={breadcrumbs} />

      <div className="page-header-main">
        <div className="page-header-main-start">
          <div className="page-header-title">{title}</div>
          {info && <div className="page-header-info">{info}</div>}
        </div>

        <PageHeaderActions modelActions={modelActions} actions={actions} actionByName={actionByName} detail={detail} />
      </div>

      {tabs && <PageHeaderTabs basePath={basePath} tabs={tabs} onTabChange={onTabChange} />}
    </header>
  );
}

const mapState = (state) => ({
  router: state.router,
});

export default connect(mapState)(PageHeader);
