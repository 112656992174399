import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { tableColumn } from 'utils';
import { TableList, ActionButton, DateTimeText } from 'components/common';
import { CategoryValue } from 'components/blocks/Category';


const RecommendedCategoryList = ({ router, columnList, defaultFilter, activeFilter, actions, recommendedCategory, groupId }) => {
  const defaultColumnList = ['recommended_category', 'sort', 'date_created', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    recommended_category: tableColumn('Рекомендуемая категория', 'recommended_category', {
      render: (val, item) => (<CategoryValue detail={val} asLink={true} isBack={true} />),
      sorter: true,
    }),
    sort: tableColumn('Сортировка', 'sort', {
      sorter: true,
    }),
    date_created: tableColumn('Дата создания', 'date_created', {
      render: (val, item) => <DateTimeText date={val} />,
      width: 150,
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.recommendedCategory} actions={[{ name: 'edit' }, { name: 'delete' }]} />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = recommendedCategory.filter.activeFilter;
      }
      actions.recommendedCategory.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [recommendedCategory, actions, setSelected],
  );

  const add = () => {
    actions.recommendedCategory.showEditForm({
      show: true, 
      preventLoadDetail: true
    });
  };

  return (
    <TableList
      location={router.location}
      model={recommendedCategory}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={[
        <Button type="primary" icon={<PlusOutlined/>} onClick={add}>
          Добавить
        </Button>
      ]}
      showSearch={false}
      selected={selected}
      rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  recommendedCategory: state.recommendedCategory,
});

const mapDispatch = (dispatch) => ({
  actions: {
    recommendedCategory: dispatch.recommendedCategory,
  },
});

export default connect(mapState, mapDispatch)(RecommendedCategoryList);
