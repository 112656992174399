import React from 'react';
import classNames from 'classnames';

import './styles.css';

export default function Logo(props) {
  const { className } = props;

  const cx = classNames(['logo', className]);

  return (
    <div className={cx}>
      <div className="logo-inner" />
    </div>
  );
}
