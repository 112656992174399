import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';
import TextFieldSet from './TextFieldSet';
import SeoFieldSet from './SeoFieldSet';


const FormContent = (props) => {
  return (
    <Tabs className="tabsForm" type="card">
      <Tabs.TabPane tab="Основное" key="detail" forceRender={true}>
        <MainFieldSet {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Текст" key="text" forceRender={true}>
        <TextFieldSet {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="SEO" key="properties" forceRender={true}>
        <SeoFieldSet {...props} />
      </Tabs.TabPane>
    </Tabs>
  )
}


const PageForm = (props) => {
  const { showEditForm } = props.page;

  const onClose = () => {
    props.actions.page.showEditForm({ show: false });
  };

  const onSubmit = (data) => {
    const newData = Object.assign(data, {
      site: props.site.activeId,
    });
    console.log(newData);
    props.actions.page.update(newData);
  };

  return (
    <Form
      width={800}
      title="Редактирование страницы"
      model={props.page}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={{ sort: 100 }}
      convertEditObject={(editObject) => ({
        ...editObject,
      })}
    >
      <FormContent {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  site: state.site,
  auth_token: state.auth.token,
  page: state.page,
});

const mapDispatch = (dispatch) => ({
  actions: {
    page: dispatch.page,
  },
});

export default connect(mapState, mapDispatch)(PageForm);
