import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { isDict } from 'utils/helpers'
import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText, PropValue } from 'components/common';


const NotificationDetail = ({ notification, actions }) => {
  const detail = notification.detail || {};
  const context = detail?.context || {};
  console.log(context);

  const renderContextValue = (name, value) => {
    console.log(name, value);
    let new_value = value;
    if (isDict(value)) {
      new_value = Object.keys(value).map(name2 => renderContextValue(name2, value[name2]));
    } else if (Array.isArray(value)) {
      if (value.length > 0) {
        new_value = value.map((item, idx) => renderContextValue(idx, item))
      } else {
        new_value = '';
      }
    }
    return <PropValue key={`p${name}`} label={name} value={new_value}/>;
  }

  const results = Object.keys(context).map(name => renderContextValue(name, context[name]));

  console.log(results);

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Тип события">{detail.event_type}</Descriptions.Item>

            <Descriptions.Item label="E-mail получателя" span={24}>{detail.to_email}</Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Контекст">
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item>
              {results}
            </Descriptions.Item>

            <Descriptions.Item label="Сообщение">
              <div style={{ overflowX: 'scroll', fontSize: '12px', whiteSpace: 'pre' }} dangerouslySetInnerHTML={{ __html: detail?.email_body_tpl }} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Статус" span={24}>{detail.status}</Descriptions.Item>
            <Descriptions.Item label="Дата создания">
              <DateTimeText date={detail.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата отправки">
              <DateTimeText date={detail.date_sent} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  notification: state.notification,
});

const mapDispatch = (dispatch) => ({
  actions: {
    notification: dispatch.notification,
  },
});

export default connect(mapState, mapDispatch)(NotificationDetail);
