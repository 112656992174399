import React from 'react';
import { InputNumber } from 'antd';

import { FormItem } from 'utils';
import { ArrayField } from 'components/common';


const PricesFieldSet = (props) => {
  const { propertyConfig, priceType, ...otherProps } = props;

  const fields = priceType.list.reduce((result, ext_id) => {
    const item = priceType.byId[ext_id];
    if (item.is_active) {
      result.push({ 
        name: item.ext_id,
        label: item.name,
        defaultValue: "",
        component: InputNumber, 
        componentStyle: {minWidth: '200px'},
      });
    }
    return result;
  }, []);

  return (
    <div className="form-block">
      <FormItem {...otherProps} name="old_price" label="Старая цена" required={false} help="Зачёркнутая цена">
        <InputNumber size="large" />
      </FormItem>

      <FormItem {...otherProps} name="site_price" label="Цена сайта" required={false} help="Перезаписывает розничную цену">
        <InputNumber size="large" />
      </FormItem>
      
      <FormItem {...otherProps} name="prices" label="" required={false} valuePropName="items">
        <ArrayField fields={fields} mode="vertical"/>
      </FormItem>
    </div>
  );
};

export default PricesFieldSet;
