import React from 'react';

import { GroupDetail } from 'components/blocks/Group';


const DetailScreen = (props) => {
  return (
    <div className="pageMainWithTabs">
      <GroupDetail />
    </div>
  );
};

export default DetailScreen;
