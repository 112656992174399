import React, { Fragment } from 'react';
import { Route } from 'react-router';
import { Layout } from 'antd';

import { Top } from 'components/layout';
import SiteDetailScreen from './SiteDetailScreen';
import NavMainListScreen from './NavMainListScreen';
import NavMainDetailScreen from './NavMainDetailScreen';
import NavTopListScreen from './NavTopListScreen';
import NavTopDetailScreen from './NavTopDetailScreen';
import NavFooterListScreen from './NavFooterListScreen';
import NavFooterDetailScreen from './NavFooterDetailScreen';
import PluginListScreen from './PluginListScreen';
import PluginDetailScreen from './PluginDetailScreen';
import PageListScreen from './PageListScreen';
import PageDetailScreen from './PageDetailScreen';
import SeoTemplatePageListScreen from './SeoTemplatePageListScreen';
import SeoTemplatePageDetailScreen from './SeoTemplatePageDetailScreen';


const SiteScreen = ({ match }) => {
  return (
    <Fragment>
      <Top
        title="Сайт"
        basePath="/site"
        tabs={[
          { title: 'Сайт', key: 'detail' },
          { title: 'Навигация', key: 'nav' },
          { title: 'Страницы', key: 'page' },
          { title: 'Шаблоны страниц', key: 'seoTemplatePage' },
          { title: 'Плагины', key: 'plugin' },
          //{ title: 'Редиректы', key: 'redirect' },
        ]}
      />

      <Layout className="layout-inner">
        <Layout.Content>
          <Route exact path="/site" component={SiteDetailScreen} />

          <Route exact path="/site/nav" component={NavMainListScreen} />
          <Route exact path="/site/nav/top" component={NavTopListScreen} />
          <Route exact path="/site/nav/footer" component={NavFooterListScreen} />
          <Route exact path="/site/nav/:navMainId" component={NavMainDetailScreen} />
          <Route exact path="/site/nav/top/:navTopId" component={NavTopDetailScreen} />
          <Route exact path="/site/nav/footer/:navFooterId" component={NavFooterDetailScreen} />

          <Route exact path="/site/page" component={PageListScreen} />
          <Route exact path="/site/page/:pageId" component={PageDetailScreen} />

          <Route exact path="/site/seoTemplatePage" component={SeoTemplatePageListScreen} />
          <Route exact path="/site/seoTemplatePage/:seoTemplatePageId" component={SeoTemplatePageDetailScreen} />

          <Route exact path="/site/plugin" component={PluginListScreen} />
          <Route exact path="/site/plugin/:pluginId" component={PluginDetailScreen} />

          <Route exact path="/site/redirect" component={SiteDetailScreen} />
        </Layout.Content>
      </Layout>
    </Fragment>
  );
};

export default SiteScreen;
