import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText, OnOff, Image } from 'components/common';


const SiteDetail = ({ site, actions }) => {
  const itemUpdate = (data) => {
    actions.site.update(data);
  };

  const detail = site.detail || {};

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Домен">{detail.url}</Descriptions.Item>

            <Descriptions.Item label="Шаблон">{detail.template}</Descriptions.Item>

            <Descriptions.Item span={3} label="favicon">
              <Image src={detail.favicon?.url} height={56} />
            </Descriptions.Item>

            <Descriptions.Item span={21} label="Название">{detail.name}</Descriptions.Item>

            <Descriptions.Item label="Активный">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_active"
                updateStatus={site.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="robots.txt" style={{ whiteSpace: "pre-line" }}>{detail.robots}</Descriptions.Item>

            <Descriptions.Item label="scripts_top" style={{ whiteSpace: "pre-line" }}>{detail.scripts_top}</Descriptions.Item>

            <Descriptions.Item label="scripts_bottom" style={{ whiteSpace: "pre-line" }}>{detail.scripts_bottom}</Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Дата создания" size="sm">
              <DateTimeText date={detail.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата синхронизации" size="sm">
              <DateTimeText date={detail.date_synced} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Настройки синхр.">
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="импортировать свойства" size="sm">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_import_properties"
                updateStatus={site.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>
            <Descriptions.Item label="импортировать категории" size="sm">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_import_categories"
                updateStatus={site.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>

            <Descriptions.Item label="импортировать картинки товаров" size="sm">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_import_product_images"
                updateStatus={site.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>
            <Descriptions.Item label="импортировать названия товаров" size="sm">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_import_product_name"
                updateStatus={site.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>
            <Descriptions.Item label="импортировать артикул товаров" size="sm">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_import_product_art_no"
                updateStatus={site.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>
            <Descriptions.Item label="импортировать описания товаров" size="sm">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_import_product_text"
                updateStatus={site.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  site: state.site,
});

const mapDispatch = (dispatch) => ({
  actions: {
    site: dispatch.site,
  },
});

export default connect(mapState, mapDispatch)(SiteDetail);
