import React from 'react';
import { connect } from 'react-redux';

import { Fieldset } from 'components/layout';
import { RecommendedCategoryList, RecommendedCategoryForm } from 'components/blocks/RecommendedCategory';
import { RecommendedGroupList, RecommendedGroupForm } from 'components/blocks/RecommendedGroup';


const RecommendedScreen = (props) => {
  const detail = props.group.detail || {};

  return (
    <div className="pageMainWithTabs">
      <Fieldset title="Рекоммендуемые категории">
        {detail.id && <RecommendedCategoryList activeFilter={{ groupId: detail.id }} groupId={detail.id} />}
      </Fieldset>

      <Fieldset title="Рекоммендуемые группы товаров">
        {detail.id && <RecommendedGroupList activeFilter={{ groupId: detail.id }} groupId={detail.id} />}
      </Fieldset>

      {detail.id && <RecommendedCategoryForm groupId={detail.id} />}
      {detail.id && <RecommendedGroupForm groupId={detail.id} />}
    </div>
  );
};

const mapState = (state) => ({
  group: state.group,
});

export default connect(mapState)(RecommendedScreen);
