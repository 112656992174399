import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText } from 'components/common';
import { PageType } from 'components/blocks/Page'


const SeoTemplatePageDetail = ({ pageDetail, seoTemplatePage, actions }) => {
  const detail = pageDetail || seoTemplatePage.detail;

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Путь" span={24}>{detail?.tpl_path}</Descriptions.Item>

            <Descriptions.Item label="Тайтл">{detail?.tpl_title}</Descriptions.Item>

            <Descriptions.Item label="Дескрипшен">{detail?.tpl_description}</Descriptions.Item>

            <Descriptions.Item label="h1">{detail?.tpl_h1}</Descriptions.Item>

            <Descriptions.Item label="h2">{detail?.tpl_h2}</Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Тип" size="sm">{PageType.getValue(detail?.page_type)}</Descriptions.Item>

            <Descriptions.Item label="Дата создания" size="sm">
              <DateTimeText date={detail?.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения" size="sm">
              <DateTimeText date={detail?.date_changed} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  seoTemplatePage: state.seoTemplatePage,
});

const mapDispatch = (dispatch) => ({
  actions: {
    seoTemplatePage: dispatch.seoTemplatePage,
  },
});

export default connect(mapState, mapDispatch)(SeoTemplatePageDetail);
