import React from 'react';
import { Input, Row, Col } from 'antd';

import { FormItem } from 'utils';


const ScriptsFieldSet = (props) => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <div className="form-block">
          <FormItem {...props} name="robots" label="robots.txt" required={false}>
            <Input.TextArea rows={4} />
          </FormItem>

          <FormItem {...props} name="scripts_top" label="Скрипты в шапке" required={false}>
            <Input.TextArea rows={4} />
          </FormItem>

          <FormItem {...props} name="scripts_bottom" label="Срипты в футере" required={false}>
            <Input.TextArea rows={4} />
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default ScriptsFieldSet;
