import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { NavFooterForm } from 'components/blocks/NavFooter';
import DetailScreen from './DetailScreen';


const NavFooterDetailScreen = ({ history, navFooter, actions, match }) => {
  useEffect(() => {
    actions.navFooter.detailLoad({ id: match.params.navFooterId });
  }, [match.params.navFooterId, actions.navFooter]);

  const id = match.params.navFooterId;
  const detail = navFooter.detail || {};

  return (
    <Page
      isLoading={navFooter.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.navFooter}
      breadcrumbs={[
        { title: 'Сайт', path: '/site' },
        { title: 'Навигация', path: navFooter.urls.list() },
        { title: detail.title, path: navFooter.urls.detail(id) },
      ]}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={navFooter.urls.detail(id)}
    >
      <Route exact path="/site/nav/footer/:navFooterId" component={DetailScreen} />

      <NavFooterForm />
    </Page>
  );
};

const mapState = (state) => ({
  navFooter: state.navFooter,
});

const mapDispatch = (dispatch) => ({
  actions: {
    navFooter: dispatch.navFooter,
  },
});

export default connect(mapState, mapDispatch)(NavFooterDetailScreen);
