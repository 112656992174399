import React from 'react';
import { Input, Select, Row, Col } from 'antd';

import { FormItem } from 'utils';
import { ImageUpload } from 'components/common';


const MainFieldSet = (props) => {
  const editObject = props.site.editObject || {};
  console.log('editObject', editObject)

  return (
    <Row gutter={32}>
      <Col span={16}>
        <div className="form-block">
          <Row gutter={32}>
            <Col span={8}>
              <FormItem {...props} name="scheme" label="Схема" required={true}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Выберите"
                >
                  <Select.Option value="http">http</Select.Option>
                  <Select.Option value="https">https</Select.Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem {...props} name="domain" label="Домен" required={true}>
                <Input size="large" />
              </FormItem>
            </Col>
          </Row>

          <FormItem {...props} name="template" label="Шаблон" required={true}>
            <Input size="large" />
          </FormItem>
        </div>

        <div className="form-block">
          <FormItem {...props} name="name" label="Название сайта" required={false}>
            <Input size="large" />
          </FormItem>
        </div>
      </Col>

      <Col span={8}>
        <div className="form-block">
          <FormItem {...props} name="favicon" required={false} valuePropName="fileList">
            <ImageUpload
              title="Загрузить favicon"
              mode='single'
              auth_token={props.auth_token}
              siteDomain={props.site.activeDomain}
              modelName="site.site"
              data={{ ext_id: editObject.id }}
            />
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
