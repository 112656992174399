import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText, OnOff, Image } from 'components/common';


const BrandDetail = ({ brand, actions }) => {
  const itemUpdate = (data) => {
    actions.brand.update(data);
  };

  const detail = brand.detail || {};
  const logo = detail.logo || {};

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Название">{detail.name}</Descriptions.Item>

            <Descriptions.Item label="Код">{detail.slug}</Descriptions.Item>
            
            <Descriptions.Item label="Активный">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_active"
                updateStatus={brand.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>

            <Descriptions.Item label="Верхнее описание" span={24}>
              <div style={{overflowX: 'scroll', fontSize: '12px'}} dangerouslySetInnerHTML={{__html: detail.teaser}} />
            </Descriptions.Item>

            <Descriptions.Item label="Описание" span={24}>
              <div style={{overflowX: 'scroll', fontSize: '12px'}} dangerouslySetInnerHTML={{__html: detail.text}} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <div key={`photo${logo.sha1}`}>
            <Image src={logo.url} height={200} />
          </div>
        </Fieldset>

        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="EXT_ID" size="sm">{detail.ext_id}</Descriptions.Item>
            <Descriptions.Item label="Дата создания" size="sm">
              <DateTimeText date={detail.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения" size="sm">
              <DateTimeText date={detail.date_changed} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата синхронизации" size="sm">
              <DateTimeText date={detail.date_synced} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  brand: state.brand,
});

const mapDispatch = (dispatch) => ({
  actions: {
    brand: dispatch.brand,
  },
});

export default connect(mapState, mapDispatch)(BrandDetail);
