import React from 'react';

import { CategoryDetail } from 'components/blocks/Category';

const DetailScreen = (props) => {
  return (
    <div className="page-main">
      <CategoryDetail />
    </div>
  );
};

export default DetailScreen;
