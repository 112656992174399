import { go } from "connected-react-router";

import { CATALOG_API_URL } from 'config';
import { api, loadStates, loadStatus, importResults } from 'utils';
import * as base from '../base';

const endpoints = {
  list: `${CATALOG_API_URL}/api/v1/site/`,
  detail: (id) => `${CATALOG_API_URL}/api/v1/site/${id}/`,
  history: (id) => `${CATALOG_API_URL}/api/v1/site/${id}/history/`,
};

export const siteListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const siteDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });
export const siteUpdate = (token, data) => api.Update(endpoints.list, endpoints.detail, token, data);
export const siteDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const siteHistoryLoad = (token, payload) => api.DetailLoad(endpoints.history, token, { id: payload.id });

export const site = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    activeDomain: null,
    title: 'Сайты',
    urls: {
      list: () => `/site`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,

    selectById(state, id) {
      return {
        ...state,
        activeId: id,
        activeDomain: state.byId[id].domain,
      };
    },

    listLoadComplete(state, payload) {
      const { list, byId } = importResults(payload.results);
      const activeId = !state.activeId ? list[0]: state.activeId;
      const activeDomain = !state.activeDomain ? byId[activeId].domain: state.activeDomain;

      return {
        ...state,
        loadListStatus: loadStatus(loadStates.loaded),
        list: list,
        byId: byId,
        activeId: activeId,
        activeDomain: activeDomain,
        pageSize: payload.next ? list.length : payload.count,
        count: payload.count,
        info: payload.info,
      };
    },
  },
  effects: (dispatch) => ({
    async select(payload, rootState) {
      dispatch['site'].selectById(payload);
      setTimeout(() => {
        dispatch(go(0))
      }, 500);
    },

    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'site', siteListLoad);
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'site', siteDetailLoad);
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'site', siteUpdate);
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'site', siteDelete);
    },
  }),
};
