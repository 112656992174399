import React from 'react';
import { Form } from 'antd';
import { isDict } from './helpers';

function getValidateStatus(fieldName, errorData) {
  if (fieldName in errorData) {
    let error = errorData[fieldName];
    if (error.non_field_errors) {
      error = error.non_field_errors;
    }
    return {
      validateStatus: 'error',
      help: error,
    };
  }
  return {};
}

export const FormItem = (props) => {
  const { className, errorData, required, ...otherProps } = props;

  return (
    <Form.Item
      className={className}
      rules={[{ required: required, message: 'Обязательное поле' }]}
      {...getValidateStatus(otherProps.name, errorData || {})}
      {...otherProps}
    >
      {props.children}
    </Form.Item>
  );
};

export const getEditObject = (editObject) => {
  let newEditObject = {};
  for (var fieldName in editObject) {
    const _val = editObject[fieldName];

    if (isDict(_val) && !('sha1' in _val)) {
      for (var childFieldName in _val) {
        newEditObject[`${fieldName}.${childFieldName}`] = editObject[fieldName][childFieldName];
      }
    } else {
      newEditObject[fieldName] = editObject[fieldName];
    }
  }

  return newEditObject;
};

export const getSubmitData = (data) => {
  let newData = {};
  for (var fieldName in data) {
    let _val = data[fieldName];

    const parts = fieldName.split('.');
    if (parts.length > 1) {
      let _parent = newData[parts[0]] || {};
      _parent[parts[1]] = _val;
      newData[parts[0]] = _parent;
    } else {
      newData[fieldName] = _val;
    }
  }

  return newData;
};
