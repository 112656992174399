import React, { useCallback, useState } from 'react';
import { Switch } from 'antd';
import { connect } from 'react-redux';

import { tableColumn } from 'utils';
import { TableList, ActionButton, DateTimeText, PropValue } from 'components/common';
import CategoryValue from '../CategoryValue';


const CategoryList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, category }) => {
  const defaultColumnList = ['name', 'slug', 'sort', 'date_synced', 'is_active', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const columnByName = {
    expand: tableColumn('', 'id', {
      render: (val, item) => '',
      sorter: false,
      width: 30,
    }),
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Название', 'name', {
      render: (val, item) => (
        <div style={{float: "left"}}>
          <CategoryValue detail={item} asLink={true} isBack={true} />
          <PropValue label="EXT_ID" value={item.ext_id}/>
        </div>
      ),
      sorter: true,
    }),
    slug: tableColumn('Код', 'slug', {
      width: 150,
      sorter: true,
    }),
    sort: tableColumn('Сортировка', 'sort', {
      width: 80,
      sorter: true,
    }),
    date_synced: tableColumn('Дата синхронизации', 'date_synced', {
      render: (val, item) => <DateTimeText date={val} />,
      width: 150,
      sorter: true,
    }),
    is_active: tableColumn('Активен', 'is_active', {
      sorter: true,
      width: 100,
      render: (val, item) => (
        <div>
          <Switch
            style={{ marginTop: '5px', minWidth: '62px' }}
            checkedChildren="вкл"
            unCheckedChildren="выкл"
            checked={item.is_active}
            loading={category.updateStatus.isLoading && itemIdForUpdate === item.id}
            onClick={() => onCategoryOnOff(item)}
          />
        </div>
      ),
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton 
          detail={item} 
          modelActions={actions.category} 
          actions={[{ name: 'edit' }, { name: 'delete' }]}
          preventLoadDetail={true}
        />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = category.filter.activeFilter;
      }
      actions.category.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [category, actions, setSelected],
  );

  const onCategoryOnOff = (item) => {
    const data = {
      id: item.id,
      is_active: !item.is_active,
      preventLoadDetail: true,
    };
    setItemIdForUpdate(item.id);
    actions.category.update(data);
  };

  const batchDelete = (selected) => {
    actions.category.batchDelete({ selected });
  };

  return (
    <TableList
      location={router.location}
      model={category}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      selected={selected}
      rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}
      rowActions={[
        {
          title: 'Удалить',
          icon: 'delete',
          action: (selected) => batchDelete(selected),
        },
      ]}
      filterFields={{
        list: ['is_active'],
        byName: {
          is_active: {
            label: 'Активно',
            type: 'bool',
          },
        },
      }}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  category: state.category,
});

const mapDispatch = (dispatch) => ({
  actions: {
    category: dispatch.category,
  },
});

export default connect(mapState, mapDispatch)(CategoryList);
