import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';

const NavMainForm = (props) => {
  const { showEditForm } = props.navMain;

  const onClose = () => {
    props.actions.navMain.showEditForm({ show: false });
  };

  const onSubmit = (data) => {
    const newData = Object.assign(data, {
      site: props.site.activeId,
      page: data.page ? data.page: null,
    });
    props.actions.navMain.update(newData);
  };

  return (
    <Form
      width={800}
      title="Редактирование навигации"
      model={props.navMain}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={{ sort: 100 }}
      convertEditObject={(editObject) => ({
        ...editObject,
        page: editObject.page ? editObject.page.id: null,
      })}
    >
      <MainFieldSet {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  site: state.site,
  auth_token: state.auth.token,
  navMain: state.navMain,
});

const mapDispatch = (dispatch) => ({
  actions: {
    navMain: dispatch.navMain,
  },
});

export default connect(mapState, mapDispatch)(NavMainForm);
