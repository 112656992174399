import React from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';

import { pageListLoad } from 'store/models/page';
import { FormItem } from 'utils';
import { ListLoadComponent } from 'components/common';

const PageSelect = (props) => {
  return (
    <ListLoadComponent
      apiLoad={pageListLoad(props.site.activeDomain)}
      {...props}
      render={({ items }) => {
        const { onChange, disabled, router, authToken, model, dispatch, activeFilter, ...otherProps } = props;
        return (
          <FormItem {...otherProps}>
            <Select
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Выберите"
              showSearch={true}
              allowClear={true}
              disabled={disabled}
              filterOption={(input, option) => {
                let children = option.props.children;
                if (typeof children !== 'string') {
                  children = children.join();
                }
                return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            >
              {!otherProps.required && <Select.Option value={null}>---</Select.Option>}
              {items.map(item => (
                <Select.Option key={`page${item.id}`} value={item.id}>
                  {item.title}: {item.path}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        );
      }}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  authToken: state.auth.token,
  model: state.page,
  site: state.site,
});

export default connect(mapState)(PageSelect);
