import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { tableColumn } from 'utils';
import { TableList, ActionButton, DateTimeText, PropValue } from 'components/common';
import OrderValue from '../OrderValue';


const OrderList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, order }) => {
  const defaultColumnList = ['number', 'status', 'user', 'shipping', 'payment', 'date_created', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    number: tableColumn('Номер', 'number', {
      render: (val, item) => (
        <div>
          <OrderValue detail={item} asLink={true} isBack={true} />
          <PropValue label="Сумма" value={item.total}/>
          <PropValue label="Кол-во товаров" value={item.num_items}/>
        </div>
      ),
      sorter: true,
      width: 150,
    }),
    status: tableColumn('Статус', 'status', {
      sorter: true,
      width: 150,
    }),
    user: tableColumn('Клиент', 'user', {
      render: (val, item) => (
        <div>
          <PropValue label="Имя" value={item.user_name}/>
          <PropValue label="Телефон" value={item.user_phone}/>
          <PropValue label="E-mail" value={item.user_email}/>
        </div>
      ),
      sorter: false,
    }),
    shipping: tableColumn('Доставка', 'shipping', {
      render: (val, item) => (
        <div>
          {item.shipping_method === 'pickup' && 
            <div>
              <PropValue label="Способ доставки" value="самовывоз"/>
              <PropValue label="Магазин" value={item.shipping_warehouse ? item.shipping_warehouse.name: ''}/>
            </div>}

          {item.shipping_method === 'courier' && 
            <div>
              <PropValue label="Способ доставки" value="курьером"/>
              <PropValue label="Адрес" value={item.shipping_address ? item.shipping_address.address: ''}/>
            </div>}
        </div>
      ),
      sorter: false,
    }),
    payment: tableColumn('Оплата', 'payment', {
      render: (val, item) => (
        <div>
          {item.payment_method === 'offline' && 
            <div>
              <PropValue label="Способ оплаты" value="при получении"/>
            </div>}
          
          {item.payment_method === 'online' && 
            <div>
              <PropValue label="Способ оплаты" value="онлайн"/>
            </div>}
        </div>
      ),
      sorter: false,
    }),
    date_created: tableColumn('Дата', 'date_created', {
      render: (val, item) => <DateTimeText date={val} />,
      width: 150,
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.order} actions={[{ name: 'edit' }/*, { name: 'delete' }*/]} />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = order.filter.activeFilter;
      }
      actions.order.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [order, actions, setSelected],
  );

  return (
    <TableList
      location={router.location}
      model={order}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      selected={selected}
      /*rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}*/
      /*filterFields={{
        list: ['is_active'],
        byName: {
          is_active: {
            label: 'Активно',
            type: 'bool',
          },
        },
      }}*/
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  order: state.order,
});

const mapDispatch = (dispatch) => ({
  actions: {
    order: dispatch.order,
  },
});

export default connect(mapState, mapDispatch)(OrderList);
