import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { PageForm } from 'components/blocks/Page';
import BrandPageScreen from './BrandPageScreen'


const CategoryPageDetailScreen = ({ history, brand, actions, match }) => {
  useEffect(() => {
    actions.brand.detailLoad({ id: match.params.brandId });
  }, [match.params.brandId, actions.brand]);

  const id = match.params.brandId;
  const detail = brand?.detail?.page || {};

  const breadcrumbs = useMemo(() => {
    let result = [{ title: 'Каталог', path: '/catalog' }, { title: 'Бренды', path: brand.urls.list() }];
    const ancestors = detail.ancestors || [];
    return result.concat(ancestors.map((item) => ({ title: item.name, path: brand.urls.detail(item.id) })));
  }, [detail.ancestors, brand.urls]);

  return (
    <Page
      isLoading={brand.loadDetailStatus.isLoading}
      detail={detail}
      title={`Страница «${detail.h1}»`}
      modelActions={actions.page}
      breadcrumbs={breadcrumbs}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={brand.urls.detail(id)}
      tabs={[
        { title: 'Основное', key: 'detail' },
        { title: 'Страница', key: 'page' },
      ]}
    >
      <Route exact path="/catalog/brand/:brandId/page" component={BrandPageScreen} />

      <PageForm />
    </Page>
  );
};

const mapState = (state) => ({
  brand: state.brand,
});

const mapDispatch = (dispatch) => ({
  actions: {
    brand: dispatch.brand,
    page: dispatch.page,
  },
});

export default connect(mapState, mapDispatch)(CategoryPageDetailScreen);
