import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { tableColumn } from 'utils';
import { TableList, ActionButton, DateTimeText, OnOff } from 'components/common';
import PageType from '../PageType';
import PageValue from '../PageValue';


const PageList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, page }) => {
  const defaultColumnList = ['path', 'title', 'h1', 'page_type', 'seo_template_off', 'date_changed', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }

  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const itemUpdate = (data) => {
    setItemIdForUpdate(data.id);
    actions.page.update({ ...data, preventLoadDetail: true });
  };

  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    path: tableColumn('Путь', 'path', {
      render: (val, item) => <PageValue detail={item} asLink={true} isBack={true} />,
      sorter: true,
    }),
    title: tableColumn('Тайтл', 'title', {
      sorter: true,
    }),
    h1: tableColumn('H1', 'h1', {
      sorter: true,
    }),
    page_type: tableColumn('Тип', 'page_type', {
      render: (val, item) => <PageType detail={item} />,
      sorter: true,
    }),
    seo_template_off: tableColumn('Не исп. seo-шаблон', 'seo_template_off', {
      render: (val, item) => (
        <OnOff
          item={item}
          updateItemId={itemIdForUpdate}
          param="seo_template_off"
          updateStatus={page.updateStatus}
          onChange={itemUpdate}
        />
      ),
      sorter: true,
      width: 130,
    }),
    date_changed: tableColumn('Дата изменения', 'date_changed', {
      render: (val, item) => <DateTimeText date={val} />,
      width: 150,
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.page} actions={[{ name: 'edit' }, { name: 'delete' }]} />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = page.filter.activeFilter;
      }
      actions.page.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [page, actions, setSelected],
  );

  const batchDelete = (selected) => {
    actions.page.batchDelete({ selected });
  };

  return (
    <TableList
      location={router.location}
      model={page}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      selected={selected}
      rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}
      rowActions={[
        {
          title: 'Удалить',
          icon: 'delete',
          action: (selected) => batchDelete(selected),
        },
      ]}
      filterFields={{
        list: ['page_type', 'seo_template_off', 'descriptionEmpty'],
        byName: {
          seo_template_off: {
            label: 'Не использовать seo-шаблон',
            type: 'bool',
          },
          descriptionEmpty: {
            label: 'Десрипшен пустой',
            type: 'bool',
          },
          page_type: {
            label: 'Тип',
            type: 'select',
            options: PageType.TYPE_LIST.map(item => ({ value: item[0], label: item[1] })),
            valueComponent: ({ value }) => PageType.getValue(value),
          },
        },
      }}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  page: state.page,
});

const mapDispatch = (dispatch) => ({
  actions: {
    page: dispatch.page,
  },
});

export default connect(mapState, mapDispatch)(PageList);
