import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { Fieldset } from 'components/layout';
import { Descriptions, OnOff } from 'components/common';
import { NotificationStaffSubscriberList, NotificationStaffSubscriberForm } from 'components/blocks/NotificationStaffSubscriber'


const NotificationTemplateDetail = ({ notificationTemplate, actions }) => {
  const detail = notificationTemplate.detail || {};

  const itemUpdate = (data) => {
    actions.notificationTemplate.update(data);
  };

  const addSubscriber = () => {
    actions.notificationStaffSubscriber.showEditForm({
      show: true,
      preventLoadDetail: true
    });
  };


  return (
    <Row gutter={16}>
      <Col span={15}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Тип события">{detail.event_type}</Descriptions.Item>

            <Descriptions.Item label="E-mail отправителя" span={24}>{detail.from_email}</Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset title="Клиент">
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Тема письма">{detail.email_subject_tpl}</Descriptions.Item>

            <Descriptions.Item label="Сообщение">
              <div style={{ overflowX: 'scroll', fontSize: '12px', whiteSpace: 'pre' }} dangerouslySetInnerHTML={{ __html: detail?.email_body_tpl }} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={9}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Для менеджеров">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="for_manager"
                updateStatus={notificationTemplate.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>

            <Descriptions.Item label="Активно">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_active"
                updateStatus={notificationTemplate.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>

        {detail?.for_manager &&
          <Fieldset title="Подписчики">
            <NotificationStaffSubscriberList
              activeFilter={{ templates: detail?.id }}
              topActions={[
                <Button type="primary" icon={<PlusOutlined />} onClick={addSubscriber}>
                  Добавить
                </Button>
              ]}
            />
          </Fieldset>
        }
      </Col>

      {detail?.id && <NotificationStaffSubscriberForm templateId={detail?.id} />}
    </Row>
  );
};

const mapState = (state) => ({
  notificationTemplate: state.notificationTemplate,
});

const mapDispatch = (dispatch) => ({
  actions: {
    notificationTemplate: dispatch.notificationTemplate,
    notificationStaffSubscriber: dispatch.notificationStaffSubscriber,
  },
});

export default connect(mapState, mapDispatch)(NotificationTemplateDetail);
