import React from 'react';
import { connect } from 'react-redux';

import { PageDetail } from 'components/blocks/Page';


const BrandPageScreen = (props) => {
  const brandDetail = props.brand?.detail;

  return (
    <div className="page-main">
      <PageDetail pageDetail={brandDetail?.page || {}} />
    </div>
  );
};

const mapState = (state) => ({
  brand: state.brand,
  page: state.page,
});

const mapDispatch = (dispatch) => ({
  actions: {
    page: dispatch.page,
  },
});

export default connect(mapState, mapDispatch)(BrandPageScreen);
