import React from 'react';
import { InputNumber, Row, Col } from 'antd';

import { FormItem } from 'utils';
import { CategorySelect } from 'components/blocks/Category';


const MainFieldSet = (props) => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <div className="form-block">
          <CategorySelect name="recommended_category" label="Рекомендуемая категория" required={true} multiple={false}/>

          <FormItem {...props} name="sort" label="Сортировка" required={true}>
            <InputNumber size="large" />
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
