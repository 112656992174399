import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';


const NotificationStaffSubscriberForm = (props) => {
  const { showEditForm } = props.notificationStaffSubscriber;

  const onClose = () => {
    props.actions.notificationStaffSubscriber.showEditForm({ show: false });
  };

  const onSubmit = (data) => {
    const newData = Object.assign(data, {
      site: props.site.activeId,
    });
    console.log('newData', newData);
    props.actions.notificationStaffSubscriber.update(newData);
  };

  return (
    <Form
      width={800}
      title="Редактирование подписчика"
      model={props.notificationStaffSubscriber}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={{ templateId: props.templateId, is_active: true }}
      convertEditObject={(editObject) => ({
        ...editObject,
      })}
    >
      <MainFieldSet {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  site: state.site,
  auth_token: state.auth.token,
  notificationStaffSubscriber: state.notificationStaffSubscriber,
});

const mapDispatch = (dispatch) => ({
  actions: {
    notificationStaffSubscriber: dispatch.notificationStaffSubscriber,
  },
});

export default connect(mapState, mapDispatch)(NotificationStaffSubscriberForm);
