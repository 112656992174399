import React, { useCallback, useState } from 'react';
import { Switch } from 'antd';
import { connect } from 'react-redux';

import { tableColumn } from 'utils';
import { TableList, ActionButton, DateTimeText, PropValue } from 'components/common';
import BrandValue from '../BrandValue';


const BrandList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, brand }) => {
  const defaultColumnList = ['name', 'slug', 'date_synced', 'is_active', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Название', 'name', {
      render: (val, item) => (
        <div>
          <BrandValue detail={item} asLink={true} isBack={true} />
          <PropValue label="EXT_ID" value={item.ext_id}/>
        </div>
      ),
      sorter: true,
    }),
    slug: tableColumn('Код', 'slug', {
      sorter: true,
    }),
    date_synced: tableColumn('Дата синхронизации', 'date_synced', {
      render: (val, item) => <DateTimeText date={val} />,
      width: 150,
      sorter: true,
    }),
    is_active: tableColumn('Активен', 'is_active', {
      sorter: true,
      width: 100,
      render: (val, item) => (
        <div>
          <Switch
            style={{ marginTop: '5px', minWidth: '62px' }}
            checkedChildren="вкл"
            unCheckedChildren="выкл"
            checked={item.is_active}
            loading={brand.updateStatus.isLoading && itemIdForUpdate === item.id}
            onClick={() => onBrandOnOff(item)}
          />
        </div>
      ),
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.brand} actions={[{ name: 'edit' }, { name: 'delete' }]} />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = brand.filter.activeFilter;
      }
      actions.brand.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [brand, actions, setSelected],
  );

  const onBrandOnOff = (item) => {
    const data = {
      id: item.id,
      is_active: !item.is_active,
      preventLoadDetail: true,
    };
    setItemIdForUpdate(item.id);
    actions.brand.update(data);
  };

  const batchDelete = (selected) => {
    actions.brand.batchDelete({ selected });
  };

  return (
    <TableList
      location={router.location}
      model={brand}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      selected={selected}
      rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}
      rowActions={[
        {
          title: 'Удалить',
          icon: 'delete',
          action: (selected) => batchDelete(selected),
        },
      ]}
      filterFields={{
        list: ['is_active'],
        byName: {
          is_active: {
            label: 'Активно',
            type: 'bool',
          },
        },
      }}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  brand: state.brand,
});

const mapDispatch = (dispatch) => ({
  actions: {
    brand: dispatch.brand,
  },
});

export default connect(mapState, mapDispatch)(BrandList);
