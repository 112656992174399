import React from 'react';
import { Input } from 'antd';

import { FormItem } from 'utils';
import { ArrayField } from 'components/common';


const MainFieldSet = (props) => {
  const { propertyConfig, ...otherProps } = props;

  const fields = propertyConfig.reduce((result, item) => {
    if (!item.is_common) {
      result.push({ 
        name: item.property_ext_id,
        label: item.property_name,
        defaultValue: "",
        component: Input, 
        componentStyle: {minWidth: '150px'},
      });
    }
    return result;
  }, [{ 
    name: 'name', 
    label: 'Название', 
    defaultValue: "",
    component: Input, 
    componentStyle: {minWidth: '600px'},
  }]);

  return (
    <div className="form-block">
      <FormItem {...otherProps} name="selectedProducts" label="" required={false} valuePropName="items">
        <ArrayField fields={fields} />
      </FormItem>
    </div>
  );
};

export default MainFieldSet;
