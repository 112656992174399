import React, { useState, useEffect } from 'react';
import { Drawer, Form, Radio, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { FormItem, getUrlSearch } from 'utils';

import './filter.css';

const getInstanceFromQueryParams = (fields, params) => {
  return fields.list.reduce((result, fieldName) => {
    let value = params[fieldName];
    const field = fields.byName[fieldName];
    const fieldType = field && field.type;
    switch (fieldType) {
      case 'bool':
        if (!value) {
          value = '';
        }
        break;
      case 'int':
        value = parseInt(value);
        break;
      case 'list':
        if (!Array.isArray(value)) {
          value = [value];
        }
        break;
      case 'list_int':
        if (Array.isArray(value)) {
          value = value.map((v) => parseInt(v));
        } else {
          value = [parseInt(value)];
        }
        break;
      default:
        break;
    }
    result[fieldName] = value;
    return result;
  }, {});
};

const Filter = ({ fields, data, onLoadData, visible, onToggle }) => {
  const [form] = Form.useForm();

  const [query, setQuery] = useState(null);
  const [instance, setInstance] = useState({});

  const onValuesChange = (changedValues, allValues) => {
    if (onLoadData) {
      let params = Object.assign({}, data.filter.params);

      Object.keys(allValues).forEach((fieldName) => {
        let value = allValues[fieldName];
        if (value !== undefined) {
          params[fieldName] = value;
        }
      });

      const query = '?' + getUrlSearch(params);
      onLoadData({ query, activeFilter: data.activeFilter });
    }
  };

  useEffect(() => {
    if (visible && query !== data.filter.query) {
      setQuery(data.filter.query);
      const inst = getInstanceFromQueryParams(fields, data.filter.params);
      setInstance(inst);
    } else if (visible) {
      form.setFieldsValue(instance);
    }
  }, [form, visible, instance, query, fields, data.filter.query, data.filter.params]);

  return (
    <Drawer
      title="Фильтры"
      visible={visible}
      width={300}
      onClose={onToggle}
      placement="right"
      destroyOnClose={true}
      forceRender={true}
      handler={
        visible && (
          <div className="filter-handle" onClick={onToggle}>
            <CloseOutlined />
          </div>
        )
      }
      style={{
        zIndex: 999,
      }}
    >
      <div className="filter-content">
        <Form form={form} onValuesChange={onValuesChange} layout="vertical">
          {fields.list.map((name) => {
            const field = fields.byName[name];
            if (field.component) {
              return <field.component key={name} name={name} label={field.label} activeFilter={field.activeFilter} />;
            }
            switch (field.type) {
              case 'bool':
                return (
                  <FormItem key={name} name={name} label={field.label}>
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="">Все</Radio.Button>
                      <Radio.Button value="true">Да</Radio.Button>
                      <Radio.Button value="false">Нет</Radio.Button>
                    </Radio.Group>
                  </FormItem>
                );
              case 'select':
                return (
                  <FormItem key={name} name={name} label={field.label}>
                    <Select options={[{ value: '', label: '----' }, ...field.options]} />
                  </FormItem>
                );
              default:
                return '';
            }
          })}
        </Form>
      </div>
    </Drawer>
  );
};

export default Filter;
