import React from 'react';
import { connect } from 'react-redux';

import { Fieldset } from 'components/layout';


const SeoScreen = (props) => {
  // const detail = props.group.detail || {};

  return (
    <div className="pageMainWithTabs">
      <Fieldset>
        
      </Fieldset>
    </div>
  );
};

const mapState = (state) => ({
  group: state.group,
});

export default connect(mapState)(SeoScreen);
