import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { WarehouseForm } from 'components/blocks/Warehouse';
import DetailScreen from './DetailScreen';

const WarehouseDetailScreen = ({ history, warehouse, actions, match }) => {
  useEffect(() => {
    actions.warehouse.detailLoad({ id: match.params.warehouseId });
  }, [match.params.warehouseId, actions.warehouse]);

  const id = match.params.warehouseId;
  const detail = warehouse.detail || {};

  return (
    <Page
      isLoading={warehouse.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.warehouse}
      breadcrumbs={[
        { title: 'Склады', path: warehouse.urls.list() },
        { title: detail.name, path: warehouse.urls.detail(id) },
      ]}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={warehouse.urls.detail(id)}
    >
      <Route exact path="/catalog/warehouse/:warehouseId" component={DetailScreen} />

      <WarehouseForm />
    </Page>
  );
};

const mapState = (state) => ({
  warehouse: state.warehouse,
});

const mapDispatch = (dispatch) => ({
  actions: {
    warehouse: dispatch.warehouse,
  },
});

export default connect(mapState, mapDispatch)(WarehouseDetailScreen);
