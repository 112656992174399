import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { NavTopForm } from 'components/blocks/NavTop';
import DetailScreen from './DetailScreen';


const NavTopDetailScreen = ({ history, navTop, actions, match }) => {
  useEffect(() => {
    actions.navTop.detailLoad({ id: match.params.navTopId });
  }, [match.params.navTopId, actions.navTop]);

  const id = match.params.navTopId;
  const detail = navTop.detail || {};

  return (
    <Page
      isLoading={navTop.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.navTop}
      breadcrumbs={[
        { title: 'Сайт', path: '/site' },
        { title: 'Навигация', path: navTop.urls.list() },
        { title: detail.title, path: navTop.urls.detail(id) },
      ]}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={navTop.urls.detail(id)}
    >
      <Route exact path="/site/nav/top/:navTopId" component={DetailScreen} />

      <NavTopForm />
    </Page>
  );
};

const mapState = (state) => ({
  navTop: state.navTop,
});

const mapDispatch = (dispatch) => ({
  actions: {
    navTop: dispatch.navTop,
  },
});

export default connect(mapState, mapDispatch)(NavTopDetailScreen);
