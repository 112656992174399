import React from 'react';
import { Input, Row, Col } from 'antd';

import { FormItem } from 'utils';
import { PageType, PageTypeSelect } from 'components/blocks/Page'


const MainFieldSet = (props) => {
  const editObject = props.page?.editObject;

  const isForObject = PageType.isForObject(editObject?.page_type, editObject?.object_id);

  return (
    <Row gutter={32}>
      <Col span={24}>
        <div className="form-block">
          <FormItem {...props} name="page_type" label="Тип страницы" required={true}>
            <PageTypeSelect isForObject={isForObject} />
          </FormItem>

          <FormItem {...props} name="path" label="Путь" required={true}>
            <Input size="large" />
          </FormItem>

          <FormItem {...props} name="title" label="Тайтл" required={false}>
            <Input size="large" />
          </FormItem>

          <FormItem {...props} name="description" label="Дескрипшен" required={false}>
            <Input.TextArea rows={3} />
          </FormItem>

          <FormItem {...props} name="h1" label="H1" required={false}>
            <Input size="large" />
          </FormItem>

          <FormItem {...props} name="h2" label="H2" required={false}>
            <Input size="large" />
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
