import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { tableColumn } from 'utils';
import { TableList, ActionButton, OnOff } from 'components/common';


const NotificationStaffSubscriberList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, notificationStaffSubscriber }) => {
  const defaultColumnList = ['email', 'is_active', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }

  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const itemUpdate = (data) => {
    setItemIdForUpdate(data.id);
    actions.notificationStaffSubscriber.update({ ...data, preventLoadDetail: true });
  };

  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    email: tableColumn('E-mail', 'email', {
      sorter: true,
    }),
    is_active: tableColumn('Активно', 'is_active', {
      render: (val, item) => (
        <OnOff
          item={item}
          updateItemId={itemIdForUpdate}
          param="is_active"
          updateStatus={notificationStaffSubscriber.updateStatus}
          onChange={itemUpdate}
        />
      ),
      sorter: true,
      width: 130,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.notificationStaffSubscriber} actions={[{ name: 'edit' }, { name: 'delete' }]} />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = notificationStaffSubscriber.filter.activeFilter;
      }
      actions.notificationStaffSubscriber.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [notificationStaffSubscriber, actions, setSelected],
  );

  return (
    <TableList
      location={router.location}
      model={notificationStaffSubscriber}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      selected={selected}
      /*rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}*/
      /*filterFields={{
        list: ['is_active'],
        byName: {
          is_active: {
            label: 'Активно',
            type: 'bool',
          },
        },
      }}*/
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  notificationStaffSubscriber: state.notificationStaffSubscriber,
});

const mapDispatch = (dispatch) => ({
  actions: {
    notificationStaffSubscriber: dispatch.notificationStaffSubscriber,
  },
});

export default connect(mapState, mapDispatch)(NotificationStaffSubscriberList);
