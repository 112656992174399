import { push } from 'connected-react-router';
import { AUTH_API_URL } from 'config';
import { api, getHeaders, loadStates, loadStatus, setToken, removeToken } from 'utils';
import { asyncRequest } from '../base';

const endpoints = {
  login: `${AUTH_API_URL}/api/v1/auth/`,
  refresh: `${AUTH_API_URL}/api/v1/auth/refresh/`,
  password_change: `${AUTH_API_URL}/api/v1/auth/password/change/`,
};

export const authTokenCheck = (token) =>
  api.Load(
    'post',
    endpoints.refresh,
    { token },
    {
      headers: getHeaders(),
    },
  );

export const authLogin = (token, data) =>
  api.Load('post', endpoints.login, data, {
    headers: getHeaders(),
  });

export const auth = {
  state: {
    token: null,
    isAuthenticated: false,
    loginStatus: loadStatus(loadStates.notAsked),
  },
  reducers: {
    loginRequest(state, payload) {
      return {
        ...state,
        loginStatus: loadStatus(loadStates.loading),
      };
    },
    loginComplete(state, payload) {
      return {
        ...state,
        loginStatus: loadStatus(loadStates.loaded),
        token: payload.token,
        isAuthenticated: true,
      };
    },
    loginError(state, payload) {
      return {
        ...state,
        loginStatus: loadStatus(loadStates.failed, payload),
      };
    },
  },
  effects: (dispatch) => ({
    async login(payload, rootState) {
      await asyncRequest(
        dispatch,
        payload,
        rootState,
        authLogin,
        {
          request: dispatch.auth.loginRequest,
          complete: dispatch.auth.loginComplete,
          error: dispatch.auth.loginError,
        },
        async (data) => {
          const next = payload.next || '/';
          setToken(data.token);
          await dispatch.app.dataLoad({ token: data.token });
          dispatch(push(next));
        },
      );
    },

    logout(payload, rootState) {
      removeToken();
      dispatch(push('/login'));
    },
  }),
};
