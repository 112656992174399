import { CATALOG_API_URL } from 'config';
import { api } from 'utils';
import * as base from '../base';

const endpoints = {
  list: (siteDomain, groupId) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/group/${groupId}/recommended_category/`,
  detail: (siteDomain, groupId) => (id) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/group/${groupId}/recommended_category/${id}/`,
};

export const recommendedCategoryListLoad = (siteDomain, groupId) => (token, payload) => api.ListLoad(endpoints.list(siteDomain, groupId), token, payload);
export const recommendedCategoryDetailLoad = (siteDomain, groupId) => (token, { id }) => api.DetailLoad(endpoints.detail(siteDomain, groupId), token, { id });
export const recommendedCategoryUpdate = (siteDomain, groupId) => (token, data) => api.Update(endpoints.list(siteDomain, groupId), endpoints.detail(siteDomain, groupId), token, data);
export const recommendedCategoryDelete = (siteDomain, groupId) => (token, data) => api.Delete(endpoints.detail(siteDomain, groupId), token, data);

export const recommendedCategory = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Бренды',
    urls: {
      list: () => `/catalog/recommendedCategory`,
      detail: (id) => `/catalog/recommendedCategory/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: (dispatch) => ({
    async listLoad(payload, rootState) {
      console.log('payload', payload);
      const groupId = payload.activeFilter.groupId;
      const apiRequest = recommendedCategoryListLoad(rootState.site.activeDomain, groupId);
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'recommendedCategory', apiRequest);
    },

    async detailLoad(payload, rootState) {
      console.log('payload', payload);
      const groupId = payload.activeFilter.groupId;
      const apiRequest = recommendedCategoryDetailLoad(rootState.site.activeDomain, groupId);
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'recommendedCategory', apiRequest);
    },

    async update(payload, rootState) {
      const groupId = payload.groupId;
      const apiRequest = recommendedCategoryUpdate(rootState.site.activeDomain, groupId);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'recommendedCategory', apiRequest);
    },

    async delete(payload, rootState) {
      const groupId = rootState.recommendedCategory.filter.activeFilter.groupId;
      const apiRequest = recommendedCategoryDelete(rootState.site.activeDomain, groupId);
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'recommendedCategory', apiRequest);
    },
  }),
};
