import React from 'react';

import { NavTopDetail } from 'components/blocks/NavTop';

const DetailScreen = (props) => {
  return (
    <div className="page-main">
      <NavTopDetail />
    </div>
  );
};

export default DetailScreen;
