import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { GroupForm } from 'components/blocks/Group';
import { ProductForm, ProductBatchForm } from 'components/blocks/Product';
import DetailScreen from './DetailScreen';
import ProductsScreen from './ProductsScreen';
import RecommendedScreen from './RecommendedScreen';
import SeoScreen from './SeoScreen';


const GroupDetailScreen = ({ history, category, group, actions, match }) => {
  useEffect(() => {
    actions.group.detailLoad({ id: match.params.groupId });
  }, [match.params.groupId, actions.group]);

  const id = match.params.groupId;
  const detail = group.detail || {};
  const propertyConfig = detail.property_config || [];

  const breadcrumbs = useMemo(() => {
    let result = [{ title: 'Каталог', path: '/catalog' }];
    const categories = detail.categories || [];
    const category_ancestors = detail.category_ancestors || {};
    const ancestors = category_ancestors[categories[0]] || [];
    return result.concat(ancestors.map((item) => ({ title: item.name, path: category.urls.groupList(item.id) })));
  }, [detail.categories, detail.category_ancestors, category.urls]);

  return (
    <Page
      isLoading={group.loadDetailStatus.isLoading || group.updateStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.group}
      breadcrumbs={breadcrumbs}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={group.urls.detail(id)}
      tabs={[
        { title: 'Основное', key: 'detail' },
        { title: 'Товары', key: 'products' },
        { title: 'Рекомендуемые', key: 'recommended' },
        { title: 'SEO', key: 'seo' },
      ]}
    >
      <Route exact path="/catalog/group/:groupId" component={DetailScreen} />
      <Route exact path="/catalog/group/:groupId/products" component={ProductsScreen} />
      <Route exact path="/catalog/group/:groupId/recommended" component={RecommendedScreen} />
      <Route exact path="/catalog/group/:groupId/seo" component={SeoScreen} />

      <GroupForm />
      <ProductForm propertyConfig={propertyConfig}/>
      <ProductBatchForm propertyConfig={propertyConfig}/>
    </Page>
  );
};

const mapState = (state) => ({
  category: state.category,
  group: state.group,
});

const mapDispatch = (dispatch) => ({
  actions: {
    group: dispatch.group,
  },
});

export default connect(mapState, mapDispatch)(GroupDetailScreen);
