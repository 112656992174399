import React from 'react';
import { TreeSelect } from 'antd';
import { connect } from 'react-redux';

import { categoryListLoad } from 'store/models/category';
import { FormItem } from 'utils';
import { ListLoadComponent } from 'components/common';

const CategorySelect = (props) => {
  return (
    <ListLoadComponent
      apiLoad={categoryListLoad(props.site.activeDomain)}
      {...props}
      render={({ items }) => {
        const { onChange, disabled, router, authToken, model, dispatch, activeFilter, ...otherProps } = props;
        return (
          <FormItem {...otherProps}>
            <TreeSelect
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={items}
              placeholder="Выберите"
              showSearch={true}
              filterTreeNode={(input, treeNode) => treeNode.props.title.toLowerCase().indexOf(input.toLowerCase()) > -1}
              allowClear={true}
              multiple={props.multiple}
              disabled={disabled}
            />
          </FormItem>
        );
      }}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  authToken: state.auth.token,
  model: state.category,
  site: state.site,
});

export default connect(mapState)(CategorySelect);
