import React, { Fragment } from 'react';
import { Layout } from 'antd';
import { Redirect } from 'react-router';

import { Top, Page } from 'components/layout';

const DashboardScreen = () => {
  return (
    <Fragment>
      <Redirect to="/catalog" />

      <Top title="Рабочий стол" />

      <Layout className="layout-inner">
        <Page />
      </Layout>
    </Fragment>
  );
};

export default DashboardScreen;
