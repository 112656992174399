export function getHeaders(token = null) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (token) {
    headers['Authorization'] = `JWT ${token}`;
  }
  return headers;
}
