import React from 'react';
import { Input, Row, Col, Select } from 'antd';

import { FormItem } from 'utils';


const MainFieldSet = (props) => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <div className="form-block">
          <FormItem {...props} name="number" label="Номер заказа" required={true}>
            <Input size="large" disabled={true} />
          </FormItem>

          <FormItem {...props} name="status" label="Статус" required={true}>
            <Select
              style={{ width: '100%' }}
              placeholder="Выберите"
            >
              <Select.Option value="new">создан</Select.Option>
              <Select.Option value="processing">в обработке</Select.Option>
              <Select.Option value="shipping">передано в доставку</Select.Option>
              <Select.Option value="ready">готов к выдаче</Select.Option>
              <Select.Option value="completed">завершён</Select.Option>
              <Select.Option value="canceled">отменён</Select.Option>
            </Select>
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
