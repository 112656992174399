import { CATALOG_API_URL } from 'config';
import { api } from 'utils';
import * as base from '../base';

const endpoints = {
  list: (siteDomain) => `${CATALOG_API_URL}/api/v1/${siteDomain}/notification/`,
  detail: (siteDomain) => (id) => `${CATALOG_API_URL}/api/v1/${siteDomain}/notification/${id}/`,
};

export const notificationListLoad = (siteDomain) => (token, payload) => api.ListLoad(endpoints.list(siteDomain), token, payload);
export const notificationDetailLoad = (siteDomain) => (token, { id }) => api.DetailLoad(endpoints.detail(siteDomain), token, { id });
export const notificationUpdate = (siteDomain) => (token, data) => api.Update(endpoints.list(siteDomain), endpoints.detail(siteDomain), token, data);
export const notificationDelete = (siteDomain) => (token, data) => api.Delete(endpoints.detail(siteDomain), token, data);

export const notification = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Уведомления',
    urls: {
      list: () => `/notification/list`,
      detail: (id) => `/notification/list/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: (dispatch) => ({
    async listLoad(payload, rootState) {
      const apiRequest = notificationListLoad(rootState.site.activeDomain);
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'notification', apiRequest);
    },

    async detailLoad(payload, rootState) {
      const apiRequest = notificationDetailLoad(rootState.site.activeDomain);
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'notification', apiRequest);
    },

    async update(payload, rootState) {
      const apiRequest = notificationUpdate(rootState.site.activeDomain);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'notification', apiRequest);
    },

    async delete(payload, rootState) {
      const apiRequest = notificationDelete(rootState.site.activeDomain);
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'notification', apiRequest);
    },
  }),
};
