import React from 'react';
import { Input, InputNumber, Row, Col } from 'antd';

import { FormItem } from 'utils';
import { HtmlField, ImageUpload } from 'components/common';
import { CategorySelect } from 'components/blocks/Category';
import { BrandSelect } from 'components/blocks/Brand';


const MainFieldSet = (props) => {
  const editObject = props.group.editObject || {};

  return (
    <Row gutter={32}>
      <Col span={16}>
        <div className="form-block">
          <CategorySelect {...props} name="categories" label="Категории" required={false} multiple={true} />

          <BrandSelect {...props} name="brand" label="Бренд" required={false} multiple={false} />

          <FormItem {...props} name="name" label="Название" required={true}>
            <Input size="large" />
          </FormItem>

          <FormItem {...props} name="art_no" label="Артикул" required={false}>
            <Input size="large" />
          </FormItem>

          <FormItem {...props} name="old_price" label="Старая цена" required={false} help="Зачёркнутая цена">
            <InputNumber size="large" />
          </FormItem>

          <FormItem {...props} name="text" label="Описание" required={false}>
            <HtmlField
              auth_token={props.auth_token}
              siteDomain={props.site.activeDomain}
              modelName="catalog.productgroup"
            />
          </FormItem>
        </div>
      </Col>

      <Col span={8}>
        <div className="form-block">
          <FormItem {...props} name="images" required={false} valuePropName="fileList">
            <ImageUpload 
              title="Загрузить фото"
              auth_token={props.auth_token}
              siteDomain={props.site.activeDomain}
              modelName="catalog.productgroup"
              data={{ext_id: editObject.ext_id}}
            />
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default MainFieldSet;
