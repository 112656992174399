import React from 'react';
import { Select } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { connect } from 'react-redux';

import { groupListLoad } from 'store/models/group';
import { FormItem } from 'utils';
import { ListLoadComponent, Image } from 'components/common';


const GroupSelect = (props) => {
  return (
    <ListLoadComponent
      filter={{ is_active: true, can_buy: true }}
      apiLoad={groupListLoad(props.site.activeDomain)}
      {...props}
      render={({ items }) => {
        const { onChange, disabled, router, authToken, model, dispatch, activeFilter, ...otherProps } = props;
        return (
          <FormItem {...otherProps}>
            <Select
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Выберите"
              showSearch={true}
              allowClear={true}
              disabled={disabled}
              optionFilterProp="label"
              filterOption1={(input, option) => {
                const children = option.props.children;
                console.log(option);
                return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            >
              {!otherProps.required && <Select.Option value={null}>---</Select.Option>}
              {items.map(item => {
                const img = item.images[0];
                return (
                  <Select.Option key={`group${item.id}`} value={item.ext_id} label={item.name + item.art_no}>
                    <div className="selectCardItem">
                      <Image className="selectCardItemImage" src={img && img.url} width={80} height={80} />
                      <div className="selectCardItemBody">
                        <div className="selectCardItemName">{item.name}</div>
                        <div className="selectCardItemProp">Артикул: {item.art_no}</div>
                        <div className="selectCardItemProp">
                          Активно: {item.is_active ?
                            <CheckCircleTwoTone twoToneColor="#52c41a" /> :
                            <CloseCircleTwoTone twoToneColor="#eb2f96" />}
                        </div>
                      </div>
                    </div>
                  </Select.Option>
                )
              })}
            </Select>
          </FormItem>
        );
      }}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  authToken: state.auth.token,
  model: state.group,
  site: state.site,
});

export default connect(mapState)(GroupSelect);
