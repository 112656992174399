import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';


const RecommendedGroupForm = (props) => {
  const { showEditForm } = props.recommendedGroup;

  const onClose = () => {
    props.actions.recommendedGroup.showEditForm({ show: false });
  };

  const onSubmit = (data) => {
    const newData = Object.assign(data, {
      groupId: props.groupId,
      preventLoadDetail: true,
    });
    props.actions.recommendedGroup.update(newData);
  };

  return (
    <Form
      width={800}
      title="Редактирование"
      model={props.recommendedGroup}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={{ sort: 100 }}
      convertEditObject={(editObject) => ({
        ...editObject,
        recommended_category: editObject.recommended_category.id,
      })}
    >
      <MainFieldSet {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  site: state.site,
  auth_token: state.auth.token,
  recommendedGroup: state.recommendedGroup,
});

const mapDispatch = (dispatch) => ({
  actions: {
    recommendedGroup: dispatch.recommendedGroup,
  },
});

export default connect(mapState, mapDispatch)(RecommendedGroupForm);
