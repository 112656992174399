import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import './styles.css';


const Breadcrumbs = (props) => {
  const { className, style, items } = props;

  const cx = classNames(['breadcrumbs', className]);

  return (
    <div className={cx} style={style}>
      <div className="breadcrumbsInner">
        <div className="breadcrumbsList">
          {items.map((item, idx) => (
            <div key={`breadcrumb${idx}`} className="breadcrumbsItem">
              <Link to={{ pathname: item.path, state: { slideOut: item.isBack } }}>
                {item.icon}
                {item.title && <span>{item.title}</span>}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
