import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { PropertyList, PropertyForm } from 'components/blocks/Property';

const PropertyListScreen = ({ history, property, actions }) => {
  return (
    <Page
      title="Свойства товаров"
      info={property.filter.query ? `Найдено: ${property.count}`: ""}
      modelActions={actions.property}
      breadcrumbs={[{ title: 'Свойства товаров', path: property.urls.list() }]}
      actions={[
        {
          mode: 'button',
          name: 'add',
          type: 'primary',
          title: 'Добавить свойство',
        },
      ]}
    >
      <div className="page-main">
        <PropertyList />
      </div>

      <PropertyForm />
    </Page>
  );
};

const mapState = (state) => ({
  property: state.property,
});

const mapDispatch = (dispatch) => ({
  actions: {
    property: dispatch.property,
  },
});

export default connect(mapState, mapDispatch)(PropertyListScreen);
