import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';
import TextFieldSet from './TextFieldSet';


const FormContent = (props) => {
  return (
    <Tabs className="tabsForm" type="card">
      <Tabs.TabPane tab="Основное" key="detail" forceRender={true}>
        <MainFieldSet {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Описание" key="text" forceRender={true}>
        <TextFieldSet {...props} />
      </Tabs.TabPane>
    </Tabs>
  )
}


const BrandForm = (props) => {
  const { showEditForm } = props.brand;

  const onClose = () => {
    props.actions.brand.showEditForm({ show: false });
  };

  const onSubmit = (data) => {
    const newData = Object.assign(data, {
      site: props.site.activeId,
      logo: data?.logo?.length > 0 ? data.logo[0]: {}
    });
    props.actions.brand.update(newData);
  };

  return (
    <Form
      width={800}
      title="Редактирование свойства"
      model={props.brand}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={{ sort: 100 }}
      convertEditObject={(editObject) => ({
        ...editObject,
        logo: editObject.logo && editObject.logo.sha1 ? [editObject.logo]: [],
      })}
    >
      <FormContent {...props}/>
    </Form>
  );
};

const mapState = (state) => ({
  site: state.site,
  auth_token: state.auth.token,
  brand: state.brand,
});

const mapDispatch = (dispatch) => ({
  actions: {
    brand: dispatch.brand,
  },
});

export default connect(mapState, mapDispatch)(BrandForm);
