import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'components/common';
import MainFieldSet from './MainFieldSet';

const ProductBatchForm = (props) => {
  const { product } = props;
  const { showBatchForm, selected } = product;

  const onClose = () => {
    props.actions.product.showBatchForm({ show: false });
  };

  const onSubmit = (data) => {
    const items = data.selectedProducts.map(item => {
      const _properties = Object.keys(item).reduce((result, fieldName) => {
        const value = item[fieldName];
        if (fieldName !== 'id' && fieldName !== 'name' && value) {
          result[fieldName] = {property_ext_id: fieldName, value: value};
        }
        return result;
      }, {});

      return {
        id: item.id,
        fields: {
          name: item.name,
          _properties: _properties,
        },
      };
    });
    const newData = {
      selected: items,
    };
    console.log(newData);
    props.actions.product.batchUpdate(newData);
  };

  let selectedProducts = [];
  selected &&
    selected.forEach((id) => {
      const item = product.byId[id] || {};
      const itemProps = item.properties || [];

      const itemFields = itemProps.reduce(
        (result, propInfo) => {
          result[propInfo.ext_id] = propInfo.value;
          return result;
        },
        { id: item.id, name: item.name },
      );

      selectedProducts.push(itemFields);
    });

  return (
    <Form
      width={1600}
      title="Свойства товаров"
      model={props.product}
      visible={showBatchForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={(editObject) => ({
        ...editObject,
        selectedProducts: selectedProducts,
      })}
    >
      <MainFieldSet {...props} />
    </Form>
  );
};

const mapState = (state) => ({
  product: state.product,
  property: state.property,
});

const mapDispatch = (dispatch) => ({
  actions: {
    product: dispatch.product,
  },
});

export default connect(mapState, mapDispatch)(ProductBatchForm);
