import React from 'react';
import { Row, Col } from 'antd';
import classNames from "classnames";

import './styles.css';


const DescriptionsItem = ({ className, style, size, defaultSpan, span, gutter, label, children }) => {
  const cx = classNames([
    "descriptionsItem",
    size && `descriptionsItem--${size}`,
    className,
  ]);

  const cxContent = classNames([
    "descriptionsItemContent",
    (!children || children.length === 0) && "descriptionsItemContent--empty",
  ]);

  return (
    <Col className={cx} span={span || defaultSpan} gutter={gutter}>
      <div className="descriptionsItemWrap">
        <div className="descriptionsItemLabel">{label}</div>
        <div className={cxContent} style={style}>{children}</div>
      </div>
    </Col>
  );
};

const Descriptions = ({ className, gutter, column, children }) => {
  const cx = classNames([
    "descriptions",
    className,
  ]);

  const childrenWithExtraProp = React.Children.map(children, child => {
    return child && React.cloneElement(child, {
      defaultSpan: 24 / column,
      gutter,
    });
  });

  return (
    <Row className={cx} type="flex" gutter={gutter}>
      {childrenWithExtraProp}
    </Row>
  );
};

Descriptions.Item = DescriptionsItem;

export default Descriptions;
