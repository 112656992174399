import React from 'react';
import { FileImageOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import './image.css';


const Image = (props) => {
  const { className, style, width, height, src } = props;

  const cx = classNames(['image', className]);

  let mainStyle = {};
  if (width) {
    mainStyle['width'] = isNaN(width) ? width: `${width}px`;
  }
  if (height) {
    mainStyle['height'] = isNaN(height) ? height: `${height}px`;
  }

  mainStyle = Object.assign(
    mainStyle,
    style || {},
  );

  return (
    <div className={cx} style={mainStyle}>
      <div className="imageInner">
        {src ? <img src={src} alt=""/>: <div className="imageEmpty"><FileImageOutlined /><div className="imageEmptyTitle">Нет картинки</div></div>}
      </div>
    </div>
  );
};

export default Image;
