import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Carousel } from 'antd';
import { Link } from 'react-router-dom';

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText, OnOff, Image } from 'components/common';
import { BrandValue } from 'components/blocks/Brand';


const GroupDetail = ({ category, group, actions }) => {
  const itemUpdate = (data) => {
    actions.group.update(data);
  };

  const detail = group.detail || {};
  const images = detail.images || [];
  const brand = detail.brand;

  const categoryAncestors = detail.category_ancestors || [];
  const categories = categoryAncestors[categoryAncestors.length-1] || [];
  //const properties = getObjectList(detail.properties || { list: [], byId: {} });

  return (
    <Row gutter={[16, 24]}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Название" span={24}>
              {detail.name}
            </Descriptions.Item>
            <Descriptions.Item label="Артикул" span={24}>
              {detail.art_no}
            </Descriptions.Item>
            <Descriptions.Item label="Категории" span={24}>
              {categories.map((item, idx) => (
                <div key={`cat${idx}`}>
                  <Link to={{ pathname: category.urls.groupList(item.id), state: { slideOut: false } }}>
                    <span>{item.name}</span>
                  </Link>
                </div>
              ))}
            </Descriptions.Item>
            <Descriptions.Item label="Бренд" span={24}>
              <BrandValue detailId={brand} asLink={true} isBack={true}/>
            </Descriptions.Item>
            <Descriptions.Item label="Старая цена" span={24}>
              {detail.old_price || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Описание" span={24}>
              <div style={{overflowX: 'scroll'}} dangerouslySetInnerHTML={{__html: detail.text}} />
            </Descriptions.Item>

            <Descriptions.Item label="Активный">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_active"
                updateStatus={group.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Carousel>
            {images.map(img => (
              <div key={`photo${img.sha1}`}>
                <Image src={img.url} height={300} />
              </div>
            ))}
          </Carousel>
        </Fieldset>
        
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="EXT_ID" size="sm">{detail.ext_id}</Descriptions.Item>
            <Descriptions.Item label="Дата создания" size="sm">
              <DateTimeText date={detail.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения" size="sm">
              <DateTimeText date={detail.date_changed} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата синхронизации" size="sm">
              <DateTimeText date={detail.date_synced} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  category: state.category,
  group: state.group,
});

const mapDispatch = (dispatch) => ({
  actions: {
    group: dispatch.group,
  },
});

export default connect(mapState, mapDispatch)(GroupDetail);
