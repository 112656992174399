import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';

import { getUrlSearch } from 'utils';

const Value = ({ value }) => {
  switch (value) {
    case 'true':
      return <span>Да</span>;
    case 'false':
      return <span>Нет</span>;
    default:
      return <span>{value}</span>;
  }
};

const getFilters = (filterFields, params) => {
  let filters = [];
  filterFields &&
    filterFields.list.forEach((fieldName) => {
      const field = filterFields.byName[fieldName];
      const ValueComponent = field.valueComponent || Value;
      let parentName = fieldName;
      let childName = '';
      if (fieldName.indexOf('.')) {
        parentName = fieldName.split('.')[0];
        childName = fieldName.split('.')[1];
      }
      let values = params[parentName];
      if (values) {
        if (childName) {
          values = values[childName];
        }
        if (values && !Array.isArray(values)) {
          values = [values];
        }
        //console.log(parentName, childName, values);
        if (values && values.length > 0) {
          filters.push({
            name: fieldName,
            label: field.label,
            values: values.map((v) => ({ Component: ValueComponent, value: v })),
          });
        }
      }
    });
  return filters;
};

const TableFilters = ({ data, onLoadData, filterFields }) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setFilters(getFilters(filterFields, data.filter.params));
  }, [filterFields, data.filter.query, data.filter.params, data.filter.params.search]);

  const onRemoveFilterParam = (e, fieldName, value) => {
    e.preventDefault();
    let params = Object.assign({}, data.filter.params);

    let parentName = fieldName;
    let childName = '';
    if (fieldName.indexOf('.') > -1) {
      parentName = fieldName.split('.')[0];
      childName = fieldName.split('.')[1];
      let values = params[parentName][childName];
      if (!Array.isArray(values)) {
        values = [values];
      }
      params[parentName][childName] = values.filter((v) => v !== value);
    } else {
      if (Array.isArray(params[fieldName])) {
        params[fieldName] = params[fieldName].filter((v) => v !== value);
      } else {
        delete params[fieldName];
      }
    }

    const query = '?' + getUrlSearch(params);
    onLoadData && onLoadData({ query, activeFilter: data.filter.activeFilter });
  };

  if (filters.length < 0) return null;

  return (
    <div className="table-filters">
      {filters.map((f) => (
        <div key={`filter-${f.name}`} className="table-filters-item">
          <div className="table-filters-item-label">{f.label}:</div>
          <div className="table-filters-item-values">
            {f.values.map((v) => (
              <Tag
                key={`filter-${f.name}-${v.value}`}
                closable
                onClose={(e) => onRemoveFilterParam(e, f.name, v.value)}
              >
                <v.Component value={v.value} />
              </Tag>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableFilters;
