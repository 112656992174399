import { Select } from 'antd';

import PageType from '../PageType'


const PageTypeSelect = ({ isForObject, disabled, value, onChange }) => {
  return (
    <Select style={{ width: '100%' }} value={value} placeholder="Выберите" disabled={disabled} onChange={onChange}>
      {isForObject ?
        PageType.TYPE_LIST.map(item => (
          <Select.Option key={`type${item[0]}`} value={item[0]}>{item[1]}</Select.Option>
        ))
        :
        PageType.EDITABLE_TYPE_LIST.map(item => (
          <Select.Option key={`type${item[0]}`} value={item[0]}>{item[1]}</Select.Option>
        ))}
    </Select>
  );
}

PageTypeSelect.getQueryParams = (key, value) => {
  return { [key]: value };
}

PageTypeSelect.getValue = (values) => {
  return values;
}

PageTypeSelect.getDisplayValue = (values, config) => {
  return values
};

export default PageTypeSelect
