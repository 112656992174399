import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Carousel } from 'antd';
import { Link } from 'react-router-dom';

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText, OnOff, Image, PropValue } from 'components/common';


const ProductDetail = ({ category, product, actions }) => {
  const itemUpdate = (data) => {
    actions.product.update(data);
  };

  const detail = product.detail || {};
  const images = detail.images || [];
  const categoryAncestors = detail.category_ancestors || {};
  const categories = (detail.categories || []).map(id => {
    const items = categoryAncestors[id];
    //const breadcrumbs = items.map(item => ({ title: item.name, path: category.urls.detail(item.id) }));
    return items[items.length-1];
  });
  const properties = detail.properties || [];
  const warehouses = detail.warehouses || [];
  const prices = detail.prices || [];

  return (
    <Row gutter={[16, 24]}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Название" span={24}>
              {detail.name}
            </Descriptions.Item>
            <Descriptions.Item label="Код" span={24}>
              {detail.code}
            </Descriptions.Item>
            <Descriptions.Item label="Артикул" span={24}>
              {detail.art_no}
            </Descriptions.Item>
            <Descriptions.Item label="Категории" span={24}>
              {categories.map((item, idx) => (
                <div key={`cat${idx}`}>
                  <Link to={{ pathname: category.urls.groupList(item.id), state: { slideOut: false } }}>
                    <span>{item.name}</span>
                  </Link>
                </div>
              ))}
            </Descriptions.Item>
            <Descriptions.Item label="Описание" span={24}>
              <div style={{overflowX: 'scroll', fontSize: '12px'}} dangerouslySetInnerHTML={{__html: detail.text}} />
            </Descriptions.Item>

            <Descriptions.Item label="Свойства" span={24}>
              {properties.map(item => (
                item.is_active && <PropValue key={`prop${item.ext_id}`} label={item.name} value={item.value}/>
              ))}
            </Descriptions.Item>

            <Descriptions.Item label="Активный">
              <OnOff
                item={detail}
                updateItemId={detail.id}
                param="is_active"
                updateStatus={product.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Carousel>
            {images.map(img => (
              <div key={`photo${img.sha1}`}>
                <Image src={img.url} height={300} />
              </div>
            ))}
          </Carousel>
        </Fieldset>
        
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="EXT_ID" size="sm">{detail.ext_id}</Descriptions.Item>
            <Descriptions.Item label="Дата создания" size="sm">
              <DateTimeText date={detail.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения" size="sm">
              <DateTimeText date={detail.date_changed} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата синхронизации" size="sm">
              <DateTimeText date={detail.date_synced} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>

        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Остатки" span={24}>
              {warehouses.map(item => (
                item.is_active && <PropValue key={`w${item.ext_id}`} label={item.name} value={item.quantity}/>
              ))}
            </Descriptions.Item>

            <Descriptions.Item label="Цены" span={24}>
              {prices.map(item => (
                item.is_active && <PropValue key={`p${item.ext_id}`} label={item.name} value={item.price}/>
              ))}
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  category: state.category,
  product: state.product,
});

const mapDispatch = (dispatch) => ({
  actions: {
    product: dispatch.product,
  },
});

export default connect(mapState, mapDispatch)(ProductDetail);
