import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { NotificationTemplateList, NotificationTemplateForm } from 'components/blocks/NotificationTemplate';


const NotificationTemplateListScreen = ({ history, site, notificationTemplate, actions }) => {
  return (
    <Page
      title="Шаблоны уведомлений"
      info={notificationTemplate.filter.query ? `Найдено: ${notificationTemplate.count}`: ""}
      modelActions={actions.notificationTemplate}
      breadcrumbs={[
        { title: 'Шаблоны уведомлений', path: notificationTemplate.urls.list() },
      ]}
      actions={[
        {
          mode: 'button',
          name: 'add',
          type: 'primary',
          title: 'Добавить шаблон',
        },
      ]}
    >
      <div className="page-main">
        <NotificationTemplateList />
      </div>

      <NotificationTemplateForm />
    </Page>
  );
};

const mapState = (state) => ({
  site: state.site,
  notificationTemplate: state.notificationTemplate,
});

const mapDispatch = (dispatch) => ({
  actions: {
    notificationTemplate: dispatch.notificationTemplate,
  },
});

export default connect(mapState, mapDispatch)(NotificationTemplateListScreen);
