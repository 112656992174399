import React from 'react';
import { Input, InputNumber, Select } from 'antd';

import { FormItem } from 'utils';
import PropertyType from '../../PropertyType';


const MainFieldSet = (props) => {
  return (
    <div className="form-block">
      <FormItem {...props} name="name" label="Название" required={true}>
        <Input size="large" />
      </FormItem>

      <FormItem {...props} name="slug" label="Код" required={true}>
        <Input size="large" />
      </FormItem>

      <FormItem {...props} name="type" label="Тип" required={true}>
        <Select style={{ width: 200 }} showSearch={false}>
          {PropertyType.PROPERTY_TYPE_LIST.map(v => (
            <Select.Option key={v} value={v}>{PropertyType.getPropertyTypeName(v)}</Select.Option>
          ))}
        </Select>
      </FormItem>

      <FormItem {...props} name="sort" label="Сортировка" required={true}>
        <InputNumber size="large" />
      </FormItem>
    </div>
  );
};

export default MainFieldSet;
