import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText, OnOff } from 'components/common';
import { PageObjectValue, PageType } from 'components/blocks/Page'


const PageDetail = ({ pageDetail, page, actions }) => {
  const detail = pageDetail || page.detail;

  const itemUpdate = (data) => {
    if (pageDetail) {
      actions.page.update({ ...data, objectDetailLoad: true });

    } else {
      actions.page.update(data);
    }
  };

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Путь" span={24}>{detail?.path}</Descriptions.Item>

            <Descriptions.Item label="Тайтл">{detail?.title}</Descriptions.Item>

            <Descriptions.Item label="Дескрипшен">{detail?.description}</Descriptions.Item>

            <Descriptions.Item label="h1">{detail?.h1}</Descriptions.Item>

            <Descriptions.Item label="h2">{detail?.h2}</Descriptions.Item>

            <Descriptions.Item label="Текст">
              <div style={{ overflowX: 'scroll', fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: detail?.text }} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>

      <Col span={8}>
        <Fieldset>
          <Descriptions column={1} gutter={[16, 16]}>
            <Descriptions.Item label="Тип" size="sm">{PageType.getValue(detail?.page_type)}</Descriptions.Item>
            <Descriptions.Item label="Объект" size="sm">
              <PageObjectValue page_type={detail?.page_type} object_id={detail?.object_id} />
            </Descriptions.Item>

            <Descriptions.Item label="Не использовать seo-шаблон">
              <OnOff
                item={detail}
                updateItemId={detail?.id}
                param="seo_template_off"
                updateStatus={page.updateStatus}
                onChange={itemUpdate}
              />
            </Descriptions.Item>

            <Descriptions.Item label="Дата создания" size="sm">
              <DateTimeText date={detail?.date_created} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата изменения" size="sm">
              <DateTimeText date={detail?.date_changed} />
            </Descriptions.Item>
          </Descriptions>
        </Fieldset>
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  page: state.page,
});

const mapDispatch = (dispatch) => ({
  actions: {
    page: dispatch.page,
  },
});

export default connect(mapState, mapDispatch)(PageDetail);
