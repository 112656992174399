import { LoginForm } from 'components/blocks/Auth';
import React from 'react';
import styles from './screen.module.css';

const LoginScreen = () => {
  return (
    <div className={styles.main}>
      <div className={styles.panel}>
        <div>
          <div className={styles.title}>Вход</div>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
