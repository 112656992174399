import React from 'react';

import { BrandSelect } from 'components/blocks/Brand';

const MainFieldSet = (props) => {
  return (
    <div className="form-block">
      <BrandSelect {...props} name="brand" label="Бренд" required={false} />
    </div>
  );
};

export default MainFieldSet;
