import { CATALOG_API_URL } from 'config';
import { api } from 'utils';
import * as base from '../base';

const endpoints = {
  list: (siteDomain, groupId) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/group/${groupId}/recommended_group/`,
  detail: (siteDomain, groupId) => (id) => `${CATALOG_API_URL}/api/v1/${siteDomain}/catalog/group/${groupId}/recommended_group/${id}/`,
};

export const recommendedGroupListLoad = (siteDomain, groupId) => (token, payload) => api.ListLoad(endpoints.list(siteDomain, groupId), token, payload);
export const recommendedGroupDetailLoad = (siteDomain, groupId) => (token, { id }) => api.DetailLoad(endpoints.detail(siteDomain, groupId), token, { id });
export const recommendedGroupUpdate = (siteDomain, groupId) => (token, data) => api.Update(endpoints.list(siteDomain, groupId), endpoints.detail(siteDomain, groupId), token, data);
export const recommendedGroupDelete = (siteDomain, groupId) => (token, data) => api.Delete(endpoints.detail(siteDomain, groupId), token, data);

export const recommendedGroup = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Бренды',
    urls: {
      list: () => `/catalog/recommendedGroup`,
      detail: (id) => `/catalog/recommendedGroup/${id}`,
    },
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,
  },
  effects: (dispatch) => ({
    async listLoad(payload, rootState) {
      console.log('payload', payload);
      const groupId = payload.activeFilter.groupId;
      const apiRequest = recommendedGroupListLoad(rootState.site.activeDomain, groupId);
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'recommendedGroup', apiRequest);
    },

    async detailLoad(payload, rootState) {
      console.log('payload', payload);
      const groupId = payload.activeFilter.groupId;
      const apiRequest = recommendedGroupDetailLoad(rootState.site.activeDomain, groupId);
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'recommendedGroup', apiRequest);
    },

    async update(payload, rootState) {
      const groupId = payload.groupId;
      const apiRequest = recommendedGroupUpdate(rootState.site.activeDomain, groupId);
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'recommendedGroup', apiRequest);
    },

    async delete(payload, rootState) {
      const groupId = rootState.recommendedGroup.filter.activeFilter.groupId;
      const apiRequest = recommendedGroupDelete(rootState.site.activeDomain, groupId);
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'recommendedGroup', apiRequest);
    },
  }),
};
