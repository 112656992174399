import React from 'react';
import { Button } from 'antd';

import { ActionButton } from 'components/common';

export default function PageHeaderActions({ modelActions, actions, actionByName, detail }) {
  return (
    <div className="header-actions">
      {actions &&
        actions.map((item, index) => {
          if (item.mode === 'group') {
            return (
              <div className="header-actions-item" key={`btn${index}`}>
                <ActionButton
                  modelActions={modelActions}
                  actions={item.actions}
                  actionByName={actionByName}
                  detail={detail}
                />
              </div>
            );
          }

          const action = ActionButton.getActions(item.name, actionByName);
          if (!action) return null;
          const itemAction = action(modelActions, detail);
          const title = item.title || itemAction.title || '';
          return (
            <div className="header-actions-item" key={`btn${index}`}>
              <Button type={item.type || 'primary'} onClick={itemAction.action}>
                {itemAction.icon}
                {title}
              </Button>
            </div>
          );
        })}
    </div>
  );
}
