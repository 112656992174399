import { init } from '@rematch/core';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import persistPlugin from '@rematch/persist';
import storage from 'redux-persist/lib/storage';

import * as models from './models'


export const history = createBrowserHistory();


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['site']
}


const store = init({
  plugins: [persistPlugin(persistConfig)],
  redux: {
    reducers: {
      router: connectRouter(history)
    },
    middlewares: [
      routerMiddleware(history)
    ],
    devtoolOptions: {}
  },
  models,
})

export default store;
