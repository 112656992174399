import { loadStates, loadStatus, importResults, getUrlParams } from 'utils';

export const ListState = {
  list: [],
  byId: {},
  activeId: 0,
  pageSize: 0,
  count: 0,
  info: null,

  filter: {
    query: null,
    params: {},
    activeFilter: {},
  },

  loadListStatus: loadStatus(loadStates.notAsked),
};

export const ListReducers = {
  select(state, payload) {
    return {
      ...state,
      activeId: payload,
    };
  },

  listLoadRequest(state, payload) {
    const query = (payload && payload.query) || '';
    const params = getUrlParams(query);
    const activeFilter = (payload && payload.activeFilter) || {};

    return {
      ...state,
      list: [],
      byId: {},
      loadListStatus: loadStatus(loadStates.loading),
      filter: {
        query: query === '?' ? null : query,
        params: params,
        activeFilter: activeFilter,
      },

      /*list: [],
      byId: {},
      pageSize: 0,
      count: 0,
      info: null,|*/
    };
  },

  listLoadComplete(state, payload) {
    const { list, byId } = importResults(payload.results);
    let activeId = state.activeId;
    if (activeId === 0) activeId = list[0];

    return {
      ...state,
      loadListStatus: loadStatus(loadStates.loaded),
      list: list,
      byId: byId,
      activeId: activeId,
      pageSize: payload.next ? list.length : payload.count,
      count: payload.count,
      info: payload.info,
    };
  },

  listLoadError(state, payload) {
    return {
      ...state,
      loadListStatus: loadStatus(loadStates.failed, payload),
    };
  },
};
