import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Switch } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { CATALOG_API_URL } from 'config';
import { tableColumn } from 'utils';
import { TableList, ActionButton, Image } from 'components/common';


const PluginDataItemList = ({ detail, fieldName, itemFields, items, actions, site, plugin }) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);

  const columnList = useMemo(() => {
    let fields = itemFields.map(item => item.name);
    fields.push('actions');
    return fields;
  }, [itemFields]);

  const addItem = () => {
    actions.plugin.showDataItemForm({ 
      show: true, 
      preventLoadDetail: true,
      detail, 
      itemFieldName: fieldName,
      itemFields
    });
  };

  const columnByName = useMemo(() => {
    const delItem = (item) => {
      console.log('delItem', item);
      items.splice(item.index, 1);
      const data = Object.assign(detail.data, {
        [fieldName]: items,
      });
  
      const postData = {
        id: detail.id,
        site: site.activeId,
        data:  data,
        preventLoadDetail: true,
      };
  
      console.log('postData', postData);
      actions.plugin.update(postData);
    };
  
    const onItemOnOff = (itemFieldName, item) => {
      setItemIdForUpdate(item.index);
  
      items[item.index][itemFieldName] = !item[itemFieldName];
  
      const data = Object.assign(detail.data, {
        [fieldName]: items,
      });
      const postData = {
        id: detail.id,
        site: site.activeId,
        data:  data,
        preventLoadDetail: true,
      };
  
      console.log('postData', postData);
      actions.plugin.update(postData);
    };

    return itemFields.reduce((res, field) => {
      let render = (val, item) => val;
      if (field.type === 'image') {
        render = (val, item) => <Image src={CATALOG_API_URL+val.path} width={300} height={150} />;
      } else if (field.type === 'bool') {
        render = (val, item) => (
          <Switch
            style={{ marginTop: '5px', minWidth: '62px' }}
            checkedChildren="вкл"
            unCheckedChildren="выкл"
            checked={val}
            loading={plugin.updateStatus.isLoading && itemIdForUpdate === item.index}
            onClick={() => onItemOnOff(field.name, item)}
          />
        );
      }
  
      res[field.name] = tableColumn(field.label, field.name, {
        render: render,
        sorter: false,
        //width: 59,
      });
      return res;
    }, {
      actions: tableColumn('', 'actions', {
        align: 'right',
        render: (val, item) => (
          <ActionButton 
            detail={item} 
            modelActions={actions.plugin} 
            actions={[{ name: 'edit' }, { name: 'delete' }]}
            actionByName={{
              edit: (modelActions, item) => ({
                title: 'Редактировать',
                action: () => actions.plugin.showDataItemForm({ 
                  show: true, 
                  detail, 
                  item, 
                  itemFieldName: fieldName, 
                  itemFields 
                }),
              }),
              delete: (modelActions, item) => ({
                title: 'Удалить',
                action: () => delItem(item),
              }),
            }}
          />
        ),
      }),
    });
  }, [fieldName, itemFields, items, site.activeId, actions.plugin, detail, itemIdForUpdate, plugin.updateStatus.isLoading]);

  return (
    <TableList
      dataSource={items}
      model={plugin}
      columnList={columnList}
      columnByName={columnByName}
      topActions={[
        <Button type="primary" icon={<PlusOutlined/>} onClick={addItem}>Добавить</Button>
      ]}
      showSearch={false}
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  site: state.site,
  plugin: state.plugin,
});

const mapDispatch = (dispatch) => ({
  actions: {
    plugin: dispatch.plugin,
  },
});

export default connect(mapState, mapDispatch)(PluginDataItemList);
