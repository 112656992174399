import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { CategoryForm } from 'components/blocks/Category';
import DetailScreen from './DetailScreen';


const CategoryDetailScreen = ({ history, category, actions, match }) => {
  useEffect(() => {
    actions.category.detailLoad({ id: match.params.categoryId });
  }, [match.params.categoryId, actions.category]);

  const id = match.params.categoryId;
  const detail = category.detail || {};

  const breadcrumbs = useMemo(() => {
    let result = [{ title: 'Каталог', path: '/catalog' }, { title: 'Категории', path: category.urls.list() }];
    const ancestors = detail.ancestors || [];
    return result.concat(ancestors.map((item) => ({ title: item.name, path: category.urls.detail(item.id) })));
  }, [detail.ancestors, category.urls]);

  return (
    <Page
      isLoading={category.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.category}
      breadcrumbs={breadcrumbs}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={category.urls.detail(id)}
      tabs={[
        { title: 'Основное', key: 'detail' },
        { title: 'Страница', key: 'page' },
      ]}
    >
      <Route exact path="/catalog/category/:categoryId" component={DetailScreen} />

      <CategoryForm />
    </Page>
  );
};

const mapState = (state) => ({
  category: state.category,
});

const mapDispatch = (dispatch) => ({
  actions: {
    category: dispatch.category,
  },
});

export default connect(mapState, mapDispatch)(CategoryDetailScreen);
