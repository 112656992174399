import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { SiteChooserPanel } from 'components/blocks/Site';
import TopTabs from './TopTabs';
import './styles.css';

function Top({ router, title, basePath, tabs, onTabChange }) {
  const cx = classNames(['top']);

  return (
    <header className={cx}>
      <div className="top-main">
        <div className="top-main-start">{title && <div className="top-main-title">{title}</div>}</div>

        <div className="top-main-middle">
          {tabs && <TopTabs basePath={basePath} tabs={tabs} onTabChange={onTabChange} />}
        </div>

        <div className="top-main-end">
          <SiteChooserPanel />
        </div>
      </div>
    </header>
  );
}

const mapState = (state) => ({
  router: state.router,
});

const mapDispatch = (dispatch) => ({
  actions: {
    router: {
      push: (payload) => dispatch(push(payload)),
    },
  },
});

export default connect(mapState, mapDispatch)(Top);
