import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { tableColumn } from 'utils';
import { TableList, ActionButton, OnOff } from 'components/common';
import NotificationTemplateValue from '../NotificationTemplateValue';


const NotificationTemplateList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, notificationTemplate }) => {
  const defaultColumnList = ['name', 'event_type', 'from_email', 'for_manager', 'is_active', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }

  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const itemUpdate = (data) => {
    setItemIdForUpdate(data.id);
    actions.notificationTemplate.update({ ...data, preventLoadDetail: true });
  };

  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    name: tableColumn('Тема письма', 'name', {
      render: (val, item) => <NotificationTemplateValue detail={item} asLink={true} isBack={true} />,
      sorter: true,
    }),
    event_type: tableColumn('Тип события', 'event_type', {
      sorter: true,
      width: 150,
    }),
    from_email: tableColumn('E-mail отправителя', 'from_email', {
      sorter: true,
      width: 250,
    }),
    for_manager: tableColumn('Для менеджеров', 'for_manager', {
      render: (val, item) => (
        <OnOff
          item={item}
          updateItemId={itemIdForUpdate}
          param="for_manager"
          updateStatus={notificationTemplate.updateStatus}
          onChange={itemUpdate}
        />
      ),
      sorter: true,
      width: 130,
    }),
    is_active: tableColumn('Активно', 'is_active', {
      render: (val, item) => (
        <OnOff
          item={item}
          updateItemId={itemIdForUpdate}
          param="is_active"
          updateStatus={notificationTemplate.updateStatus}
          onChange={itemUpdate}
        />
      ),
      sorter: true,
      width: 130,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton detail={item} modelActions={actions.notificationTemplate} actions={[{ name: 'edit' }, { name: 'delete' }]} />
      ),
    }),
  };

  const [selected, setSelected] = useState([]);

  const onLoad = useCallback(
    ({ query, activeFilter }) => {
      if (activeFilter === undefined) {
        activeFilter = notificationTemplate.filter.activeFilter;
      }
      actions.notificationTemplate.listLoad({ query, activeFilter, locationChange: true });
      setSelected([]);
    },
    [notificationTemplate, actions, setSelected],
  );

  return (
    <TableList
      location={router.location}
      model={notificationTemplate}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      selected={selected}
      /*rowSelection={{
        selectedRowKeys: selected,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelected(selectedRowKeys.slice());
        },
      }}*/
      /*filterFields={{
        list: ['is_active'],
        byName: {
          is_active: {
            label: 'Активно',
            type: 'bool',
          },
        },
      }}*/
    />
  );
};

const mapState = (state) => ({
  router: state.router,
  notificationTemplate: state.notificationTemplate,
});

const mapDispatch = (dispatch) => ({
  actions: {
    notificationTemplate: dispatch.notificationTemplate,
  },
});

export default connect(mapState, mapDispatch)(NotificationTemplateList);
