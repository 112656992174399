import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
import { SeoTemplatePageForm } from 'components/blocks/SeoTemplatePage';
import DetailScreen from './DetailScreen';


const SeoTemplatePageDetailScreen = ({ history, seoTemplatePage, actions, match }) => {
  useEffect(() => {
    actions.seoTemplatePage.detailLoad({ id: match.params.seoTemplatePageId });
  }, [match.params.seoTemplatePageId, actions.seoTemplatePage]);

  const id = match.params.seoTemplatePageId;
  const detail = seoTemplatePage.detail || {};

  return (
    <Page
      isLoading={seoTemplatePage.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.tpl_title}
      modelActions={actions.seoTemplatePage}
      breadcrumbs={[
        { title: 'Сайт', path: '/site' },
        { title: 'Шаблоны страниц', path: seoTemplatePage.urls.list() },
        { title: detail.tpl_title, path: seoTemplatePage.urls.detail(id) },
      ]}
      actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}
      basePath={seoTemplatePage.urls.detail(id)}
    >
      <Route exact path="/site/seoTemplatePage/:seoTemplatePageId" component={DetailScreen} />

      <SeoTemplatePageForm />
    </Page>
  );
};

const mapState = (state) => ({
  seoTemplatePage: state.seoTemplatePage,
});

const mapDispatch = (dispatch) => ({
  actions: {
    seoTemplatePage: dispatch.seoTemplatePage,
  },
});

export default connect(mapState, mapDispatch)(SeoTemplatePageDetailScreen);
