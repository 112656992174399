import React from 'react';
import { connect } from 'react-redux';

import { Page } from 'components/layout';
import { NavMainList, NavMainForm } from 'components/blocks/NavMain';


const NavMainListScreen = ({ history, site, navMain, actions }) => {
  return (
    <Page
      title="Навигация"
      info={navMain.filter.query ? `Найдено: ${navMain.count}`: ""}
      modelActions={actions.brand}
      breadcrumbs={[
        { title: 'Сайт', path: '/site' },
        { title: 'Навигация', path: navMain.urls.list() },
      ]}
      
      basePath="/site/nav"
      tabs={[
        { title: 'Главная навигация', key: 'detail' },
        { title: 'Верхняя навигация', key: 'top' },
        { title: 'Нижняя навигация', key: 'footer' },
      ]}
    >
      <div className="page-main">
        <NavMainList />
      </div>

      <NavMainForm />
    </Page>
  );
};

const mapState = (state) => ({
  site: state.site,
  navMain: state.navMain,
});

const mapDispatch = (dispatch) => ({
  actions: {
    navMain: dispatch.navMain,
  },
});

export default connect(mapState, mapDispatch)(NavMainListScreen);
