import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { Page } from 'components/layout';
//import { PluginForm } from 'components/blocks/Plugin';
import DetailScreen from './DetailScreen';


const PluginDetailScreen = ({ history, plugin, actions, match }) => {
  useEffect(() => {
    actions.plugin.detailLoad({ id: match.params.pluginId });
  }, [match.params.pluginId, actions.plugin]);

  const id = match.params.pluginId;
  const detail = plugin.detail || {};

  return (
    <Page
      isLoading={plugin.loadDetailStatus.isLoading}
      detail={detail}
      title={detail.name}
      modelActions={actions.plugin}
      breadcrumbs={[
        { title: 'Сайт', path: '/site' },
        { title: 'Плагины', path: plugin.urls.list() },
        { title: detail.title, path: plugin.urls.detail(id) },
      ]}
      /*actions={[
        { mode: 'button', name: 'edit' },
        {
          mode: 'group',
          actions: [{ mode: 'button', name: 'delete' }],
        },
      ]}*/
      basePath={plugin.urls.detail(id)}
    >
      <Route exact path="/site/plugin/:pluginId" component={DetailScreen} />
    </Page>
  );
};

const mapState = (state) => ({
  plugin: state.plugin,
});

const mapDispatch = (dispatch) => ({
  actions: {
    plugin: dispatch.plugin,
  },
});

export default connect(mapState, mapDispatch)(PluginDetailScreen);
