import React from 'react';
import { Row, Col, Checkbox } from 'antd';

import { FormItem } from 'utils';

//seo_template_off
//seo_template
//object_id
//product_filter

const SeoFieldSet = (props) => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <div className="form-block">
          <FormItem name="seo_template_off" label="" required={false} valuePropName="checked">
            <Checkbox>Не использовать seo-шаблон</Checkbox>
          </FormItem>
        </div>
      </Col>
    </Row>
  );
};

export default SeoFieldSet;
